import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 PAP 프로젝트를 가져옵니다.
 * @param {String} params
 */
export async function fetchAllPapProjects() {
  const queryParams = [];
  queryParams.push('order=-createdAt');
  const res = await axios.get(
    `/v1/admin/pap/projects?${queryParams.join('&')}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 특정 PAP 프로젝트를 가져옵니다.
 */
export async function fetchPapProject(id) {
  const res = await axios.get(`/v1/admin/pap/projects/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * PAP 프로젝트를 생성합니다.
 * @param {Object} data
 */
export async function createPapProject(data) {
  const res = await axios.post('/v1/admin/pap/projects', data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * PAP 프로젝트를 수정합니다.
 * @param {string} id
 * @param {Object} data
 */
export async function updatePapProject(id, data) {
  const res = await axios.patch(`/v1/admin/pap/projects/${id}`, data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * PAP 프로젝트를 삭제합니다.
 * @param {string} id
 */
export async function deletePapProject(id) {
  const res = await axios.delete(`/v1/admin/pap/projects/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 사진 업로드 항목을 가져옵니다.
 * @param {string} id
 */
export async function fetchPapDocument(id) {
  const res = await axios.get(`/v1/admin/pap/photos/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 사진 업로드 항목을 생성합니다.
 * @param {Object} data
 */
export async function createPapDocument(data) {
  const res = await axios.post('/v1/admin/pap/photos', data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 사진 업로드 항목을 수정합니다.
 * @param {string} id
 * @param {Object} data
 */
export async function updatePapDocument(id, data) {
  const res = await axios.patch(`/v1/admin/pap/photos/${id}`, data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 사진 업로드 항목을 삭제합니다.
 * @param {string} id
 */
export async function deletePapDocument(id) {
  const res = await axios.delete(`/v1/admin/pap/photos/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목을 가져옵니다.
 * @param {string} id
 */
export async function fetchPapTerm(termId, versionId) {
  const res = await axios.get(`/v1/admin/pap/terms/${termId}/${versionId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목을 생성합니다.
 * @param {Object} data
 */
export async function createPapTerm(data) {
  const res = await axios.post('/v1/admin/pap/terms', data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목을 수정합니다.
 * @param {string} id
 * @param {Object} data
 */
export async function updatePapTerm(id, data) {
  const res = await axios.patch(`/v1/admin/pap/terms/${id}`, data);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목을 삭제합니다.
 * @param {string} id
 */
export async function deletePapTerm(id) {
  const res = await axios.delete(`/v1/admin/pap/terms/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관 항목의 특정 버전을 삭제합니다.
 * @param {string} id
 * @param {string} versionId
 */
export async function deletePapTermVersion(id, versionId) {
  const res = await axios.delete(`/v1/admin/pap/terms/${id}/${versionId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 약관에 대한 재동의를 요청하는 푸시 알림을 발송합니다.
 * @param {string} id - PAP project ID
 * @param {boolean} isNok - 대리인 푸시 발송 여부
 */
export async function requestPapTermReAgreement(id, isNok = false) {
  const res = await axios.post(
    `/v1/admin/pap/projects/${id}/notifications${
      isNok ? '?isRefundNOK=true' : ''
    }`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * PAP 프로젝트에 대한 신청 리스트를 가져옵니다.
 * @param {string} id
 */
export async function fetchPapProjectRequests(id) {
  const res = await axios.get(`/v1/admin/pap/requests/projects/${id}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 유저의 PAP 신청내역을 가져옵니다.
 * @param {string} userId
 */
export async function fetchPapUserRequest(userId) {
  const res = await axios.get(`/v1/admin/pap/requests/users/${userId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 유저의 PAP 신청내역을 수정합니다.
 * @param {string} requestId
 * @param {Object} data
 * @param {boolean} sendPush
 */
export async function updatePapUserRequest(requestId, data, sendPush) {
  const res = await axios.patch(
    `/v1/admin/pap/requests/${requestId}${sendPush ? '?isSendPush=true' : ''}`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * 유저의 PAP 신청의 변경내역을 가져옵니다.
 * @param {string} requestId
 */
export async function fetchPapUserRequestHistories(requestId) {
  const res = await axios.get(`/v1/admin/pap/requests/${requestId}/histories`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * PAP에 제출된 문서를 다운로드합니다.
 * @param {string} requestId
 * @param {string} documentId
 */
export async function downloadPapDocument(requestId, documentId) {
  const res = await axios.get(
    `/v1/admin/pap/requests/${requestId}/documents/${documentId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

/**
 * PAP에 제출된 문서 전체를 다운로드합니다.
 * @param {string} requestId
 */
export async function downloadPapDocuments(requestId) {
  const res = await axios.get(`/v1/admin/pap/requests/${requestId}/documents`, {
    responseType: 'blob',
  });
  return res.data;
}

/**
 * PAP 프로젝트에 대한 대량 이체 엑셀을 다운로드합니다.
 * @param {string} id
 */
export async function downloadPapProjectFinanceExcel(id) {
  const res = await axios.get(`/v1/admin/pap/projects/${id}/finance`);
  return res.data;
}

/**
 * PAP 프로젝트에 대한 제약사 보고 엑셀을 다운로드합니다.
 * @param {string} id
 */
export async function downloadPapProjectReportExcel(id) {
  const res = await axios.get(`/v1/admin/pap/projects/${id}/report`);
  return res.data;
}
