import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Select,
  Divider,
  Skeleton,
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import useWindow from '../../../hooks/useWindow';
import { useMutate, useFetch } from '../../../hooks/useRequest';
import {
  fetchClinicalTrialUserCounseling as fetchClinicalTrialCounselingContentService,
  patchClinicalTrialCounseling as patchClinicalTrialCounselingContentService,
  deleteClinicalTrialCounseling as deleteClinicalTrialCounselingContentService,
} from '../../../services/clinicalTrialCounselingService';
import * as listMap from '../../../util/listMap';
import DisabledInput from '../../components/disabledInput';

const ClinicalTrialCounselingHistory = (userId) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const { findWindowById, createWindow } = useWindow();

  const handleCreateEditWindow = (record) => {
    const recordId = record.split('/').pop();
    if (findWindowById(recordId)) {
      alert('이미 편집중인 글입니다.');
      return;
    }
    createWindow({
      id: recordId,
      dataType: 'clinicalTrial',
    });
  };

  const [
    patchClinicalTrialCounselingRequestId,
    setPatchClinicalTrialCounselingRequestId,
  ] = useState(null);
  const [
    deleteClinicalTrialCounselingRequestId,
    setDeleteClinicalTrialCounselingRequestId,
  ] = useState(null);
  const [
    clinicalTrialCounselingStatus,
    setClinicalTrialCounselingStatus,
  ] = useState({});
  const [
    clinicalTrialCounselingMemo,
    setClinicalTrialCounselingMemo,
  ] = useState({});
  const clinicalTrialCounselingMemoDone = useRef(false);
  const userDataFetchDone = useRef(false);

  const {
    done: fetchClinicalTrialCounselingContentDone,
    data: fetchClinicalTrialCounselingContentData,
    call: fetchClinicalTrialCounselingContent,
  } = useFetch(
    null,
    fetchClinicalTrialCounselingContentService,
    userId?.userId,
  );

  useEffect(() => {
    if (
      !fetchClinicalTrialCounselingContentDone &&
      userId &&
      !userDataFetchDone.current
    ) {
      fetchClinicalTrialCounselingContent();
      userDataFetchDone.current = true;
    }
  }, [fetchClinicalTrialCounselingContentDone, userId, userDataFetchDone]);

  useEffect(() => {
    if (
      fetchClinicalTrialCounselingContentData &&
      !clinicalTrialCounselingMemoDone.current
    ) {
      const clinicalTrialCounselingMemoList = {};
      const clinicalTrialcousnselingStatusList = {};
      fetchClinicalTrialCounselingContentData.forEach((counselingHistory) => {
        clinicalTrialCounselingMemoList[counselingHistory.id] =
          counselingHistory.memo;
        clinicalTrialcousnselingStatusList[counselingHistory.id] =
          counselingHistory.counselingStatus;
      });
      setClinicalTrialCounselingStatus(clinicalTrialcousnselingStatusList);
      setClinicalTrialCounselingMemo(clinicalTrialCounselingMemoList);
      clinicalTrialCounselingMemoDone.current = true;
    }
  }, [fetchClinicalTrialCounselingContentData]);

  const {
    mutate: patchClinicalTrialCounselingContent,
    done: isClinicalTrialCounselingPatched,
    initialize: initializeClinicalTrialCounselingPatch,
  } = useMutate(
    patchClinicalTrialCounselingContentService,
    patchClinicalTrialCounselingRequestId,
    {
      userId: userId?.userId,
      memo: clinicalTrialCounselingMemo[patchClinicalTrialCounselingRequestId],
      counselingStatus:
        clinicalTrialCounselingStatus[patchClinicalTrialCounselingRequestId],
    },
  );

  const {
    mutate: deleteClinicalTrialCounseling,
    done: isClinicalTrialCounselingDeleted,
    initialize: initializeClinicalTrialCounselingDelete,
  } = useMutate(
    deleteClinicalTrialCounselingContentService,
    deleteClinicalTrialCounselingRequestId,
  );

  useEffect(() => {
    if (isClinicalTrialCounselingPatched) {
      fetchClinicalTrialCounselingContent();
      initializeClinicalTrialCounselingPatch();
      notification.success({
        message: '수정이 완료되었습니다.',
        description: '수정이 완료되었습니다.',
      });
    }
    if (isClinicalTrialCounselingDeleted) {
      fetchClinicalTrialCounselingContent();
      initializeClinicalTrialCounselingDelete();
      notification.success({
        message: '삭제가 완료되었습니다.',
        description: '삭제가 완료되었습니다.',
      });
    }
  }, [
    isClinicalTrialCounselingPatched,
    isClinicalTrialCounselingDeleted,
    fetchClinicalTrialCounselingContent,
    initializeClinicalTrialCounselingDelete,
    initializeClinicalTrialCounselingPatch,
  ]);

  useEffect(() => {
    if (patchClinicalTrialCounselingRequestId) {
      clinicalTrialCounselingMemoDone.current = false;
      patchClinicalTrialCounselingContent();
      setPatchClinicalTrialCounselingRequestId(null);
    }
  }, [
    patchClinicalTrialCounselingRequestId,
    patchClinicalTrialCounselingContent,
  ]);

  useEffect(() => {
    if (deleteClinicalTrialCounselingRequestId) {
      clinicalTrialCounselingMemoDone.current = false;
      deleteClinicalTrialCounseling();
      setDeleteClinicalTrialCounselingRequestId(null);
    }
  }, [deleteClinicalTrialCounselingRequestId, deleteClinicalTrialCounseling]);

  if (!userDataFetchDone.current) return <Skeleton active />;
  return (
    <>
      {fetchClinicalTrialCounselingContentData?.length > 0 ? (
        <>
          {fetchClinicalTrialCounselingContentData?.map((counselingContent) => (
            <React.Fragment key={counselingContent.id}>
              <Form
                name="basic"
                form={form}
                initialValues={{
                  funnel:
                    counselingContent.funnel === 'treatment_carousel'
                      ? '배너'
                      : counselingContent.funnel,
                }}
                {...listMap.layout}
              >
                <Row span={24}>
                  <Col span={12}>
                    <DisabledInput
                      label="신청일"
                      type="createdAt"
                      value={
                        counselingContent.createdAt &&
                        moment(counselingContent.createdAt).format('YYYY.MM.DD')
                      }
                    />
                    <DisabledInput
                      label="희망일시"
                      type="applicationDate"
                      value={
                        counselingContent.applicationDate &&
                        moment(counselingContent.applicationDate).format(
                          'YYYY.MM.DD',
                        )
                      }
                    />
                    <DisabledInput
                      label="신청연락처"
                      type="contactPhone"
                      value={counselingContent.contactPhone}
                    />
                  </Col>
                  <Col span={12}>
                    <DisabledInput
                      label="상담질환"
                      type="diseaseType"
                      value={counselingContent.diseaseType}
                    />
                    <DisabledInput
                      label="상담희망시간"
                      type="applicationTime"
                      value={
                        counselingContent.applicationTime &&
                        `${counselingContent.applicationTime}시 ~ ${
                          counselingContent.applicationTime + 1
                        }시`
                      }
                    />
                    <Form.Item
                      label="유입경로"
                      name="funnel"
                      labelCol={listMap.layout.labelCol}
                      wrapperCol={listMap.layout.wrapperCol}
                      onClick={() => {
                        if (counselingContent.funnel !== 'treatment_carousel') {
                          handleCreateEditWindow(counselingContent.funnel);
                        }
                      }}
                    >
                      <Input disabled />
                    </Form.Item>
                  </Col>
                </Row>
                {counselingContent.topic && (
                  <Row span={24}>
                    <Col span={24}>
                      <DisabledInput
                        label="상담목적"
                        type="topic"
                        value={counselingContent.topic}
                        size={true}
                      />
                    </Col>
                  </Row>
                )}
                <Row span={24}>
                  <Col span={24}>
                    <Form.Item
                      label="상담 내역"
                      labelCol={listMap.largeLayout.labelCol}
                      wrapperCol={listMap.largeLayout.wrapperCol}
                    >
                      <Select
                        showSearch
                        allowClear
                        status={
                          clinicalTrialCounselingStatus[
                            counselingContent.id
                          ] === 'apply' && 'warning'
                        }
                        style={{ width: '100%' }}
                        placeholder="상태를 선택해주세요"
                        disabled={
                          clinicalTrialCounselingStatus[
                            counselingContent.id
                          ] === 'cancel'
                        }
                        value={
                          clinicalTrialCounselingStatus[counselingContent.id]
                        }
                        onChange={(_, data) => {
                          setClinicalTrialCounselingStatus({
                            ...clinicalTrialCounselingStatus,
                            [counselingContent.id]: data.value,
                          });
                        }}
                      >
                        {listMap.counselingStatusOptions?.map((status) => (
                          <Select.Option
                            id={status?.value}
                            key={status?.value}
                            value={status?.value}
                          >
                            {status?.text}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      label="상담 내역"
                      labelCol={listMap.largeLayout.labelCol}
                      wrapperCol={listMap.largeLayout.wrapperCol}
                    >
                      <TextArea
                        rows={4}
                        value={
                          clinicalTrialCounselingMemo[counselingContent.id]
                        }
                        onChange={(e) =>
                          setClinicalTrialCounselingMemo({
                            ...clinicalTrialCounselingMemo,
                            [counselingContent.id]: e.target.value,
                          })
                        }
                      />
                    </Form.Item>
                    <Form.Item
                      wrapperCol={{
                        offset: 4,
                        span: 20,
                      }}
                    >
                      <Button
                        type="primary"
                        htmlType="submit"
                        style={{ width: 100 }}
                        onClick={() => {
                          setPatchClinicalTrialCounselingRequestId(
                            counselingContent.id,
                          );
                        }}
                      >
                        저장
                      </Button>
                      <Button
                        danger
                        htmlType="button"
                        style={{ width: 100, marginLeft: 8 }}
                        onClick={() => {
                          if (window.confirm('신청 내역을 삭제하시겠습니까?')) {
                            setDeleteClinicalTrialCounselingRequestId(
                              counselingContent.id,
                            );
                          }
                        }}
                      >
                        삭제
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
              <Divider plain />
            </React.Fragment>
          ))}
        </>
      ) : (
        <div style={{ marginLeft: 10, color: 'red' }}>
          임상상담 이력이 없습니다.
        </div>
      )}
    </>
  );
};

export default ClinicalTrialCounselingHistory;
