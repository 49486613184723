import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  DatePicker,
  Upload,
  Select,
  notification,
} from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import '../../../public/css/window.css';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import moment from 'moment';
import Lottie from 'react-lottie-player';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';
import { treatmentNewsCreators } from '../../../store/reducers/treatmentNews.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { noticeCreators } from '../../../store/reducers/notice.reducer';
import { dnaCreators } from '../../../store/reducers/dna.reducer';

import { useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useAntFileUpload } from '../../util/useAntFileImage';
import MoveAppUrl from '../../components/moveAppUrl';
import * as listMap from '../../../util/listMap';
import {
  deleteEntryModal,
  patchEntryModal,
  postEntryModal,
} from '../../../services/entryModalService';
import { rssFeedCreators } from '../../../store/reducers/rssFeed.reducer';
import { papProjectCreators } from '../../../store/reducers/papProject.reducer';

const EntryModalWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    treatmentNews,
    diseaseTypesInfo,
    notices,
    dnas,
    rssFeed,
    pap,
  } = useSelector((state) => {
    return {
      treatmentNews: state.treatmentNewsReducer.treatmentNews.data?.map(
        (treatment) => ({
          ...treatment,
          key: treatment?.id,
        }),
      ),
      diseaseTypesInfo: state.diseaseTypeReducer.diseaseTypes?.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
      notices: state.noticeReducer.notices.data,
      dnas: state.dnaReducer.dna?.data,
      rssFeed: state.rssFeedReducer?.rssFeed?.data,
      pap: state.papProjectReducer?.papProject?.data?.data,
    };
  }, shallowEqual);

  const initialState = {
    title: '',
    content: null,
    image: '',
    buttonText: '',
    hasMakeUrl: true,
    link: '',
    releaseTarget: 'all',
    diseaseTypeIds: [],
    isAppStartAt: moment(new Date().setHours(0, 0, 0, 0)),
    isAppEndAt: moment(new Date().setHours(23, 59, 59, 0)),
    isApp: false,
    isLottie: false,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [entryModalId, setEntryModalId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [paramData, setParamData] = useState(null);
  const [patchData, setPatchData] = useState(null);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const [isLottie, setIsLottie] = useState(false);
  const fileUploadSetting = useRef(false);
  const didCancel = useRef(false);
  const uploadCancel = useRef(false);
  const { RangePicker } = DatePicker;

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useAntFileUpload(distinctFileInputEvent);

  const postData = {
    title: state.title,
    content: state.content,
    image: state.image ?? null,
    isLottie,
    buttonText: state.buttonText,
    link: state.hasMakeUrl ? state.link : '',
    diseaseTypeIds: state.diseaseTypeIds ?? [],
    isAppStartAt: moment(state.isAppStartAt),
    isAppEndAt: moment(state.isAppEndAt),
    isApp: false,
  };

  const { mutate: putEntryModal, done: isPosted } = useMutate(
    postEntryModal,
    postData,
  );

  const { mutate: changeEntryModal, done: isPatched } = useMutate(
    patchEntryModal,
    entryModalId,
    patchData,
  );

  const { mutate: eraseEntryModal, done: isDeleted } = useMutate(
    deleteEntryModal,
    entryModalId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? JSON.parse(params.id).id : params.new);
    dispatch(
      treatmentNewsCreators.fetchAllTreatmentNews.request({
        params: '',
      }),
    );
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(noticeCreators.fetchNotices.request());
    dispatch(dnaCreators.fetchAllDnas.request());
    dispatch(rssFeedCreators.fetchAllRssFeeds.request());
    dispatch(papProjectCreators.fetchAllPapProjects.request());
    if (params.id) {
      const parseParams = JSON.parse(params.id);
      setEntryModalId(parseParams.id);
      setParamData(parseParams);
      setState({
        title: parseParams.title,
        content: parseParams.content,
        image: parseParams.image,
        buttonText: parseParams.buttonText,
        link: parseParams.link,
        releaseTarget:
          parseParams.diseaseTypeIds.length === 0 ? 'all' : 'select',
        diseaseTypeIds: parseParams.diseaseTypeIds,
        isAppStartAt: moment(parseParams.isAppStartAt),
        isAppEndAt: moment(parseParams.isAppEndAt).endOf('day'),
      });
      setIsLottie(parseParams.isLottie);
      didCancel.current = true;
      setIsLoading(false);
    } else {
      setEntryModalId('');
      didCancel.current = true;
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      title: values.title,
      content: values.content,
      buttonText: values.buttonText,
      link: values.link,
    });
    if (entryModalId) {
      setPatchData(null);
      const tempsData = {};
      // title
      if (paramData.title !== values.title)
        Object.assign(tempsData, { title: values.title });
      // content
      if (paramData.content !== state.content)
        Object.assign(tempsData, { content: state.content });
      // image && isLottie
      if (paramData.image !== state.image)
        Object.assign(tempsData, {
          image: state.image ?? null,
          isLottie,
        });
      // buttonText
      if (paramData.buttonText !== values.buttonText)
        Object.assign(tempsData, {
          buttonText: values.buttonText,
        });
      // isAppStartAt
      if (
        moment(paramData.isAppStartAt).format('YYYY-MM-DD') !==
        moment(state.isAppStartAt).format('YYYY-MM-DD')
      )
        Object.assign(tempsData, { isAppStartAt: moment(state.isAppStartAt) });
      // isAppEndAt
      if (
        moment(paramData.isAppEndAt).format('YYYY-MM-DD') !==
        moment(state.isAppEndAt).format('YYYY-MM-DD')
      )
        Object.assign(tempsData, {
          isAppEndAt: moment(state.isAppEndAt).endOf('day'),
        });
      // link
      if (paramData.link !== state.link)
        Object.assign(tempsData, {
          link: state.hasMakeUrl ? state.link : '',
        });
      // diseaseTypeIds
      if (
        state?.diseaseTypeIds?.sort().join(',') !==
        paramData?.diseaseTypeIds?.sort().join(',')
      )
        Object.assign(tempsData, {
          diseaseTypeIds: state.diseaseTypeIds ?? [],
        });
      setPatchData(tempsData);
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
      return;
    }
    if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseEntryModal();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeEntryModal();
      setIsPatch(false);
    }
    if (isPost) {
      putEntryModal();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  const beforeUpload = (file) => {
    const isValidFile =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'application/json';
    if (!isValidFile) {
      notification.error({
        message: 'JPG/PNG/JSON만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isValidFile && isLt10M;
  };

  const uploadImg = async (e) => {
    if (e !== undefined) {
      setIsLottie(e.file.name.endsWith('.json'));
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const noop = () => {};

  const deleteThumbImg = () => {
    setState({
      image: '',
    });
    notification.success({
      message: '이미지를 삭제하였습니다.',
      description: '이미지를 다시 업로드해주세요.',
    });
  };

  const uploadProps = {
    customRequest: noop,
    onChange: uploadImg,
    onPreview: noop,
  };

  useEffect(() => {
    if (distinctFileInputEvent && uploadCancel.current) {
      distinctImageUpload();
      uploadCancel.current = false;
      fileUploadSetting.current = true;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '이미지 등록에 성공하였습니다.',
      });
      setState({ image: distinctPublicUrl });
      distinctUploadInitialize();
      setLoading(false);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const textCopy = (resultUrl) => {
    setState({
      link: resultUrl,
    });
    form.setFieldsValue({
      link: resultUrl,
    });
    notification.success({
      message: '링크 복사를 완료하였습니다.',
      description: '링크를 확인해 주세요.',
    });
  };

  const hasMakeUrl = (check) => {
    setState({
      hasMakeUrl: check,
    });
  };

  if (isLoading) return <ElementLoading type="앱 진입 모달" />;
  return (
    <>
      <WindowHeader title="앱 진입 모달" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              title: state.title,
              content: state.content,
              buttonText: state.buttonText,
              hasMakeUrl: state.hasMakeUrl,
              link: state.link,
              releaseTarget: state.releaseTarget,
              diseaseTypeIds: state.diseaseTypeIds,
              ...state,
            }}
          >
            <Form.Item
              label="제목"
              name="title"
              rules={[
                {
                  required: true,
                  message: '제목을 입력해주세요.',
                },
                {
                  max: 30,
                  message: '30자 내로 작성해 주세요.',
                },
              ]}
            >
              <Input placeholder="\n을 쓸 경우 줄바꿈, 최대 30자 이내로 작성해 주세요." />
            </Form.Item>
            <Form.Item label="서브 문구" name="content">
              <Input
                placeholder="서브 문구를 입력하세요."
                onChange={(e) => setState({ content: e.target.value })}
              />
            </Form.Item>
            <Form.Item
              label="노출 기간"
              name="isAppPeriod"
              rules={[
                {
                  required: true,
                  message: '노출 기간을 확인해주세요.',
                },
              ]}
              initialValue={[
                moment(state.isAppStartAt),
                moment(state.isAppEndAt).endOf('day'),
              ]}
            >
              <RangePicker
                value={[moment(state.isAppStartAt), moment(state.isAppEndAt)]}
                onChange={(date, dateString) => {
                  setState({
                    isAppStartAt: dateString[0],
                    isAppEndAt: dateString[1],
                  });
                }}
              />
            </Form.Item>
            <Form.Item label="이미지" name="image">
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.image ? (
                  isLottie ? (
                    <Lottie
                      path={state.image}
                      play
                      loop
                      style={{
                        width: '100%',
                      }}
                    />
                  ) : (
                    <img
                      src={state?.image}
                      alt="avatar"
                      style={{
                        width: '100%',
                      }}
                    />
                  )
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.image && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={deleteThumbImg}
                >
                  이미지 삭제
                </Button>
              )}
            </Form.Item>
            <Form.Item
              label="버튼 문구"
              name="buttonText"
              rules={[
                { max: 15, message: '15자 내로 작성해 주세요.' },
                { required: true, message: '버튼 문구를 입력해주세요.' },
              ]}
            >
              <Input placeholder="15자 내로 작성해 주세요." />
            </Form.Item>
            <MoveAppUrl
              type="entryModal"
              hasMakeUrl={hasMakeUrl}
              textCopy={textCopy}
              treatmentNews={treatmentNews}
              notices={notices}
              dnas={dnas}
              rssFeed={rssFeed}
              pap={pap}
            />
            <Form.Item
              label="링크"
              name="link"
              rules={[
                {
                  required: true,
                  message: '링크를 확인해주세요.',
                },
              ]}
            >
              <Input disabled />
            </Form.Item>
            <Form.Item
              name="releaseTarget"
              label="노출 유저"
              rules={[
                {
                  required: true,
                  message: '노출 유저를 확인해주세요.',
                },
              ]}
            >
              <Select
                showSearch
                allowClear
                style={{ width: '100%' }}
                placeholder="노출 유저를 선택해주세요."
                name="releaseTarget"
                onChange={(e) => {
                  setState({
                    releaseTarget: e,
                  });
                  if (e === 'all') {
                    setState({
                      diseaseTypeIds: [],
                    });
                  }
                }}
              >
                {listMap.entryModalTargetOptions?.map((target) => (
                  <Select.Option
                    key={target?.value}
                    id={target?.value}
                    value={target?.value}
                  >
                    {target?.label}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {state.releaseTarget === 'select' && (
              <Form.Item
                name="diseaseTypeIds"
                wrapperCol={{
                  offset: 4,
                  span: 20,
                }}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: '100%' }}
                  placeholder="질환 태그를 선택해주세요"
                  filterOption={(input, option) =>
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.props.key
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e) =>
                    setState({
                      diseaseTypeIds: e,
                    })
                  }
                >
                  {diseaseTypesInfo?.map((diseaseType) => (
                    <Select.Option
                      key={diseaseType?.krName}
                      id={diseaseType?.krName}
                      value={diseaseType?.id}
                    >
                      {diseaseType.krName}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {entryModalId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="에러 메시지를 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default EntryModalWindow;
