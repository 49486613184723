import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 특정 유저의 유전자 검사 상담 내역을 가져옵니다.
 * @param {String} userId
 */
export async function fetchDnaCounselingContent(userId) {
  const res = await axios.get(
    `/v3/admin/users/${userId}/dnaCounselingRequests`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 유전자 검사 상담 내역을 수정합니다.
 * @param {String} dnaCounselingRequestId
 * @param {String} userId
 * @param {String} counselingStatus
 * @param {String} memo
 */
export async function patchDnaCounselingContent(
  dnaCounselingRequestId,
  { userId, counselingStatus, memo },
) {
  const res = await axios.patch(
    `/v3/admin/dnaCounselingRequests/${dnaCounselingRequestId}`,
    {
      userId,
      counselingStatus,
      memo,
    },
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유전자 검사 상담 내역을 삭제합니다.
 * @param {String} dnaCounselingRequestId
 */
export async function deleteDnaCounselingContent(dnaCounselingRequestId) {
  const res = await axios.delete(
    `/v3/admin/dnaCounselingRequests/${dnaCounselingRequestId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
