import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import DndTable from '../../components/dndTable';
import { Button, Input, Layout, Row, Select, Space } from 'antd';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import useWindow from '../../../hooks/useWindow';
import { NEW } from '../../../window/util/utils';
import { fetchHealthProfileGroups } from '../../../services/healthProfileGroupService';
import { useHistory } from 'react-router-dom';
import { SearchOutlined } from '@ant-design/icons';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';

const HealthProfileGroup = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pagination, diseaseTypeInfo } = useSelector((state) => {
    return {
      pagination: state.paginationReducer.healthProfileGroupPagination,
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data ?? [],
    };
  });

  const diseaseTypeList = useMemo(
    () =>
      diseaseTypeInfo?.map((d) => ({
        label: d.krName,
        value: d.id,
      })),
    [diseaseTypeInfo],
  );

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(true);
  const confirmRef = useRef();
  const { createWindow, destroyWindowById } = useWindow();

  const getData = useCallback(async () => {
    setLoading(true);
    const res = await fetchHealthProfileGroups();
    setDataSource(res);
    dispatch(paginationCreators.setValue((pagination.total = res.length)));
    setLoading(false);
  }, []);

  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  useEffect(() => {
    getData();
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage, getData]);

  const handleCreateNewHealthProfileGroupWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'healthProfileGroup' });
  };

  const TagWrapper = ({ text }) => (
    <div className="tagWrapper" style={{ color: 'rgb(51, 51, 51)' }}>
      {text}
    </div>
  );

  const columns = [
    {
      title: '적용일',
      dataIndex: 'appliedAt',
      key: 'appliedAt',
      render: (value) => moment(value).format('YY.MM.DD'),
      sorter: (a, b) => a.appliedAt - b.appliedAt,
    },
    {
      title: '주기 상태',
      dataIndex: 'isRunning',
      key: 'isRunning',
      render: (value, record) => {
        const NOW = moment();
        const START = moment(record.appliedAt);
        if (NOW.isBefore(START)) return '대기 중';
        if (value) return '진행 중';
        return '주기 종료';
      },
      filters: [
        {
          text: '대기 중',
          value: '대기 중',
        },
        {
          text: '진행 중',
          value: '진행 중',
        },
        {
          text: '주기 종료',
          value: '주기 종료',
        },
      ],
      onFilter: (value, record) => {
        const NOW = moment();
        const START = moment(record.appliedAt);
        if (NOW.isBefore(START)) return '대기 중' === value;
        if (record.isRunning) return '진행 중' === value;
        return '주기 종료' === value;
      },
    },
    {
      title: '주기적 설문 그룹명',
      dataIndex: 'title',
      key: 'title',
      render: (value, record) => (
        <Button
          type="link"
          onClick={() => {
            createWindow({ id: record.id, dataType: 'healthProfileGroup' });
          }}
        >
          {value}
        </Button>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
      onFilter: (value, record) => {
        return record.title.includes(value);
      },
      filterDropdown: ({ confirm, setSelectedKeys }) => {
        confirmRef.current = (keyword) => {
          if (keyword.length > 0) {
            setSelectedKeys([keyword]);
            dispatch(
              paginationCreators.setValue((pagination.filter.title = keyword)),
            );
          } else {
            setSelectedKeys(null);
            dispatch(
              paginationCreators.setValue((pagination.filter.title = null)),
            );
          }

          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      render: (diseaseTypes) => (
        <>
          {diseaseTypes?.map((d) => (
            <TagWrapper text={d.krName} key={d.id} />
          ))}
          {diseaseTypes?.length === 0 && '-'}
        </>
      ),
      filters: diseaseTypeList,
      onFilter: (value, record) => {
        const isDiseaseMatched = record.diseaseTypes
          .map((d) => d.id)
          .includes(value);
        const isAllDiseaseMatched =
          value === 'all' && record.diseaseTypes?.length === 0;
        return isDiseaseMatched || isAllDiseaseMatched;
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div
          style={{
            padding: 8,
          }}
          onKeyDown={(e) => e.stopPropagation()}
        >
          <Select
            style={{
              marginRight: 8,
              width: 250,
            }}
            placement="bottomLeft"
            defaultValue={pagination.filter.diseaseTypes ?? null}
            value={selectedKeys}
            showSearch
            mode="multiple"
            placeholder="질환을 선택하세요"
            options={diseaseTypeList}
            filterOption={(input, { label }) =>
              label.toLowerCase().includes(input.toLowerCase())
            }
            onChange={(e) => setSelectedKeys(e)}
          />
          <Space>
            <Button
              type="primary"
              onClick={() => confirm()}
              icon={<SearchOutlined />}
              size="small"
              style={{
                width: 90,
              }}
            >
              Search
            </Button>
            <Button
              onClick={() => clearFilters()}
              size="small"
              style={{
                width: 90,
              }}
            >
              Reset
            </Button>
          </Space>
        </div>
      ),
    },
    {
      title: '응답 유저 페이지',
      key: 'userPage',
      render: (_, record) => (
        <Button
          onClick={() => {
            history.push(`/healthProfileGroupUsers`, {
              healthProfileGroupId: record.id,
              groupId: record.id,
            });
          }}
        >
          응답 유저 페이지 &gt;
        </Button>
      ),
    },
    {
      title: '참여자',
      dataIndex: 'userCount',
      key: 'userCount',
      render: (value) => `${value}명`,
      sorter: (a, b) => a.userCount - b.userCount,
    },
    {
      title: '차수별 설문',
      dataIndex: 'rounds',
      key: 'rounds',
      render: (value) => (
        <>
          {value?.map((round, idx) => (
            <Row key={round.id} align="middle">
              <b>[{idx + 1}차]&nbsp;</b>
              {round.healthProfiles?.map((profile, index) => (
                <React.Fragment key={profile.id}>
                  <Button
                    type="link"
                    style={{ padding: 0 }}
                    onClick={() => {
                      history.push(`/healthProfileQuestion`, {
                        healthProfileId: profile.id,
                        title: profile.type,
                      });
                    }}
                  >
                    {profile.type}
                  </Button>
                  {index !== round.healthProfiles?.length - 1 && (
                    <span>&nbsp;&gt;&nbsp;</span>
                  )}
                </React.Fragment>
              ))}
            </Row>
          ))}
        </>
      ),
    },
    {
      title: '푸시 주기',
      dataIndex: 'pushPeriod',
      key: 'pushPeriod',
      render: (value) => `${value}일`,
      sorter: (a, b) => a.pushPeriod - b.pushPeriod,
    },
  ];
  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="healthProfileGroup" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="운영"
            subTitle="주기적 설문"
            className="white-bg"
          />
          <Layout.Content className="site-layout-background contentStyle">
            <Row justify="end">
              <span className="searchResult">
                검색결과 {pagination.total}개
              </span>
              <Input.Search
                placeholder="검색어를 입력해주세요."
                allowClear
                className="searchStyle"
                onSearch={(v) => confirmRef.current(v)}
                defaultValue={pagination.filter.title}
              />
            </Row>
            <Row justify="space-between" style={{ marginBottom: 8 }}>
              <Row style={{ gap: 6 }}>
                <Button
                  type="primary"
                  onClick={handleCreateNewHealthProfileGroupWindow}
                >
                  주기적 설문 등록하기
                </Button>
              </Row>
            </Row>
            <DndTable
              dataSource={dataSource}
              columns={columns}
              loading={loading}
              setDataSource={setDataSource}
              pagination={{
                pageSize: pagination.pageSize,
                showSizeChanger: true,
              }}
              onChange={(page, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (pagination.page = page.current),
                    (pagination.pageSize = page.pageSize),
                    (pagination.filter = filters),
                    (pagination.sorter = sorter),
                    (pagination.total = extra?.currentDataSource?.length),
                  ),
                );
              }}
            />
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HealthProfileGroup;
