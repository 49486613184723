import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import {
  Button,
  Collapse,
  Divider,
  Form,
  Input,
  Row,
  Select,
  Typography,
} from 'antd';
import React, { useState } from 'react';

const RoundItem = ({ round, form, removeRound, healthProfiles }) => {
  const [searchInput, setSearchInput] = useState();
  return (
    <Collapse>
      <Collapse.Panel
        style={{ alignItems: 'center' }}
        header={`${round.name + 1}차 설문`}
        extra={
          removeRound ? (
            <Button danger onClick={removeRound} size="small">
              삭제
            </Button>
          ) : undefined
        }
        key="1"
      >
        <Form.Item noStyle name={[round.name, 'id']} hidden>
          <Input />
        </Form.Item>
        <Form.List name={[round.name, 'healthProfileIds']}>
          {(fields, { add, remove }) => (
            <>
              <Row style={{ marginBottom: 24 }}>
                <Select
                  showSearch
                  options={healthProfiles}
                  onChange={setSearchInput}
                  value={searchInput}
                  autoClearSearchValue
                  filterOption={(input, option) =>
                    option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ width: 'auto', flex: 1 }}
                  placeholder="설문 구분, 설문명으로 검색하세요. (‘질환 선택’에서 설정한 질환의 설문만 검색됩니다)"
                />
                <Button
                  onClick={() => {
                    if (!searchInput) return;
                    add(searchInput);
                    setSearchInput(null);
                  }}
                  type="primary"
                >
                  추가
                </Button>
              </Row>

              {fields.map((field, index) => {
                const rowData = form.getFieldValue([
                  'rounds',
                  round.name,
                  'healthProfileIds',
                ]);
                return (
                  <React.Fragment key={field.key}>
                    {index > 0 && <Divider />}
                    <Row style={{ width: '100%' }} justify="space-between">
                      <Typography.Text>
                        {index + 1}번 설문:{' '}
                        {healthProfiles.find((x) => x.value === rowData[index])
                          ?.label || (
                          <Typography.Text type="danger">
                            유효하지 않은 설문입니다.
                          </Typography.Text>
                        )}
                      </Typography.Text>
                      <Row style={{ gap: 4 }}>
                        <Button
                          onClick={() => {
                            const data = form.getFieldValue([
                              'rounds',
                              round.name,
                              'healthProfileIds',
                            ]);
                            const idx = data.indexOf(rowData[index]);
                            if (idx > 0) {
                              const temp = data[idx];
                              data[idx] = data[idx - 1];
                              data[idx - 1] = temp;
                              form.setFieldsValue({
                                rounds: [...form.getFieldValue('rounds')],
                              });
                            }
                          }}
                          icon={<CaretUpOutlined />}
                        />

                        <Button
                          onClick={() => {
                            const data = form.getFieldValue([
                              'rounds',
                              round.name,
                              'healthProfileIds',
                            ]);
                            const idx = data.indexOf(rowData[index]);
                            if (idx < data.length - 1) {
                              const temp = data[idx];
                              data[idx] = data[idx + 1];
                              data[idx + 1] = temp;
                              form.setFieldsValue({
                                rounds: [...form.getFieldValue('rounds')],
                              });
                            }
                          }}
                          icon={<CaretDownOutlined />}
                        />

                        <Button
                          danger
                          onClick={() => {
                            remove(field.name);
                          }}
                        >
                          삭제
                        </Button>
                      </Row>
                    </Row>
                  </React.Fragment>
                );
              })}
            </>
          )}
        </Form.List>
      </Collapse.Panel>
    </Collapse>
  );
};

export default RoundItem;
