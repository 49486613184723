import React, {
  useEffect,
  useState,
  useRef,
  useReducer,
  useCallback,
} from 'react';
import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  Radio,
  Alert,
  Row,
  Col,
} from 'antd';
import '../../../public/css/window.css';
import 'bootstrap';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';
import {
  fetchMutationDna,
  postMutationDna,
  patchMutationDna,
  deleteMutationDna,
} from '../../../services/mutationDnaService';
import { useFetch } from '../../../hooks/useRequest';
import {
  INHERITANCE,
  ZYGOSITY,
  VARIANT_ANNOVAR,
  ANNOVAR,
  VEP,
  VARIANT_CLASSIFICATION,
  INSILICO_PREDICTION,
  SIFT,
  POLYPHEN,
  MUTATIONTASTER,
  CLINVAR,
  ACMG,
  CADD,
  GERP,
  POLYPHEN2,
  FATHMM,
  MUTATIONASSESSOR,
} from '../../../assets/mutationDnaOptions';
import { dnaCreators } from '../../../store/reducers/dna.reducer';

const Variant = ({ style, textObject }) => (
  <p style={style}>
    {textObject?.startText ?? '(변이 내용 없음)'}
    <b>{textObject?.boldText}</b>
    {textObject?.endText}
  </p>
);

const VariantHeader = ({ text, variant }) => (
  <div style={{ textAlign: 'center', paddingBottom: '8px', fontSize: '18px' }}>
    {text}
    <br />
    {variant && <i>{variant}</i>}
  </div>
);

const MutationDnaWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const dnas = useSelector((state) => state.dnaReducer.dna.data);

  const initialState = {
    selectedDna: null,
    nucleotide: '',
    aminoAcid: '',
    inheritance: '',
    zygosity: '',
    etcZygosity: '',
    variantType: [],
    variantTypeAnnoVar: null,
    variantTypeVep: null,
    pathogenicityClassification: null,
    pathogenicityPrediction: null,
    pathogenicitySift: null,
    pathogenicityPolyPhen: null,
    pathogenicityMutationtaster: null,
    pathogenicityClinVarSignificance: null,
    pathogenicityAcmgClassification: null,
    pathogenicityCadd: null,
    pathogenicityCaddValue: null,
    pathogenicityGerp: null,
    pathogenicityGerpValue: null,
    pathogenicityPolyPhen2: null,
    pathogenicityFathmm: null,
    pathogenicityMutationAssessor: null,
    frequency1000GenomesProject: '',
    frequencyExAcFreqEas: '',
    frequencyExAcFreqGlobal: '',
    frequencyKrgdb: '',
    frequencyGenomAd: '',
    frequencyGenomAdMaf: '',
    frequencyGenomAdEastAsian: '',
    frequencyGenomAdExome: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const { Option } = Select;
  const [form] = Form.useForm();
  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [mutationDnaId, setMutationDnaId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [modalContent, setModalContent] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);

  const didCancel = useRef(false);
  const [closeWindow, setCloseWindow] = useState(false);

  const getPostPatchData = useCallback(
    (currentState) => ({
      geneName: currentState.selectedDna ?? null,
      inheritance: currentState.inheritance,
      zygosity:
        currentState.zygosity === '기타'
          ? currentState.etcZygosity
          : currentState.zygosity,
      variantType: currentState.variantType,
      variantTypeAnnoVar: currentState.variantTypeAnnoVar,
      variantTypeVep: currentState.variantTypeVep,
      nucleotide:
        currentState.nucleotide === '' ? null : currentState.nucleotide,
      aminoAcid: currentState.aminoAcid === '' ? null : currentState.aminoAcid,
      pathogenicityClassification:
        currentState.pathogenicityClassification ?? null,
      pathogenicityPrediction: currentState.pathogenicityPrediction ?? null,
      pathogenicitySift: currentState.pathogenicitySift ?? null,
      pathogenicityPolyPhen: currentState.pathogenicityPolyPhen ?? null,
      pathogenicityMutationtaster:
        currentState.pathogenicityMutationtaster ?? null,
      pathogenicityClinVarSignificance:
        currentState.pathogenicityClinVarSignificance ?? null,
      pathogenicityAcmgClassification:
        currentState.pathogenicityAcmgClassification ?? null,
      pathogenicityCadd: currentState.pathogenicityCadd ?? null,
      pathogenicityCaddValue: currentState.pathogenicityCaddValue ?? null,
      pathogenicityGerp: currentState.pathogenicityGerp ?? null,
      pathogenicityGerpValue: currentState.pathogenicityGerpValue ?? null,
      pathogenicityPolyPhen2: currentState.pathogenicityPolyPhen2 ?? null,
      pathogenicityFathmm: currentState.pathogenicityFathmm ?? null,
      pathogenicityMutationAssessor:
        currentState.pathogenicityMutationAssessor ?? null,
      frequency1000GenomesProject:
        currentState.frequency1000GenomesProject === ''
          ? null
          : currentState.frequency1000GenomesProject,
      frequencyExAcFreqEas:
        currentState.frequencyExAcFreqEas === ''
          ? null
          : currentState.frequencyExAcFreqEas,
      frequencyExAcFreqGlobal:
        currentState.frequencyExAcFreqGlobal === ''
          ? null
          : currentState.frequencyExAcFreqGlobal,
      frequencyKrgdb:
        currentState.frequencyKrgdb === '' ? null : currentState.frequencyKrgdb,
      frequencyGenomAd:
        currentState.frequencyGenomAd === ''
          ? null
          : currentState.frequencyGenomAd,
      frequencyGenomAdMaf:
        currentState.frequencyGenomAdMaf === ''
          ? null
          : currentState.frequencyGenomAdMaf,
      frequencyGenomAdEastAsian:
        currentState.frequencyGenomAdEastAsian === ''
          ? null
          : currentState.frequencyGenomAdEastAsian,
      frequencyGenomAdExome:
        currentState.frequencyGenomAdExome === ''
          ? null
          : currentState.frequencyGenomAdExome,
    }),
    [],
  );

  const { data: mutationDnaData, call: fetchMutationDnaData } = useFetch(
    {},
    fetchMutationDna,
    mutationDnaId,
  );

  const handleCreate = useCallback(
    () =>
      postMutationDna(getPostPatchData(state))
        .then((mutationDna) => {
          window?.opener?.postMessage(
            `create ${JSON.stringify(mutationDna)}`,
            '/',
          );
          const {
            aminoAcid,
            aminoAcidTextObject,
            nucleotide,
            nucleotideTextObject,
          } = mutationDna;
          setModalContent({
            aminoAcid,
            aminoAcidTextObject,
            nucleotide,
            nucleotideTextObject,
          });
        })
        .catch(() => alert('변이 유전자 생성에 실패했습니다.')),
    [state, getPostPatchData],
  );

  const handleUpdate = useCallback(
    () =>
      patchMutationDna(mutationDnaId, getPostPatchData(state))
        .then((mutationDna) => {
          window?.opener?.postMessage(
            `update ${JSON.stringify(mutationDna)}`,
            '/',
          );
          const {
            aminoAcid,
            aminoAcidTextObject,
            nucleotide,
            nucleotideTextObject,
          } = mutationDna;
          setModalContent({
            aminoAcid,
            aminoAcidTextObject,
            nucleotide,
            nucleotideTextObject,
          });
        })
        .catch(() => alert('변이 유전자 수정에 실패했습니다.')),
    [mutationDnaId, state, getPostPatchData],
  );

  const handleRemove = useCallback(
    () =>
      deleteMutationDna(mutationDnaId)
        .then((mutationDna) => {
          window?.opener?.postMessage(
            `delete ${JSON.stringify(mutationDna)}`,
            '/',
          );
          alert(`변이 유전자를 삭제했습니다.`);
          setCloseWindow(true);
        })
        .catch(() => alert('변이 유전자 삭제에 실패했습니다.')),
    [mutationDnaId],
  );

  const handleClose = useCallback(() => {
    setCloseWindow(true);
  }, [setCloseWindow]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(dnaCreators.fetchAllDnas.request());
    if (params.id) {
      setMutationDnaId(params.id);
    } else {
      setMutationDnaId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (mutationDnaId) {
      fetchMutationDnaData();
    }
  }, [mutationDnaId, fetchMutationDnaData]);
  useEffect(() => {
    if (mutationDnaData && dnas && !didCancel.current) {
      let isZygosityEtc = false;
      if (mutationDnaData.zygosity) {
        if (ZYGOSITY.every((item) => item !== mutationDnaData.zygosity)) {
          isZygosityEtc = true;
        }
      }
      setState({
        selectedDna: mutationDnaData.geneName,
        inheritance: mutationDnaData.inheritance,
        zygosity: isZygosityEtc ? '기타' : mutationDnaData.zygosity,
        etcZygosity: isZygosityEtc ? mutationDnaData.zygosity : '',
        variantType: mutationDnaData.variantType,
        variantTypeAnnoVar: mutationDnaData.variantTypeAnnoVar,
        variantTypeVep: mutationDnaData.variantTypeVep,
        nucleotide: mutationDnaData.nucleotide,
        aminoAcid: mutationDnaData.aminoAcid,
        pathogenicityClassification:
          mutationDnaData.pathogenicityClassification,
        pathogenicityPrediction: mutationDnaData.pathogenicityPrediction,
        pathogenicitySift: mutationDnaData.pathogenicitySift,
        pathogenicityPolyPhen: mutationDnaData.pathogenicityPolyPhen,
        pathogenicityMutationtaster:
          mutationDnaData.pathogenicityMutationtaster,
        pathogenicityClinVarSignificance:
          mutationDnaData.pathogenicityClinVarSignificance,
        pathogenicityAcmgClassification:
          mutationDnaData.pathogenicityAcmgClassification,
        pathogenicityCadd: mutationDnaData.pathogenicityCadd,
        pathogenicityCaddValue: mutationDnaData.pathogenicityCaddValue,
        pathogenicityGerp: mutationDnaData.pathogenicityGerp,
        pathogenicityGerpValue: mutationDnaData.pathogenicityGerpValue,
        pathogenicityPolyPhen2: mutationDnaData.pathogenicityPolyPhen2,
        pathogenicityFathmm: mutationDnaData.pathogenicityFathmm,
        pathogenicityMutationAssessor:
          mutationDnaData.pathogenicityMutationAssessor,
        frequency1000GenomesProject:
          mutationDnaData.frequency1000GenomesProject ?? '',
        frequencyExAcFreqEas: mutationDnaData.frequencyExAcFreqEas ?? '',
        frequencyExAcFreqGlobal: mutationDnaData.frequencyExAcFreqGlobal ?? '',
        frequencyKrgdb: mutationDnaData.frequencyKrgdb ?? '',
        frequencyGenomAd: mutationDnaData.frequencyGenomAd ?? '',
        frequencyGenomAdMaf: mutationDnaData.frequencyGenomAdMaf ?? '',
        frequencyGenomAdEastAsian:
          mutationDnaData.frequencyGenomAdEastAsian ?? '',
        frequencyGenomAdExome: mutationDnaData.frequencyGenomAdExome ?? '',
      });
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [mutationDnaData, dnas]);

  useEffect(() => {
    if (closeWindow) window.close();
  }, [closeWindow]);

  const onSingleChange = (value, e, type) => {
    setState({
      [type]: value,
    });
  };

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
  }, []);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      selectedDna: values.selectedDna,
      nucleotide: values.nucleotide,
      aminoAcid: values.aminoAcid,
      etcZygosity: values.etcZygosity,
      variantType: values.variantType,
      variantTypeAnnoVar: values.variantTypeAnnoVar,
      variantTypeVep: values.variantTypeVep,
      pathogenicityClassification: values.pathogenicityClassification,
      pathogenicityPrediction: values.pathogenicityPrediction,
      pathogenicityClinVarSignificance: values.pathogenicityClinVarSignificance,
      pathogenicityAcmgClassification: values.pathogenicityAcmgClassification,
      pathogenicityMutationtaster: values.pathogenicityMutationtaster,
      pathogenicitySift: values.pathogenicitySift,
      pathogenicityPolyPhen: values.pathogenicityPolyPhen,
      pathogenicityCadd: values.pathogenicityCadd,
      pathogenicityCaddValue: values.pathogenicityCaddValue,
      pathogenicityGerp: values.pathogenicityGerp,
      pathogenicityGerpValue: values.pathogenicityGerpValue,
      pathogenicityPolyPhen2: values.pathogenicityPolyPhen2,
      pathogenicityFathmm: values.pathogenicityFathmm,
      pathogenicityMutationAssessor: values.pathogenicityMutationAssessor,
      frequency1000GenomesProject: values.frequency1000GenomesProject,
      frequencyExAcFreqEas: values.frequencyExAcFreqEas,
      frequencyExAcFreqGlobal: values.frequencyExAcFreqGlobal,
      frequencyKrgdb: values.frequencyKrgdb,
      frequencyGenomAd: values.frequencyGenomAd,
      frequencyGenomAdMaf: values.frequencyGenomAdMaf,
      frequencyGenomAdEastAsian: values.frequencyGenomAdEastAsian,
      frequencyGenomAdExome: values.frequencyGenomAdExome,
    });
    if (mutationDnaId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  useEffect(() => {
    if (isPatch) {
      handleUpdate();
      setIsPatch(false);
    }
    if (isPost) {
      handleCreate();
      setIsPost(false);
    }
  }, [isPatch, isPost]);
  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      handleRemove();
    }
  };
  if (isLoading) return <ElementLoading type="변이 등록" />;
  return (
    <>
      <WindowHeader title="변이 등록" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              selectedDna: state.selectedDna,
              nucleotide: state.nucleotide,
              aminoAcid: state.aminoAcid,
              etcZygosity: state.etcZygosity,
              variantType: state.variantType,
              variantTypeAnnoVar: state.variantTypeAnnoVar,
              variantTypeVep: state.variantTypeVep,
              inheritance: state.inheritance,
              zygosity: state.zygosity,
              pathogenicityClassification: state.pathogenicityClassification,
              pathogenicityPrediction: state.pathogenicityPrediction,
              pathogenicityClinVarSignificance:
                state.pathogenicityClinVarSignificance,
              pathogenicityAcmgClassification:
                state.pathogenicityAcmgClassification,
              pathogenicityCadd: state.pathogenicityCadd,
              pathogenicityCaddValue: state.pathogenicityCaddValue,
              pathogenicityGerp: state.pathogenicityGerp,
              pathogenicityGerpValue: state.pathogenicityGerpValue,
              pathogenicityMutationtaster: state.pathogenicityMutationtaster,
              pathogenicitySift: state.pathogenicitySift,
              pathogenicityPolyPhen: state.pathogenicityPolyPhen,
              pathogenicityPolyPhen2: state.pathogenicityPolyPhen2,
              pathogenicityFathmm: state.pathogenicityFathmm,
              pathogenicityMutationAssessor:
                state.pathogenicityMutationAssessor,
              frequency1000GenomesProject: state.frequency1000GenomesProject,
              frequencyExAcFreqEas: state.frequencyExAcFreqEas,
              frequencyExAcFreqGlobal: state.frequencyExAcFreqGlobal,
              frequencyKrgdb: state.frequencyKrgdb,
              frequencyGenomAd: state.frequencyGenomAd,
              frequencyGenomAdMaf: state.frequencyGenomAdMaf,
              frequencyGenomAdEastAsian: state.frequencyGenomAdEastAsian,
              frequencyGenomAdExome: state.frequencyGenomAdExome,
            }}
            onKeyPress={(event) => {
              if (event.key === 'Enter') {
                event.preventDefault();
              }
            }}
          >
            <Form.Item
              label="유전자"
              name="selectedDna"
              rules={[{ required: true, message: '유전자를 선택해주세요' }]}
            >
              <Select
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="유전자를 선택해주세요"
                onChange={(value) => setState({ selectedDna: value })}
              >
                {dnas?.map((option) => (
                  <Option
                    key={option?.name}
                    id={option?.name}
                    value={option?.name}
                  >
                    {option.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Row span={24}>
              <Col span={4}>
                <div className="mutationTitle">변이 내용</div>
              </Col>
              <Col span={20}>
                <Form.Item label="nucleotide" name="nucleotide">
                  <Input />
                </Form.Item>
                <Col span={20} offset={4}>
                  {mutationDnaData.nucleotideTextObject && (
                    <Variant
                      textObject={mutationDnaData.nucleotideTextObject}
                    />
                  )}
                </Col>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="aminoAcid" name="aminoAcid">
                  <Input />
                </Form.Item>
                <Col span={20} offset={4}>
                  {mutationDnaData.aminoAcidTextObject && (
                    <Variant textObject={mutationDnaData.aminoAcidTextObject} />
                  )}
                </Col>
              </Col>
            </Row>
            <hr />
            <Form.Item label="유전 형태" name="inheritance">
              <Select
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="유전 형태을 선택해주세요"
                onChange={(value, e) => onSingleChange(value, e, 'inheritance')}
              >
                {INHERITANCE?.map((option) => (
                  <Option key={option} id={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="유전자형" name="zygosity">
              <Select
                allowClear
                showSearch
                style={{ width: '100%' }}
                placeholder="유전자형을 선택해주세요"
                onChange={(value, e) => onSingleChange(value, e, 'zygosity')}
              >
                {ZYGOSITY?.map((option) => (
                  <Option key={option} id={option} value={option}>
                    {option}
                  </Option>
                ))}
              </Select>
            </Form.Item>

            {state?.zygosity === '기타' && (
              <Form.Item label="기타 유전자형" name="etcZygosity">
                <Input />
              </Form.Item>
            )}
            <hr />
            <Row span={24}>
              <Col span={4}>
                <div className="mutationTitle">변이 타입</div>
              </Col>
              <Col span={20}>
                <Form.Item label="기준 없음" name="variantType">
                  <Select
                    allowClear
                    showSearch
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder="기준 없음을 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'variantType')
                    }
                  >
                    {VARIANT_ANNOVAR?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="ANNOVAR" name="variantTypeAnnoVar">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="ANNOVAR를 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'variantTypeAnnoVar')
                    }
                  >
                    {ANNOVAR?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="VEP" name="variantTypeVep">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="VEP을 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'variantTypeVep')
                    }
                  >
                    {VEP?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <hr />
            <Row span={24}>
              <Col span={4}>
                <div className="mutationTitle">질환 관련성</div>
              </Col>
              <Col span={20}>
                <Form.Item
                  label="기준없음_변이분류"
                  name="pathogenicityClassification"
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="기준없음_변이분류를 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'pathogenicityClassification')
                    }
                  >
                    {VARIANT_CLASSIFICATION?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item
                  label="기준없음_인실리코"
                  name="pathogenicityPrediction"
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="기준없음_인실리코를 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'pathogenicityPrediction')
                    }
                  >
                    {INSILICO_PREDICTION?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item
                  label="ClinVar"
                  name="pathogenicityClinVarSignificance"
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="ClinVar를 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(
                        value,
                        e,
                        'pathogenicityClinVarSignificance',
                      )
                    }
                  >
                    {CLINVAR?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="ACMG" name="pathogenicityAcmgClassification">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="ACMG를 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(
                        value,
                        e,
                        'pathogenicityAcmgClassification',
                      )
                    }
                  >
                    {ACMG?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item
                  label="Mutationtaster"
                  name="pathogenicityMutationtaster"
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Mutationtaster를 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'pathogenicityMutationtaster')
                    }
                  >
                    {MUTATIONTASTER?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="SIFT" name="pathogenicitySift">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="SIFT를 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'pathogenicitySift')
                    }
                  >
                    {SIFT?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="PolyPhen" name="pathogenicityPolyPhen">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="PolyPhen를 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'pathogenicityPolyPhen')
                    }
                  >
                    {POLYPHEN?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Row span={24}>
                  <Col span={4}>
                    <div className="mutationTitle">CADD</div>
                  </Col>
                  <Col span={20}>
                    <Row span={24}>
                      <Col span={10} offset={2}>
                        <Form.Item label="단계" name="pathogenicityCadd">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="CADD를 선택해주세요"
                            onChange={(value, e) =>
                              onSingleChange(value, e, 'pathogenicityCadd')
                            }
                          >
                            {CADD?.map((option) => (
                              <Option key={option} id={option} value={option}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={10} offset={2}>
                        <Form.Item label="수치" name="pathogenicityCaddValue">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={20} offset={4}>
                <Row span={24}>
                  <Col span={4}>
                    <div className="mutationTitle">GERP</div>
                  </Col>
                  <Col span={20}>
                    <Row span={24}>
                      <Col span={10} offset={2}>
                        <Form.Item label="단계" name="pathogenicityGerp">
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="GERP를 선택해주세요"
                            onChange={(value, e) =>
                              onSingleChange(value, e, 'pathogenicityGerp')
                            }
                          >
                            {GERP?.map((option) => (
                              <Option key={option} id={option} value={option}>
                                {option}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col span={10} offset={2}>
                        <Form.Item label="수치" name="pathogenicityGerpValue">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="PolyPhen2" name="pathogenicityPolyPhen2">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="PolyPhen2를 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'pathogenicityPolyPhen2')
                    }
                  >
                    {POLYPHEN2?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="Fathmm" name="pathogenicityFathmm">
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Fathmm을 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'pathogenicityFathmm')
                    }
                  >
                    {FATHMM?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item
                  label="Mutation Assessor"
                  name="pathogenicityMutationAssessor"
                >
                  <Select
                    allowClear
                    showSearch
                    style={{ width: '100%' }}
                    placeholder="Mutation Assessor을 선택해주세요"
                    onChange={(value, e) =>
                      onSingleChange(value, e, 'pathogenicityMutationAssessor')
                    }
                  >
                    {MUTATIONASSESSOR?.map((option) => (
                      <Option key={option} id={option} value={option}>
                        {option}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <hr />
            <Row span={24}>
              <Col span={4}>
                <div className="mutationTitle">빈도</div>
              </Col>
              <Col span={20}>
                <Form.Item
                  label="1000 Genomes"
                  name="frequency1000GenomesProject"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="exAc Freq Eas" name="frequencyExAcFreqEas">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item
                  label="exAc Freq Global"
                  name="frequencyExAcFreqGlobal"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="KRGDB" name="frequencyKrgdb">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="GnomAD" name="frequencyGenomAd">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="GnomAD MAF" name="frequencyGenomAdMaf">
                  <Input />
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item
                  label="GnomAD East Asian"
                  name="frequencyGenomAdEastAsian"
                >
                  <Input />
                </Form.Item>
              </Col>
              <Col span={20} offset={4}>
                <Form.Item label="GnomAD Exome" name="frequencyGenomAdExome">
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {mutationDnaId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>

            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요. 내용 또는 이미지를 입력해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>

      <Modal
        title="성공하였습니다."
        visible={modalContent !== null}
        onOk={handleClose}
        onCancel={handleClose}
        footer={[
          <Button key="ok" type="primary" onClick={handleClose}>
            확인
          </Button>,
        ]}
      >
        <VariantHeader
          text="염기서열 변이"
          variant={modalContent?.nucleotide}
        />
        <Variant textObject={modalContent?.nucleotideTextObject} />
        <hr />
        <VariantHeader text="아미노산 변이" variant={modalContent?.aminoAcid} />
        <Variant textObject={modalContent?.aminoAcidTextObject} />
      </Modal>
    </>
  );
};
export default MutationDnaWindow;
