// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { customOpFactory } from '../operations/custom';
import { convertItemToConversationMessage } from './convertItemToConversationMessage';
import { AiAction, getCustomUserAgentDetails } from './getCustomUserAgentDetails';
export const createOnMessageFunction = (client, modelIntrospection, conversationId, conversationRouteName, getInternals) => (handler) => {
    const { conversations } = modelIntrospection;
    // Safe guard for standalone function. When called as part of client generation, this should never be falsy.
    if (!conversations) {
        return {};
    }
    const subscribeSchema = conversations[conversationRouteName].message.subscribe;
    const subscribeOperation = customOpFactory(client, modelIntrospection, 'subscription', subscribeSchema, false, getInternals, getCustomUserAgentDetails(AiAction.OnMessage));
    return subscribeOperation({ conversationId }).subscribe((data) => {
        handler(convertItemToConversationMessage(data));
    });
};
