import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import { Form, Spinner, Row, Col } from 'react-bootstrap';
import { parse } from 'qs';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import WindowHeader from '../../components/windowHeader';
import { hospitalExaminationCreators } from '../../../store/reducers/hospitalExamination.reducer';
import TextInput from '../../components/textInput';
import MutateButton from '../../components/mutateButton';
import ElementLoading from '../../components/elementLoading';
import MultiSelectDropdown from '../../components/multiSelectDropdown';
import TinymceInput from '../../components/tinymceInput';
import AntTinymceInput from '../../components/antTinymceInput';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';

import {
  fetchHospitalExamination,
  postHospitalExamination,
  patchHospitalExamination,
  deleteHospitalExamination,
} from '../../../services/hospitalExaminationService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import DistinctImage from '../../components/distinctImage';
import { ALERT_MESSAGES } from '../../../assets/alert';
import {
  useDistinctFileUpload,
  useDistinctFileDelete,
} from '../../util/useDistinctImage';

const HospitalExaminationWindow = (props) => {
  const dispatch = useDispatch();
  const { diseaseTypeInfo } = useSelector((state) => {
    return {
      hospitalExaminations:
        state.hospitalExaminationReducer?.hospitalExamination?.data,
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
    };
  }, shallowEqual);

  const { search } = props.location;
  const [windowId, setWindowId] = useState('');
  const [hospitalExaminationId, setHospitalExaminationId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [initialPhoto, setInitialPhoto] = useState(null);
  const [windowClose, setWindowClose] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const [distinctImageToDelete, setDistinctImageToDelete] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);

  const contentChange = useRef(false);
  const didCancel = useRef(false);
  const fileUploadReady = useRef(false);
  const removeDistinctImageByButton = useRef(false);
  const deleteDone = useRef(false);
  const isDeleteImageExist = useRef(null);
  const distinctImageType = useRef(null);

  const initialState = {
    krName: null,
    enName: null,
    description: null,
    content: null,
    photo: null,
    thumbnail: null,
    styleVersion: 1,
    selectedDiseaseType: [],
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);

  const postPatchData = {
    krName: state.krName,
    enName: state.enName,
    description: state.description,
    content: state.content,
    photo: state.photo,
    thumbnail: state.thumbnail,
    diseaseTypeIds: state.selectedDiseaseType.map(
      (diseaseType) => diseaseType.id,
    ),
    styleVersion: state.styleVersion,
  };

  const {
    data: fetchHospitalExaminationData,
    call: callHospitalExamination,
  } = useFetch(null, fetchHospitalExamination, hospitalExaminationId);

  const { mutate: putHospitalExamination, done: isPosted } = useMutate(
    postHospitalExamination,
    postPatchData,
  );

  const { mutate: changeHospitalExamination, done: isPatched } = useMutate(
    patchHospitalExamination,
    hospitalExaminationId,
    postPatchData,
  );

  const { mutate: eraseHospitalExamination, done: isDeleted } = useMutate(
    deleteHospitalExamination,
    hospitalExaminationId,
  );

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useDistinctFileUpload(distinctFileInputEvent);

  const {
    done: distinctImageDeleteDone,
    imageDelete: distinctImageDelete,
    initialize: distinctDeleteInitialize,
  } = useDistinctFileDelete(distinctImageToDelete);

  const message = isPosted
    ? ALERT_MESSAGES.CREATE
    : isPatched
    ? ALERT_MESSAGES.UPDATE
    : isDeleted
    ? ALERT_MESSAGES.DELETE
    : '';

  const isMutated = isPosted || isPatched || isDeleted;
  const windowCloseCondition = windowClose && distinctImageDeleteDone;

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(
      hospitalExaminationCreators.fetchAllHospitalExaminations.request(),
    );
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    if (params.id) {
      setHospitalExaminationId(params.id);
    } else {
      setHospitalExaminationId('');
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (hospitalExaminationId) callHospitalExamination();
  }, [hospitalExaminationId, callHospitalExamination]);

  useEffect(() => {
    if (fetchHospitalExaminationData && diseaseTypeInfo && !didCancel.current) {
      setInitialPhoto([
        fetchHospitalExaminationData.thumbnail,
        fetchHospitalExaminationData.photo,
      ]);
      setState({
        krName: fetchHospitalExaminationData.krName,
        enName: fetchHospitalExaminationData.enName,
        description: fetchHospitalExaminationData.description,
        content: fetchHospitalExaminationData.content,
        photo: fetchHospitalExaminationData.photo,
        thumbnail: fetchHospitalExaminationData.thumbnail,
        selectedDiseaseType: fetchHospitalExaminationData.diseaseTypes,
        styleVersion: fetchHospitalExaminationData.styleVersion,
      });
      didCancel.current = true;
      setIsLoading(false);
    } else if (
      hospitalExaminationId === '' &&
      diseaseTypeInfo &&
      !didCancel.current
    ) {
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [fetchHospitalExaminationData, diseaseTypeInfo, hospitalExaminationId]);

  const figureFileInput = async (e, type) => {
    distinctImageType.current = type;
    e.persist();
    if (e.target.files[0] !== undefined) {
      setDistinctFileInputEvent(e);
      fileUploadReady.current = true;
      setImageLoading(true);
    }
  };

  useEffect(() => {
    if (distinctFileInputEvent && fileUploadReady.current) {
      distinctImageUpload();
      fileUploadReady.current = false;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (
      distinctImageToDelete?.length > 0 &&
      !distinctImageDeleteDone &&
      !deleteDone.current
    ) {
      distinctImageDelete();
      setState({ [distinctImageType.current]: null });
      setWindowClose(true);
      deleteDone.current = true;
      distinctImageType.current = null;
    }
  }, [distinctImageDelete, distinctImageDeleteDone, distinctImageToDelete]);

  const closeWindowAfterAlert = useCallback(() => {
    if (isMutated || isCanceled) {
      setWindowClose(false);
      setDistinctImageToDelete(null);
      if (isMutated) alert(message);
      window.close();
    }
  }, [isMutated, isCanceled, message]);

  useEffect(() => {
    if (!isDeleteImageExist.current || windowCloseCondition) {
      closeWindowAfterAlert();
    }
  }, [windowCloseCondition, closeWindowAfterAlert]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      setState({ [distinctImageType.current]: distinctPublicUrl });
      distinctUploadInitialize();
      setImageLoading(false);
      distinctImageType.current = null;
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const readyToDeleteDistinctImage = (type, image) => {
    removeDistinctImageByButton.current = true;
    const splittedUrl = image.split('/');
    const deleteImage = `${splittedUrl[splittedUrl.length - 2]}/${
      splittedUrl[splittedUrl.length - 1]
    }`;
    distinctImageType.current = type;
    setDistinctImageToDelete(deleteImage);
  };

  useEffect(() => {
    if (distinctImageDeleteDone && removeDistinctImageByButton.current) {
      distinctDeleteInitialize();
      removeDistinctImageByButton.current = false;
      setDistinctImageToDelete(null);
      deleteDone.current = false;
    }
  }, [distinctDeleteInitialize, distinctImageDeleteDone]);

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
    contentChange.current = true;
  }, []);

  if (isLoading) return <ElementLoading type="병원 검사" />;
  return (
    <LoadingOverlay
      active={imageLoading}
      spinner={<Spinner animation="border" variant="info" />}
      text={<p>이미지를 업로드 하는 중</p>}
    >
      <WindowHeader title="병원 검사" />
      <Form className="windowForm">
        <TextInput
          text="이름"
          value={state.krName}
          onChange={changeState}
          isEssential={true}
          name="krName"
        />
        <TextInput
          text="영문명"
          value={state.enName}
          onChange={changeState}
          isEssential={true}
          name="enName"
        />
        <DistinctImage
          text="썸네일"
          imageFileInput={(e) => figureFileInput(e, 'thumbnail')}
          images={state?.thumbnail}
          deleteImage={readyToDeleteDistinctImage}
          name="thumbnail"
        />
        <TextInput
          text="짧은 설명"
          value={state.description}
          onChange={changeState}
          isEssential={false}
          name="description"
        />
        <DistinctImage
          text="본문 상단 이미지"
          imageFileInput={(e) => figureFileInput(e, 'photo')}
          images={state?.photo}
          deleteImage={readyToDeleteDistinctImage}
          name="photo"
        />
        <Form.Group className="formContent" as={Row}>
          <Form.Label column sm="3" md="2" xl="1">
            스타일 버전
          </Form.Label>
          <Col sm="9" md="10" xl="11">
            <Form.Group className="formContent checkBoxContainer" as={Row}>
              <Form.Check
                inline
                label="1"
                name="styleVersion"
                type="radio"
                id="radio-1"
                onChange={() => {
                  setState({ styleVersion: 1 });
                }}
                checked={state.styleVersion === 1}
              />
              <Form.Check
                inline
                label="2"
                name="styleVersion"
                type="radio"
                id="radio-2"
                onChange={() => {
                  setState({ styleVersion: 2 });
                }}
                checked={state.styleVersion === 2}
              />
            </Form.Group>
          </Col>
        </Form.Group>

        {state.styleVersion === 1 && (
          <TinymceInput
            content={state.content}
            setContent={changeState}
            setImageLoading={setImageLoading}
            windowId={hospitalExaminationId}
            name="content"
          />
        )}

        {state.styleVersion === 2 && (
          <Form.Group className="formContent" as={Row}>
            <Form.Label column sm="3" md="2" xl="1">
              내용 <span className="required-tag">*필수</span>
            </Form.Label>
            <Col sm="9" md="10" xl="11">
              <AntTinymceInput
                content={state.content}
                setContent={(e) => {
                  setState({ content: e });
                }}
                setImageLoading={setImageLoading}
                onBlur={(e) => {}}
                contentLinkData={{ setState }}
              />
            </Col>
          </Form.Group>
        )}

        <MultiSelectDropdown
          text="질환"
          data={[
            {
              option: diseaseTypeInfo,
              selectedOption: state.selectedDiseaseType,
              setOption: changeState,
              optionName: '대상 질환(복수 선택 가능)을 선택하세요',
              name: 'selectedDiseaseType',
            },
          ]}
        />
        <MutateButton
          elementId={hospitalExaminationId}
          create={putHospitalExamination}
          update={changeHospitalExamination}
          remove={eraseHospitalExamination}
          imageType="figure"
          setIsCanceled={setIsCanceled}
        />
      </Form>
    </LoadingOverlay>
  );
};

export default HospitalExaminationWindow;
