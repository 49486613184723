import { EyeTwoTone } from '@ant-design/icons';
import { Col, DatePicker, Divider, Form, Input, Row, Spin } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect } from 'react';

export const MediaMonitorPreview = ({
  preview,
  diseaseTypeData,
  previewLoading,
  previewLoadingText,
}) => {
  const [previewForm] = Form.useForm();

  useEffect(() => {
    if (preview && previewForm && diseaseTypeData)
      previewForm.setFieldsValue({
        lang: preview ? (preview.lang === 'ko' ? '국내' : '해외') : '',
        title: preview?.title,
        originalTitle: preview?.originalTitle,
        publishedDate: dayjs(preview?.publishedDate),
        diseaseName:
          diseaseTypeData?.find((data) => data.id === preview?.diseaseTypeId)
            ?.krName ?? preview?.diseaseTypeId,
        siteName: preview?.siteName,
        author: preview?.author,
        description: preview?.description,
        url: preview?.url,
      });
  }, [diseaseTypeData, preview, previewForm]);

  return (
    <Row span={24}>
      <Col span={24}>
        <h5>
          <EyeTwoTone className="userTitleIcon" />
          후공정 미리보기
        </h5>
        <Divider plain />
        <Spin spinning={previewLoading} tip={previewLoadingText}>
          <Form name="preview" form={previewForm}>
            <Row span={24}>
              <Col span={24}>
                <Form.Item
                  label="국내/해외"
                  name="lang"
                  className="mediaMonitorListHeader"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="기사 제목"
                  name="title"
                  className="mediaMonitorListHeader"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="원문 제목"
                  name="originalTitle"
                  className="mediaMonitorListHeader"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="기사 발행일"
                  name="publishedDate"
                  className="mediaMonitorListHeader"
                >
                  <DatePicker disabled />
                </Form.Item>
                <Form.Item
                  label="대상 질환"
                  name="diseaseName"
                  className="mediaMonitorListHeader"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="발행 미디어"
                  name="siteName"
                  className="mediaMonitorListHeader"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="작성자"
                  name="author"
                  className="mediaMonitorListHeader"
                >
                  <Input disabled />
                </Form.Item>
                <Form.Item
                  label="설명 / GPT 요약문"
                  name="description"
                  className="mediaMonitorListHeader"
                >
                  <Input.TextArea autoSize={{ minRows: 5 }} disabled />
                </Form.Item>
                <Form.Item
                  label="원본 URL"
                  name="url"
                  className="mediaMonitorListHeader"
                >
                  <Input disabled />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Spin>
      </Col>
    </Row>
  );
};
