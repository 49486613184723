import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Checkbox, Radio, Table } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { DndContext } from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

const RowContext = React.createContext({});
const DragHandle = (props) => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<MenuOutlined />}
      style={props.disabled ? {} : { cursor: 'move' }}
      ref={setActivatorNodeRef}
      {...props}
      {...listeners}
    />
  );
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners],
  );

  // NOTE(reo): role="button"이 추가되면 글로벌 css때문에 cursor가 변경됨
  attributes.role = undefined;

  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

/**
 * DnD 기능이 추가된 Table
 * dataSource의 id를 기준으로 drag & drop이 작동함
 * @param dataSource Table의 dataSource
 * @param setDataSource dataSource 변경 함수; sort column 사용을 위해 필수
 * @param columns 테이블 column 구조; key에 sort가 있으면 DnD column으로, key에 rowSelection이 있으면 rowSelection column으로 변경됨; `type: 'check' | 'radio'`
 * @param setSelectedRowKey 선택된 row의 key를 저장하는 dispatcher; rowSelection column이 있을 때만 사용
 * @param onDragEnd drag가 끝났을 때 실행되는 함수; 드래그한 row와 드롭 위치의 row id를 순서대로 반환
 */
const DndTable = ({
  dataSource,
  setDataSource,
  columns,
  selectedRowKey,
  setSelectedRowKey,
  onDragEnd,
  disableDnd,
  ...tableProps
}) => {
  const [selectedRowKeyLocal, setSelectedRowKeyLocal] = useState([]);

  const handleDragEnd = ({ active, over }) => {
    onDragEnd?.(active.id, over?.id);
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.id === active?.id,
        );
        const overIndex = prevState.findIndex(
          (record) => record.id === over?.id,
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const Columns = columns.map((column) => {
    if (column.key === 'sort') {
      return {
        ...column,
        render: () => <DragHandle disabled={disableDnd} />,
      };
    }
    if (column.key === 'rowSelection') {
      return {
        width: 50,
        ...column,
        ...(column.type !== 'radio'
          ? {
              title: (
                <Checkbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedRowKeyLocal(dataSource.map((data) => data.id));
                      setSelectedRowKey?.(dataSource.map((data) => data.id));
                    } else {
                      setSelectedRowKeyLocal([]);
                      setSelectedRowKey?.([]);
                    }
                  }}
                  checked={selectedRowKeyLocal.length === dataSource.length}
                  indeterminate={
                    selectedRowKeyLocal.length > 0 &&
                    selectedRowKeyLocal.length < dataSource.length
                  }
                />
              ),
            }
          : {}),
        render: (_, record) =>
          column.type === 'radio' ? (
            <Radio
              style={{ margin: 0 }}
              checked={record.id === selectedRowKeyLocal}
              onChange={() => {
                setSelectedRowKeyLocal(record.id);
                setSelectedRowKey?.(record.id);
              }}
            />
          ) : (
            <Checkbox
              style={{ margin: 0 }}
              checked={selectedRowKeyLocal?.includes(record.id)}
              onChange={(e) => {
                if (!e.target.checked) {
                  setSelectedRowKeyLocal((prev) =>
                    prev.filter((key) => key !== record.id),
                  );
                  setSelectedRowKey?.((prev) =>
                    prev.filter((key) => key !== record.id),
                  );
                } else {
                  setSelectedRowKeyLocal((prev) => [...prev, record.id]);
                  setSelectedRowKey?.((prev) => [...prev, record.id]);
                }
              }}
            />
          ),
      };
    }
    return column;
  });

  useEffect(() => {
    if (Array.isArray(selectedRowKey)) {
      if (selectedRowKey.length !== selectedRowKeyLocal.length) {
        setSelectedRowKeyLocal(selectedRowKey);
      }
    } else {
      setSelectedRowKeyLocal(selectedRowKey);
    }
  }, [selectedRowKey]);

  return (
    <DndContext
      modifiers={[restrictToVerticalAxis, restrictToParentElement]}
      onDragEnd={handleDragEnd}
    >
      <SortableContext
        items={dataSource.map((i) => i.id)}
        strategy={verticalListSortingStrategy}
        disabled={disableDnd}
      >
        <Table
          size="small"
          dataSource={dataSource}
          columns={Columns}
          pagination={false}
          rowClassName={(record) =>
            record.id === selectedRowKeyLocal ? 'ant-table-row-selected' : ''
          }
          components={{
            body: {
              row: Row,
            },
          }}
          rowKey={(record) => record.id}
          bordered
          {...tableProps}
        />
      </SortableContext>
    </DndContext>
  );
};

export default DndTable;
