import { parse } from 'qs';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';
import { useFetch } from '../../../hooks/useRequest';
import { fetchMediaMonitorConfigLetter } from '../../../services/mediaMonitorService';
import WindowHeader from '../../components/windowHeader';

const MediaMonitorLetterWindow = () => {
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [configId, setConfigId] = useState(null);

  const { data: html, call: getLetter } = useFetch(
    null,
    fetchMediaMonitorConfigLetter,
    configId,
  );

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setConfigId(params.id);
    }
  }, [search, windowId]);

  useEffect(() => {
    if (configId) {
      getLetter();
    }
  }, [configId, getLetter]);

  useEffect(() => {
    if (html) {
      const iframe = document.getElementById('letterPreview');
      const doc = iframe.contentWindow.document;
      doc.open();
      doc.write(html);
      doc.close();
      window.document.body.style.overflow = 'hidden';
    }
  }, [html]);

  return (
    <>
      <WindowHeader title="뉴스레터 미리보기" />
      <iframe
        id="letterPreview"
        title="Newsletter Preview"
        style={{ width: '100%', height: '100vh' }}
      />
    </>
  );
};

export default MediaMonitorLetterWindow;
