import { FileTwoTone } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Space } from 'antd';
import React, { useCallback, useState } from 'react';
import AntList from '../../components/antList';

export const MediaMonitorProcess = ({
  processForm,
  substitutions,
  setSubstitutions,
}) => {
  const [pageUpdate, setPageUpdate] = useState(1);
  const [addSubstitution, setAddSubstitution] = useState(false);
  const [addTargetValue, setAddTargetValue] = useState(null);
  const [addSubstitutionValue, setAddSubstitutionValue] = useState(null);

  const handleRemoveSubstitutions = useCallback(
    (record) => {
      if (!processForm || !substitutions) return;
      const next = substitutions.filter(
        (substitution) =>
          substitution.target !== record.target ||
          substitution.substitution !== record.substitution,
      );
      processForm.setFieldsValue({
        substitutions: next,
      });
      setSubstitutions(next);
    },
    [processForm, setSubstitutions, substitutions],
  );

  const handleAddSubstitutions = useCallback(() => {
    if (!processForm || !substitutions) return;
    if (!addTargetValue || !addSubstitutionValue) {
      alert('원문과 대치어를 입력해주세요.');
      return;
    }
    const next = [
      ...substitutions,
      {
        target: addTargetValue,
        substitution: addSubstitutionValue,
      },
    ];
    processForm.setFieldsValue({
      substitutions: next,
    });
    setSubstitutions(next);
    setAddSubstitution(false);
    setAddTargetValue(null);
    setAddSubstitutionValue(null);
  }, [
    addSubstitutionValue,
    addTargetValue,
    processForm,
    setSubstitutions,
    substitutions,
  ]);

  const columns = [
    {
      title: '원문',
      dataIndex: 'target',
      key: 'target',
      sorter: (a, b) => a.target.localeCompare(b.target),
    },
    {
      title: '대치어',
      dataIndex: 'substitution',
      key: 'substitution',
      sorter: (a, b) => a.substitution.localeCompare(b.substitution),
    },
    {
      title: '',
      width: '74px',
      render: (_, record) => (
        <Button onClick={() => handleRemoveSubstitutions(record)}>삭제</Button>
      ),
    },
  ];

  return (
    <Form name="process" form={processForm}>
      <Row span={24}>
        <Col span={24}>
          <h5>
            <FileTwoTone className="userTitleIcon" />
            3. GPT 요약 및 번역
          </h5>
          <h6 style={{ marginTop: 16 }}>요약문 및 번역 프롬프트</h6>
          <Divider plain />
          <Form.Item
            label="요약문 프롬프트"
            name="descriptionPrompt"
            rules={[
              { required: true, message: 'GPT 프롬프트를 입력해주세요.' },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 5, maxRows: 10 }} />
          </Form.Item>
          <Divider plain />
          <h6 style={{ marginTop: 16 }}>대치어 적용</h6>
          <Divider plain />
          {!addSubstitution && (
            <Button
              type="primary"
              style={{ marginBottom: 16 }}
              onClick={() => setAddSubstitution(!addSubstitution)}
            >
              추가
            </Button>
          )}
          {addSubstitution && (
            <Space style={{ marginBottom: 16 }}>
              <Button type="primary" onClick={() => handleAddSubstitutions()}>
                추가
              </Button>
              <Input
                placeholder="원문을 입력해주세요."
                onChange={(e) => setAddTargetValue(e.target.value)}
              />
              <Input
                placeholder="대치어를 입력해주세요."
                onChange={(e) => setAddSubstitutionValue(e.target.value)}
              />
            </Space>
          )}
          <AntList
            columns={columns}
            dataSource={substitutions}
            pageUpdate={pageUpdate}
            setPageUpdate={setPageUpdate}
            defaultPageSize={5}
          />
        </Col>
      </Row>
    </Form>
  );
};
