import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Form,
  Input,
  Select,
  DatePicker,
  Button,
  Radio,
  Alert,
  Row,
  Col,
} from 'antd';

import '../../../public/css/window.css';
import 'bootstrap';
import { parse } from 'qs';
import dayjs from 'dayjs';
import { useLocation } from 'react-router';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';
import {
  fetchMutationDnaReport as fetchMutationDnaReportsService,
  postMutationDnaReport as postMutationDnaReportService,
  patchMutationDnaReport as patchMutationDnaReportService,
  deleteMutationDnaReport as deleteMutationDnaReportService,
  checkMutationDnaReportConnection as checkMutationDnaReportConnectionService,
} from '../../../services/mutationDnaReportService';
import { fetchUser as fetchUserService } from '../../../services/userService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import { mutationDnaCreators } from '../../../store/reducers/mutationDna.reducer';
import { userCreators } from '../../../store/reducers/user.reducer';
import { ALERT_MESSAGES } from '../../../assets/alert';

const clientId = process.env.REACT_APP_CLIENT_ID || '';

const { TextArea } = Input;

const MutationDnaReportWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { mutationDnas, users, notUsers } = useSelector((state) => {
    return {
      mutationDnas: state.mutationDnaReducer.mutationDna.data?.map(
        (mutationDna) => ({
          ...mutationDna,
          name: `${dayjs(mutationDna.createdAt).format('YYYY-MM-DD HH:mm')} ${
            mutationDna.geneName
          } (${
            mutationDna.nucleotide
              ? `${mutationDna.nucleotide}${
                  mutationDna.aminoAcid ? `, ${mutationDna.aminoAcid}` : ''
                }`
              : mutationDna.aminoAcid || 'NA'
          })`,
        }),
      ),
      users: state.userReducer.adminUser.data,
      notUsers: state.userReducer.notAdminUser.data,
    };
  }, shallowEqual);

  const initialState = {
    userId: null,
    name: null,
    birthday: moment(),
    phone: null,
    email: null,
    gender: null,
    etcGender: null,
    institution: null,
    reportedAt: '',
    content: null,
    mutationResult: false,
    mutationDnas: [],
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [mutationDnaReportId, setMutationDnaReportId] = useState(null);
  const didCancel = useRef(false);
  const [isHumanProtocol, setIsHumanProtocol] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const {
    data: mutationDnaReportData,
    call: fetchMutationDnaReport,
  } = useFetch(null, fetchMutationDnaReportsService, mutationDnaReportId);

  const { done: fetchUserDone, data: userData, call: fetchUser } = useFetch(
    null,
    fetchUserService,
    state?.userId,
  );

  const postPatchData = {
    userId: state.userId,
    institution: state.institution,
    name: state.name,
    birthday: state.birthday,
    phone: state.phone,
    email: state.email,
    gender:
      state.gender === null
        ? null
        : state.gender === 'etc'
        ? state.etcGender
        : state.gender,
    reportedAt: state.reportedAt,
    content: state.content,
    mutationDnaIds: state.mutationResult ? state.mutationDnas : [],
  };

  const {
    done: checkMutationDnaReportConnectionDone,
    data: connectionResult,
    call: checkMutationDnaReportConnection,
  } = useFetch(null, checkMutationDnaReportConnectionService, {
    name: state.name,
    birthday: state.birthday,
    phone: state.phone,
  });

  const { mutate: postMutationDnaReport, done: isPosted } = useMutate(
    postMutationDnaReportService,
    postPatchData,
  );
  const { mutate: patchMutationDnaReport, done: isPatched } = useMutate(
    patchMutationDnaReportService,
    mutationDnaReportId,
    postPatchData,
  );
  const { mutate: deleteMutationDnaReport, done: isDeleted } = useMutate(
    deleteMutationDnaReportService,
    mutationDnaReportId,
  );

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(mutationDnaCreators.fetchAllMutationDna.request());
    dispatch(userCreators.fetchAdminUser.request({ clientId, isAdmin: true }));
    dispatch(
      userCreators.fetchNotAdminUser.request({ clientId, isAdmin: false }),
    );

    if (params.id) {
      setMutationDnaReportId(params.id);
    } else {
      setMutationDnaReportId('');
      setIsLoading(false);
    }
    if (params.subId === 'humanProtocolRegister') {
      setIsHumanProtocol(true);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (mutationDnaReportId) {
      fetchMutationDnaReport();
    }
  }, [mutationDnaReportId, fetchMutationDnaReport]);

  useEffect(() => {
    if (
      mutationDnaReportData &&
      mutationDnas &&
      users &&
      notUsers &&
      !didCancel.current
    ) {
      let isGender = null;
      if (
        mutationDnaReportData.gender === 'male' ||
        mutationDnaReportData.gender === 'female' ||
        mutationDnaReportData.gender === null
      ) {
        isGender = mutationDnaReportData.gender;
      } else {
        isGender = 'etc';
      }
      const isHumanProtocolRegister =
        mutationDnaReportData.name &&
        mutationDnaReportData.birthday &&
        mutationDnaReportData.phone;
      setIsHumanProtocol(isHumanProtocolRegister);
      setState({
        userId: mutationDnaReportData.userId,
        institution: mutationDnaReportData.institution,
        name: mutationDnaReportData.name,
        birthday: moment(mutationDnaReportData.birthday),
        email: mutationDnaReportData.email,
        phone: mutationDnaReportData.phone,
        gender: isGender,
        etcGender: isGender === 'etc' ? mutationDnaReportData.gender : '',
        reportedAt: mutationDnaReportData.reportedAt,
        content: mutationDnaReportData.content,
        mutationDnas: mutationDnaReportData.mutationDnaIds,
        mutationResult: mutationDnaReportData.mutationDnaIds.length > 0,
      });
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [mutationDnaReportData, mutationDnas, users, notUsers]);

  useEffect(() => {
    if (!fetchUserDone && state?.userId) {
      fetchUser();
    }
  }, [fetchUser, fetchUserDone, state?.userId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = () => {
    setShowAlert(false);
    if (mutationDnaReportId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      deleteMutationDnaReport();
    }
  };

  useEffect(() => {
    if (isPatch) {
      patchMutationDnaReport();
      setIsPatch(false);
    }
    if (isPost) {
      postMutationDnaReport();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  if (isLoading) return <ElementLoading type="유전자 검사 결과 등록" />;
  return (
    <>
      <WindowHeader
        title={
          isHumanProtocol
            ? '휴먼 프로토콜 유전자 검사 결과 등록'
            : '앱 사진 유전자 검사 결과 등록'
        }
      />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              name: state.name,
              phone: state.phone,
              birthday: state.birthday,
              gender: state.gender,
              etcGender: state.etcGender,
              institution: state.institution,
              reportedAt: state.reportedAt,
              content: state.content,
              mutationResult: state.mutationResult,
              mutationDnas: state.mutationDnas,
            }}
          >
            {isHumanProtocol && (
              <>
                {mutationDnaReportId !== '' && (
                  <Form.Item label="이메일">
                    {userData?.email ? (
                      <div className="greenText">{userData?.email}</div>
                    ) : (
                      <div className="redText">일치하는 계정이 없습니다.</div>
                    )}
                  </Form.Item>
                )}
                <Form.Item
                  label="이름"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: '이름을 확인해주세요.',
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      setState({ name: e.target.value });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="생년월일"
                  name="birthday"
                  rules={[
                    {
                      required: true,
                      message: '생년월일을 확인해주세요.',
                    },
                  ]}
                >
                  <DatePicker
                    onChange={(date, dateString) => {
                      setState({ birthday: dateString });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  label="연락처"
                  name="phone"
                  rules={[
                    {
                      required: true,
                      message: '연락처를 확인해주세요.',
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => {
                      setState({ phone: e.target.value });
                    }}
                  />
                </Form.Item>
                <Form.Item
                  wrapperCol={{
                    offset: 4,
                    span: 20,
                  }}
                >
                  <Button
                    type="primary"
                    onClick={checkMutationDnaReportConnection}
                  >
                    연결될 이메일 확인해 보기
                  </Button>
                </Form.Item>

                {checkMutationDnaReportConnectionDone && (
                  <Form.Item label="연결될 이메일">
                    {connectionResult?.email ? (
                      <div className="greenText">{connectionResult?.email}</div>
                    ) : (
                      <div className="redText">일치하는 계정이 없습니다.</div>
                    )}
                  </Form.Item>
                )}
              </>
            )}
            {!isHumanProtocol && users && notUsers && (
              <>
                <Form.Item
                  label="유저"
                  rules={[{ required: true, message: '유저를 선택해주세요' }]}
                >
                  <Select
                    showSearch
                    defaultValue={state?.userId}
                    style={{ width: '100%' }}
                    placeholder="유저를 선택해주세요"
                    filterOption={(input, option) =>
                      option.props.value
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0 ||
                      option.props.key
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(values, object) => {
                      if (values)
                        setState({ userId: values, email: object?.id });
                    }}
                  >
                    {users?.map((user) => (
                      <Select.Option
                        key={user?.id + user?.email}
                        id={user?.email}
                        value={user?.id}
                      >
                        {user.email}
                      </Select.Option>
                    ))}
                    {notUsers?.map((user) => (
                      <Select.Option
                        key={user?.id + user?.email}
                        id={user?.email}
                        value={user?.id}
                      >
                        {user.email}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                {mutationDnaReportId !== '' && (
                  <>
                    <Form.Item label="이름">
                      {userData?.name ? (
                        <div className="greenText">{userData?.name}</div>
                      ) : (
                        <div className="redText">일치하는 이름이 없습니다.</div>
                      )}
                    </Form.Item>
                    <Form.Item label="생년월일">
                      {userData?.birthDate ? (
                        <div className="greenText">{userData?.birthDate}</div>
                      ) : (
                        <div className="redText">
                          일치하는 생년월일이 없습니다.
                        </div>
                      )}
                    </Form.Item>
                    <Form.Item label="연락처">
                      {userData?.phone ? (
                        <div className="greenText">{userData?.phone}</div>
                      ) : (
                        <div className="redText">
                          일치하는 연락처가 없습니다.
                        </div>
                      )}
                    </Form.Item>
                  </>
                )}
              </>
            )}
            <Form.Item label="성별">
              <Radio.Group
                onChange={(e) => {
                  setState({ gender: e.target.value });
                }}
                value={state?.gender}
              >
                <Radio value="male">남성</Radio>
                <Radio value="female">여성</Radio>
                <Radio value="etc">기타</Radio>
              </Radio.Group>
            </Form.Item>
            {state?.gender === 'etc' && (
              <Form.Item
                label="기타 성별"
                name="etcGender"
                rules={[
                  {
                    required: true,
                    message: '기타 성별을 확인해주세요.',
                  },
                ]}
              >
                <Input
                  onChange={(e) => {
                    setState({ etcGender: e.target.value });
                  }}
                />
              </Form.Item>
            )}
            <Form.Item label="검사 병원" name="institution">
              <Input
                onChange={(e) => {
                  setState({ institution: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item label="보고일" name="reportedAt">
              <Input
                placeholder="예시) 20220101"
                onChange={(e) => {
                  setState({ reportedAt: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item label="보고서">
              <TextArea
                rows={3}
                value={state?.content}
                onChange={(e) => setState({ content: e.target.value })}
              />
            </Form.Item>
            <Form.Item label="변이 결과">
              <Radio.Group
                onChange={(e) => {
                  setState({ mutationResult: e.target.value });
                }}
                value={state?.mutationResult}
              >
                <Radio value={false}>없음</Radio>
                <Radio value={true}>있음</Radio>
              </Radio.Group>
            </Form.Item>
            {state.mutationResult && (
              <Form.Item name="select" label="변이 유전자">
                <Select
                  mode="multiple"
                  showSearch
                  defaultValue={state?.mutationDnas}
                  style={{ width: '100%' }}
                  placeholder="변이 유전자를 선택해주세요"
                  filterOption={(input, option) =>
                    option.props.value
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0 ||
                    option.props.key
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(values) => {
                    setState({ mutationDnas: values });
                  }}
                >
                  {mutationDnas?.map((dna) => (
                    <Select.Option
                      key={dna?.id + dna?.name}
                      id={dna?.id}
                      value={dna?.id}
                    >
                      {dna.name}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            )}
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {mutationDnaReportId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default MutationDnaReportWindow;
