import { Checkbox, Col, Row, Switch } from 'antd'; // This line is not used, consider removing it if not needed
import dayjs from 'dayjs';
import { parse } from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFetch } from '../../../hooks/useRequest';
import {
  fetchMediaMonitorConfigById,
  getMediaMonitorPreviews,
} from '../../../services/mediaMonitorService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import AntList from '../../../views/components/antList';
import WindowHeader from '../../components/windowHeader';

const MediaMonitorPreviewsWindow = () => {
  const [pageUpdate, setPageUpdate] = useState(1);
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [configId, setConfigId] = useState(null);

  const dispatch = useDispatch();
  const { diseaseTypeData } = useSelector((state) => {
    const diseaseTypes = state.diseaseTypeReducer.diseaseTypes?.data;
    return {
      diseaseTypeData: diseaseTypes,
    };
  });
  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const { data: config, call: getConfig } = useFetch(
    null,
    fetchMediaMonitorConfigById,
    configId,
  );

  const { data: previews, call: getPreviews } = useFetch(
    null,
    getMediaMonitorPreviews,
    configId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setConfigId(params.id);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search]);

  useEffect(() => {
    if (configId) {
      getConfig();
      getPreviews();
    }
  }, [configId, getConfig, getPreviews]);

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setConfigId(params.id);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (configId) {
      getConfig();
      getPreviews();
    }
  }, [configId, getConfig]);

  const columns = [
    {
      title: '선택',
      render: (_, record) => <Checkbox id={record.id} />,
    },
    {
      title: '국내/해외',
      dataIndex: 'lang',
      key: 'lang',
      filters: [
        { text: '국내', value: 'ko' },
        { text: '해외', value: 'en' },
      ],
      onFilter: (value, record) => record.lang === value,
      render: (text) => (text === 'ko' ? '국내' : '해외'),
    },
    {
      title: '기사 발행일',
      dataIndex: 'publishedDate',
      key: 'publishedDate',
      sorter: (a, b) =>
        a.publishedDate?.localeCompare(b.publishedDate),
      render: (text) => (text ? dayjs(text).format('YY.MM.DD') : '-'),
    },
    {
      title: '기사 제목',
      dataIndex: 'title',
      key: 'title',
      sorter: (a, b) => a.title.localeCompare(b.title),
      render: (text, record) => (
        <a href={record.url} target="_blank" rel="noreferrer">
          {text}
        </a>
      ),
    },
    {
      title: '대상 질환',
      dataIndex: 'diseaseTypeId',
      key: 'diseaseTypeId',
      filters: config?.diseaseTypeIds?.map((id) => ({
        text: diseaseTypeData?.find((data) => data.id === id)?.krName ?? id,
        value: id,
      })),
      onFilter: (value, record) => record.diseaseTypeId === value,
      render: (value) =>
        diseaseTypeData?.find((data) => data.id === value)?.krName ?? value,
    },
    {
      title: '적합성',
      dataIndex: 'eligible',
      key: 'eligible',
      filters: [
        { text: '적합', value: true },
        { text: '부적합', value: false },
      ],
      onFilter: (value, record) => record.eligible === value,
      render: (value) => <Switch disabled checked={value} />,
    },
  ];

  return (
    <>
      <WindowHeader title="적재 미리보기" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <AntList
            columns={columns}
            dataSource={previews}
            pagination={{
              current: pageUpdate,
              onChange: (page) => setPageUpdate(page),
              pageSize: 20,
              showSizeChanger: true,
            }}
          />
        </Col>
      </Row>
    </>
  );
};

export default MediaMonitorPreviewsWindow;
