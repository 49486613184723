/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useCallback, useEffect, useState } from 'react';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { Spinner } from 'react-bootstrap';
import LoadingOverlay from 'react-loading-overlay';
import { Form, Input, Button, Row, Col, Switch } from 'antd';
import { DeleteFilled } from '@ant-design/icons';
import 'antd/dist/antd.css';
import WindowHeader from '../../components/windowHeader';
import {
  patchClinicalTrial,
  deleteDiseaseTypeClinicalTrial,
  fetchClinicalTrial,
} from '../../../services/clinicalTrialService';
import { useLazyRequest } from '../../../hooks/useRequest';
import ElementLoading from '../../components/elementLoading';

const onReset = () => {
  if (window.confirm('취소하시겠습니까?')) {
    window.close();
  }
};

const setupBeforeUnloadListener = (ctkId) =>
  window.addEventListener('beforeunload', (event) => {
    event.preventDefault();
    return window?.opener?.postMessage(`close ${ctkId}`, '/');
  });

const rowStyle = { marginBottom: '0', height: '40px' };

const ClinicalTrialWindow = () => {
  const { search } = useLocation();

  const [
    { clinicalTrials, notDeletedCount, initialWarning },
    setState,
  ] = useState({
    clinicalTrials: [],
    notDeletedCount: 0,
    initialWarning:
      '임상의 관련 질환을 삭제하면 다시 되돌릴 수 없습니다. 그래도 삭제하시겠습니까?',
  });

  const [
    { title = '', ctkId, nctId, clientPhase, clientOverallStatus } = {},
  ] = clinicalTrials;

  const handleDelete = useCallback(
    (params) => {
      if (initialWarning) {
        if (!window.confirm(initialWarning)) return;
      }
      return deleteDiseaseTypeClinicalTrial(params);
    },
    [initialWarning],
  );

  const { loading, data: fetchResponse, call: getData } = useLazyRequest(
    fetchClinicalTrial,
  );

  const {
    call: updateClinicalTrial,
    data: updateResponse,
    loading: updateLoading,
  } = useLazyRequest(patchClinicalTrial, null, false);
  const {
    call: deleteClinicalTrial,
    data: deleteResponse,
    loading: deleteLoading,
  } = useLazyRequest(handleDelete, null, false);

  useEffect(() => {
    const {
      id,
      subId: diseaseTypeId, // TODO: remove when not used for sure
    } = parse(search, { ignoreQueryPrefix: true });

    getData(id);
    setupBeforeUnloadListener(id);
  }, [search, getData]);

  useEffect(() => {
    if (fetchResponse?.length) {
      setState((prevState) => ({
        ...prevState,
        clinicalTrials: fetchResponse,
        notDeletedCount: fetchResponse.filter(
          ({ deletedAt }) => deletedAt === null,
        ).length,
      }));
    }
  }, [fetchResponse]);

  useEffect(() => {
    if (updateResponse) {
      window.alert('수정되었습니다!');
      return window.close();
    }
    if (deleteResponse) {
      if (notDeletedCount === 0) {
        window.alert('삭제되었습니다!');
        return window.close();
      }
      setState((prevState) => {
        const prevClinicalTrials = prevState.clinicalTrials;
        let currNotDeletedCount = 0;
        return {
          clinicalTrials: prevClinicalTrials.map((clinicalTrial) => {
            const deleted = deleteResponse.find(
              ({ id }) => id === clinicalTrial.id,
            );
            if (deleted)
              return { ...clinicalTrial, deletedAt: deleted.deletedAt };
            if (!clinicalTrial.deletedAt) currNotDeletedCount++;
            return clinicalTrial;
          }),
          notDeletedCount: currNotDeletedCount,
          initialWarning: null,
        };
      });
    }
  }, [updateResponse, deleteResponse, notDeletedCount]);

  const onFinish = useCallback(
    (form) => {
      if (form?.title === title) return window.close();
      if (window.confirm('수정하시겠습니까?')) updateClinicalTrial(ctkId, form);
    },
    [updateClinicalTrial, title, ctkId],
  );

  return loading ? (
    <ElementLoading type="임상시험" />
  ) : (
    <>
      <WindowHeader title="임상시험" />
      <LoadingOverlay
        className="overlayLoading"
        active={updateLoading || deleteLoading || deleteLoading}
        spinner={<Spinner animation="border" variant="info" />}
      >
        <Row span={24} style={{ padding: 24 }}>
          <Col span={24}>
            <Form
              name="basic"
              labelCol={{ span: 4 }}
              wrapperCol={{ span: 20 }}
              onFinish={onFinish}
              initialValues={{
                remember: true,
                title,
              }}
            >
              <Form.Item
                label="제목"
                name="title"
                style={{ ...rowStyle, marginRight: '80px' }}
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: '제목을 확인해주세요.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Row className="ant-form-item" style={rowStyle}>
                <Col span={4} className="ant-form-item-label">
                  <label className="ant-form-item">ID</label>
                </Col>
                <Col span={20} style={{ paddingTop: '5px' }}>
                  <a
                    href={`https://clinicaltrials.gov/ct2/show/${nctId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {nctId}
                  </a>
                </Col>
              </Row>
              <Row className="ant-form-item" style={rowStyle}>
                <Col span={4} className="ant-form-item-label">
                  <label className="ant-form-item">단계</label>
                </Col>
                <Col span={20} style={{ paddingTop: '5px', height: '32px' }}>
                  {clientPhase}
                </Col>
              </Row>
              <Row className="ant-form-item" style={rowStyle}>
                <Col span={4} className="ant-form-item-label">
                  <label className="ant-form-item">상태</label>
                </Col>
                <Col span={20} style={{ paddingTop: '5px', height: '32px' }}>
                  {clientOverallStatus}
                </Col>
              </Row>
              <Row className="ant-form-item">
                <Col span={4} className="ant-form-item-label">
                  <label className="ant-form-item">관련 질환</label>
                </Col>
                <Col span={20}>
                  {clinicalTrials.map(
                    ({ id, diseaseType: { krName }, deletedAt }) => (
                      <div
                        key={id}
                        style={{
                          display: 'flex',
                          justifyContent: 'space-between',
                          margin: '4px 80px 12px 0',
                        }}
                      >
                        <p
                          style={{
                            marginBottom: 0,
                            ...(deletedAt && {
                              color: 'grey',
                            }),
                          }}
                        >
                          {krName}
                        </p>
                        {deletedAt === null && (
                          <DeleteFilled
                            style={{ fontSize: 20 }}
                            onClick={() =>
                              deleteClinicalTrial(
                                notDeletedCount === 1 ? { ctkId } : { id },
                              )
                            }
                          />
                        )}
                      </div>
                    ),
                  )}
                </Col>
              </Row>
              <Form.Item
                wrapperCol={{
                  offset: 4,
                  span: 20,
                }}
              >
                <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                  저장
                </Button>
                <Button
                  htmlType="button"
                  style={{ width: 100 }}
                  onClick={onReset}
                >
                  취소
                </Button>
                <Button
                  danger
                  htmlType="button"
                  onClick={() => deleteClinicalTrial({ ctkId })}
                  style={{ width: 100, marginLeft: 8 }}
                >
                  삭제
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </LoadingOverlay>
    </>
  );
};

export default ClinicalTrialWindow;
