import axios from 'axios';
import { SUCCESS, HTTP_SUCCESS } from './utils';

/**
 * 모든 설문 항목을 가져옵니다.
 * @deprecated
 */
export async function fetchAllHealthProfileV4() {
  const res = await axios.get('/v4/admin/healthProfiles');
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 설문 목록을 가져옵니다.
 * @param {string} category NORMAL | RESEARCH | PATIENT_GROUP
 */
export async function fetchHealthProfiles(category) {
  const res = await axios.get(
    `/v1/admin/healthProfiles${category ? `?category=${category}` : ''}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목의 모든 설문 질문을 가져옵니다.
 * @param {uuid} healthProfileId
 */
export async function fetchAllHealthProfileQuestion(healthProfileId) {
  const res = await axios.get(
    `/v1/admin/healthProfiles/${healthProfileId}/healthProfileQuestions`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목을 가져옵니다.
 * @param {string} healthProfileId
 */
export async function fetchHealthProfile(healthProfileId) {
  const res = await axios.get(`/v1/admin/healthProfiles/${healthProfileId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목을 생성합니다.
 * @param {Object} posthData
 */
export async function postHealthProfile(postData) {
  const res = await axios.post(`/v1/admin/healthProfiles`, postData);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목을 수정합니다.
 * @param {uuid} healthProfileId
 * @param {Object} patchData
 */
export async function patchHealthProfile(healthProfileId, patchData) {
  const res = await axios.patch(
    `/v1/admin/healthProfiles/${healthProfileId}`,
    patchData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 설문의 앱 노출을 수정합니다.
 * @param {Object} patchData
 */
export async function patchHealthProfileBulk(patchData) {
  const res = await axios.patch(`/v1/admin/healthProfiles`, patchData);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 항목을 삭제합니다.
 * @param {uuid} healthProfileId
 */
export async function deleteHealthProfile(healthProfileId) {
  const res = await axios.delete(`/v1/admin/healthProfiles/${healthProfileId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 완료/진행중인 유저 리스트를 가져옵니다.
 * @param {string} healthProfileId
 *
 */
export async function fetchHealthProfileUser(healthProfileId) {
  const res = await axios.get(
    `/v1/admin/healthProfiles/${healthProfileId}/users`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 다수의 설문 응답을 초기화합니다.
 * @param {uuid} healthProfileId
 * @param {array} userIds
 */
export async function deleteHealthProfileUser(healthProfileId, userIds) {
  const res = await axios.delete(
    `/v1/admin/healthProfiles/${healthProfileId}/users`,
    {
      data: { userIds },
    },
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 질문을 가져옵니다.
 * @param {string} healthProfileId
 * @param {string} healthProfileQuestionId
 */
export async function fetchHealthProfileQuestion(
  healthProfileId,
  healthProfileQuestionId,
) {
  const res = await axios.get(
    `/v1/admin/healthProfiles/${healthProfileId}/healthProfileQuestions/${healthProfileQuestionId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 질문을 생성합니다.
 * @param {uuid} healthProfileId
 * @param {Object} data
 */
export async function postHealthProfileQuestion(healthProfileId, data) {
  const res = await axios.post(
    `/v1/admin/healthProfiles/${healthProfileId}/healthProfileQuestions`,
    data,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문 질문을 수정합니다.
 * @param {uuid} healthProfileQuestionId
 * @param {array} patchData
 */
export async function patchHealthProfileQuestion(
  healthProfileId,
  healthProfileQuestionId,
  patchData,
) {
  const res = await axios.put(
    `/v1/admin/healthProfiles/${healthProfileId}/healthProfileQuestions/${healthProfileQuestionId}`,
    patchData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 다수의 설문 질문을 수정합니다.
 * @param {uuid} healthProfileId
 * @param {array} patchData
 */
export async function patchHealthProfileQuestions(healthProfileId, patchData) {
  const res = await axios.patch(
    `/v1/admin/healthProfiles/${healthProfileId}/healthProfileQuestions`,
    patchData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 다수의 설문 질문을 삭제합니다.
 * @param {uuid} healthProfileId
 * @param {array} healthProfileQuestionIds
 */
export async function deleteHealthProfileQuestions(
  healthProfileId,
  healthProfileQuestionIds,
) {
  const res = await axios.delete(
    `/v1/admin/healthProfiles/${healthProfileId}/healthProfileQuestions`,
    {
      data: { ids: healthProfileQuestionIds },
    },
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 다수의 설문 질문을 복사합니다.
 * @param {uuid} healthProfileId
 * @param {array} healthProfileQuestionIds
 */
export async function copyHealthProfileQuestions(
  healthProfileId,
  healthProfileQuestionIds,
) {
  const res = await axios.post(
    `/v1/admin/healthProfiles/${healthProfileId}/healthProfileQuestions/copies`,
    { ids: healthProfileQuestionIds },
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 설문 질문과 답변 정보를 가져옵니다.
 * @param {uuid} healthProfileOptionId
 */
export async function fetchHealthProfileQuestionAnswer({
  userId,
  healthProfileId,
}) {
  const res = await axios.get(
    `/v4/admin/users/${userId}/healthProfiles/healthProfilesQNAs?healthProfileId=${healthProfileId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 설문 답변을 수정합니다.
 * @param {String} healthProfileOptionId
 * @param {Object} body
 */
export async function patchHealthProfileQuestionAnswer(
  userId,
  healthProfileAnswerId,
  body,
) {
  const res = await axios.patch(
    `/v3/admin/users/${userId}/healthProfileAnswers/${healthProfileAnswerId}`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 기본 설문 질문 답변 정보를 가져옵니다.
 * @param {String} userId
 * @param {String} type
 */
export async function fetchHealthProfileBasicQuestionAnswer({ userId, type }) {
  const res = await axios.get(
    `/v3/admin/healthProfileQuestions?userId=${userId}&type=${type}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 기본 설문 질문 답변 정보를 가져옵니다.
 * @param {String} userId
 * @param {String} diseaseGroupId
 * @param {Object} body
 */
export async function postHealthProfileAnswer(userId, diseaseGroupId, body) {
  const res = await axios.post(
    `/v3/admin/diseaseGroups/${diseaseGroupId}/users/${userId}/healthProfileAnswers`,
    body,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 카이안과 설문 질문과 답변 정보를 가져옵니다.
 * @param {uuid} healthProfileOptionId
 */
export async function fetchHealthProfileQuestionsHealthProfileAnswers(
  projectName,
) {
  const res = await axios.get(
    `/v4/admin/userParticipationHistories/${projectName}/reports`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 설문을 복사합니다.
 * @param {string} healthProfileId
 */
export async function postHealthProfileCopy(healthProfileId) {
  const res = await axios.post(
    `/v1/admin/healthProfiles/${healthProfileId}/copies`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 액셀 다운로드 요청 / 다운로드 합니다.
 * @param {uuid} healthProfileId
 */
export async function fetchHealthProfileExcel(
  healthProfileId,
  fileName = '건강 설문',
) {
  const res = await axios.get(
    `/v1/admin/excel?type=HEALTH_PROFILE&targetId=${healthProfileId}`,
    { responseType: 'arraybuffer' },
  );

  let name = `${fileName}.xlsx`;

  // NOTE(reo): 아래 코드는 보안 설정 문제로 작동하지 않음; content-disposition이 항상 undefined임
  const disposition = res.headers['content-disposition'];
  if (disposition && disposition.includes('filename=')) {
    const matches = disposition.match(/filename="(.+)"/);
    if (matches != null && matches[1]) {
      name = decodeURIComponent(matches[1]);
    }
  }

  const blob = new Blob([res.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = name;

  link.click();

  URL.revokeObjectURL(link.href);
}

/**
 * 액셀 다운로드 요청 리스트를 가져옵니다.
 */
export async function fetchHealthProfileWaitingList() {
  const res = await axios.get(
    `/v4/admin/securityApprovalHistories/waitingList`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 질환별 설문 질문과 답변 정보를 가져옵니다.
 * @param {uuid} diseaseId
 */
export async function fetchDiseaseHealthProfileAnswers(diseaseId) {
  const res = await axios.get(
    `/v4/admin/healthProfiles/diseaseHealthProfile/excel/?contentsId=${diseaseId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}
