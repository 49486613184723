import React, { useEffect, useState, useRef, useReducer } from 'react';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { useDispatch } from 'react-redux';
import { Form, Input, Button, Alert, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import WindowHeader from '../../components/windowHeader';
import {
  fetchDictionary,
  postDictionary,
  patchDictionary,
  deleteDictionary,
} from '../../../services/dictionaryService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import { dictionaryCreators } from '../../../store/reducers/dictionary.reducer';
import ElementLoading from '../../components/elementLoading';
import { ALERT_MESSAGES } from '../../../assets/alert';

const DictionaryWindow = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [dictionaryId, setDictionaryId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);

  const initialState = {
    krName: '',
    enName: '',
    description: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const { TextArea } = Input;
  const [state, setState] = useReducer(stateReducer, initialState);
  const didCancel = useRef(false);

  const { data: fetchDictionaryData, call: callDictionary } = useFetch(
    null,
    fetchDictionary,
    dictionaryId,
  );
  const { mutate: putDictionary, done: isPosted } = useMutate(
    postDictionary,
    state,
  );
  const { mutate: changeDictionary, done: isPatched } = useMutate(
    patchDictionary,
    dictionaryId,
    state,
  );
  const { mutate: eraseDictionary, done: isDeleted } = useMutate(
    deleteDictionary,
    dictionaryId,
  );

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(dictionaryCreators.fetchAllDictionary.request());
    if (params.id) {
      setDictionaryId(params.id);
    } else {
      setDictionaryId('');
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (dictionaryId) {
      callDictionary();
    }
  }, [dictionaryId, callDictionary]);

  useEffect(() => {
    if (fetchDictionaryData && !didCancel.current) {
      setState({
        krName: fetchDictionaryData.krName,
        enName: fetchDictionaryData.enName,
        description: fetchDictionaryData.description,
      });
      didCancel.current = true;
      setIsLoading(false);
    } else if (dictionaryId === '' && !didCancel.current) {
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [fetchDictionaryData, dictionaryId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      krName: values.krName,
      enName: values.enName,
      description: values.description,
    });
    if (dictionaryId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseDictionary();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeDictionary();
      setIsPatch(false);
    }
    if (isPost) {
      putDictionary();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  if (isLoading) return <ElementLoading type="용어 사전" />;
  return (
    <>
      <WindowHeader title="용어 사전" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              krName: state.krName,
              enName: state.enName,
              description: state.description,
            }}
          >
            <Form.Item
              label="단어(한글)"
              name="krName"
              rules={[
                {
                  required: true,
                  message: '단어(한글)을 확인해주세요.',
                },
              ]}
            >
              <TextArea autoSize />
            </Form.Item>
            <Form.Item
              label="단어(영어)"
              name="enName"
              rules={[
                {
                  required: true,
                  message: '단어(영어)을 확인해주세요.',
                },
              ]}
            >
              <TextArea autoSize />
            </Form.Item>
            <Form.Item
              label="설명"
              name="description"
              rules={[
                {
                  required: true,
                  message: '설명을 확인해주세요.',
                },
              ]}
            >
              <TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {dictionaryId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default DictionaryWindow;
