// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const config =  {
    API: {
        GraphQL: {
          endpoint: 'https://supre6nefrdctpxijsb2276lqa.appsync-api.ap-northeast-2.amazonaws.com/graphql',
          region: 'ap-northeast-2',
          defaultAuthMode: 'apiKey',
          apiKey: 'da2-goodvmxz3zcybcwkodfrfyaooi'
        }
    }
};

export default config;