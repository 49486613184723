import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Layout,
  Table,
  Input,
  Row,
  notification,
  Typography,
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import {
  HEALTH_PROFILE_ACCOUNT_TYPES,
  HEALTH_PROFILE_GENDER,
} from '../../../util/healthProfileConstants';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import { healthProfileCreators } from '../../../store/reducers/healthProfile.reducer';
import { deleteHealthProfileUser } from '../../../services/healthProfileService';

const HealthProfileUsers = () => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { healthProfileUserInfo, healthProfileUserPagination } = useSelector(
    (state) => {
      return {
        healthProfileUserInfo:
          state?.healthProfileReducer?.healthProfileUser?.data,
        healthProfileUserPagination:
          state?.paginationReducer?.healthProfileUserPagination,
      };
    },
    shallowEqual,
  );

  const [total, setTotal] = useState(0);
  const [pageUpdate, setPageUpdate] = useState(1);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const confirmRef = useRef(() => {});

  const getData = useCallback(() => {
    dispatch(
      healthProfileCreators.fetchHealthProfileUsers.request(
        state?.healthProfileId,
      ),
    );
  }, [dispatch]);

  useEffect(getData, [getData]);

  const { Search } = Input;
  const { Content } = Layout;

  const handleMoveDetailPage = useCallback(
    ({ userId }) => () => history.push('/userDetail', { userId }),
    [history],
  );

  const CompletedTag = (completed) => {
    return (
      <div
        className="tagWrapper"
        style={{
          color: completed ? '#1890ff' : '#979797',
        }}
      >
        {completed ? '완료' : '진행중'}
      </div>
    );
  };

  const onReset = async () => {
    if (selectedUsers.length === 0) return;
    if (window.confirm('선택한 유저들의 설문을 초기화 하시겠습니까?')) {
      try {
        await deleteHealthProfileUser(state?.healthProfileId, selectedUsers);
        notification.success({
          message: '선택된 유저들의 응답 상태를 초기화했습니다.',
          key: 'resetComplete',
        });
        getData();
      } catch (e) {
        notification.error({
          message: '선택된 유저들의 응답 상태 초기화에 실패했습니다.',
          key: 'resetFail',
        });
        throw e;
      }
    }
  };

  const columns = [
    {
      title: '상태',
      dataIndex: 'isComplete',
      key: 'isComplete',
      render: CompletedTag,
      filters: [
        {
          text: '완료',
          value: true,
        },
        {
          text: '진행중',
          value: false,
        },
      ],
      filteredValue: healthProfileUserPagination.filter?.isComplete || null,
      onFilter: (value, record) => record.isComplete === value,
    },
    {
      title: '업데이트일',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (text, record) => moment(text).format('YYYY-MM-DD HH:mm:ss'),
      sorter: {
        compare: (a, b) => a?.updatedAt?.localeCompare(b?.updatedAt),
      },
    },
    {
      title: '계정',
      dataIndex: 'email',
      sorter: (a, b) => a?.email?.localeCompare(b?.email),
      filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => {
        confirmRef.current = (searchWord) => {
          if (searchWord) setSelectedKeys([searchWord]);
          else clearFilters();
          confirm();
        };
        return <></>;
      },
      filterIcon: () => <></>,
      filteredValue: healthProfileUserPagination.filter?.email || null,
      onFilter: (search, { email, name, phone }) =>
        [email, name, phone].some((value) => value?.includes(search)),
      render: (text, record) =>
        text ? (
          <>
            {text}
            <Button
              type="primary"
              size="small"
              style={{ marginLeft: 8 }}
              onClick={handleMoveDetailPage(record)}
            >
              이동
            </Button>
          </>
        ) : (
          '-'
        ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      filteredValue: healthProfileUserPagination.filter?.gender || [],
      onFilter: (value, record) => record.gender === value,
      filters: Object.entries(HEALTH_PROFILE_GENDER).map(([key, value]) => ({
        text: value,
        value: key,
      })),
      render: (gender) => HEALTH_PROFILE_GENDER[gender] ?? '-',
    },
    {
      title: '질환',
      dataIndex: 'diseaseTypes',
      key: 'diseaseTypes',
      sorter: {
        compare: (a, b) =>
          a?.diseaseTypes
            ?.map((d) => d.name)
            .join(',')
            .localeCompare(b?.diseaseTypes?.map((d) => d.name).join(',')),
      },
      render: (diseaseTypes) =>
        diseaseTypes?.map((d) => d.name)?.join(', ') ?? '-',
    },
    {
      title: '계정 유형',
      dataIndex: 'accountType',
      key: 'accountType',
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
      render: (accountType) => HEALTH_PROFILE_ACCOUNT_TYPES[accountType] ?? '-',
    },
    {
      title: '14세 미만',
      dataIndex: 'isUnder14',
      key: 'isUnder14',
      render: (isUnder14) => <>{isUnder14 ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: healthProfileUserPagination.filter?.isUnder14 || [],
      onFilter: (value, record) => record.isUnder14 === value,
    },
    {
      title: '테스트ID',
      dataIndex: 'isTest',
      key: 'isTest',
      render: (isTest) => <>{isTest ? 'O' : 'X'}</>,
      filters: [
        {
          text: 'O',
          value: true,
        },
        {
          text: 'X',
          value: false,
        },
      ],
      filteredValue: healthProfileUserPagination.filter?.isTest || [],
      onFilter: (value, record) => record.isTest === value,
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="healthProfile" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="정보"
            subTitle="설문 완료/진행중인 유저"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Typography.Title level={4}>{state.title}</Typography.Title>
            <Row>
              <Button type="primary" onClick={() => history.goBack()}>
                설문 돌아가기
              </Button>
              <Button
                danger
                style={{ marginLeft: 8 }}
                onClick={onReset}
                disabled={selectedUsers.length === 0}
              >
                답변 초기화
              </Button>
            </Row>
            <Search
              placeholder="검색어를 입력해주세요."
              allowClear
              className="searchStyle"
              onSearch={(value) => {
                confirmRef.current(value);
              }}
              defaultValue={healthProfileUserPagination.text}
            />
            <div className="searchResult">검색결과 {total}개</div>
            <Table
              rowKey={(record) => record.userId}
              columns={columns}
              dataSource={healthProfileUserInfo}
              pagination={{
                onChange: (page) => {
                  setPageUpdate(page);
                },
                current: pageUpdate,
                defaultPageSize: 20,
                showSizeChanger: true,
                pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
              }}
              onChange={(pagination, filters, sorter, extra) => {
                dispatch(
                  paginationCreators.setValue(
                    (healthProfileUserPagination.page = pagination?.current),
                    (healthProfileUserPagination.filter = filters),
                    (healthProfileUserPagination.total =
                      extra?.currentDataSource?.length),
                  ),
                );
                setTotal(extra?.currentDataSource?.length);
              }}
              size="small"
              rowSelection={{
                type: 'checkbox',
                onChange: (selectedRowKeys) => {
                  setSelectedUsers(selectedRowKeys);
                },
              }}
              bordered
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default HealthProfileUsers;
