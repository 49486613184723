import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabs, Row, Col, Menu, Divider, Skeleton, List } from 'antd';
import 'antd/dist/antd.css';
import { WalletTwoTone } from '@ant-design/icons';
import { healthProfileCreators } from '../../../store/reducers/healthProfile.reducer';
import { useFetch } from '../../../hooks/useRequest';
import UserHospitalExamination from './userHospitalExamination';
import UserdiseaseTypeCertification from './userdiseaseTypeCertification';
import { fetchHealthProfileQuestionAnswer as fetchHealthProfileQuestionAnswerService } from '../../../services/healthProfileService';
import UserPapRequest from './userPapRequest';
import { fetchHealthProfileGroupUser } from '../../../services/healthProfileGroupService';
import UserHealthProfileGroup from './userHealthProfileGroup';
import { useLocation } from 'react-router-dom';

const UserHealthProfile = (userId) => {
  const dispatch = useDispatch();
  const { healthProfileInfo } = useSelector((state) => ({
    healthProfileInfo: state?.healthProfileReducer?.healthProfile?.data?.map(
      ({ id, type }) => ({
        label: type,
        key: id,
      }),
    ),
  }));
  const { state } = useLocation();

  const [healthProfileGroup, setHealthProfileGroup] = useState([]);

  const getData = useCallback(async () => {
    const res = await fetchHealthProfileGroupUser(userId?.userId);
    setHealthProfileGroup(res);
    dispatch(healthProfileCreators.fetchAllHealthProfiles.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const [key, setKey] = useState(state.dataKey || 'pap');
  const [isPapPermitted, setIsPapPermitted] = useState(true);
  const [selectedHealthProfileId, setSelectedHealthProfileId] = useState('');
  const fetchHealthProfileDone = useRef(false);
  const { TabPane } = Tabs;
  const loading = !healthProfileInfo;

  const {
    done: fetchUserHealthProfileQuestionAnswerDone,
    data: fetchUserHealthProfileQuestionAnswerData,
    call: fetchUserHealthProfileQuestionAnswer,
    initialize: initializeUserHealthProfileQuestionAnswerDone,
  } = useFetch(null, fetchHealthProfileQuestionAnswerService, {
    userId: userId?.userId,
    healthProfileId: selectedHealthProfileId,
  });

  useEffect(() => {
    if (selectedHealthProfileId && !fetchHealthProfileDone.current) {
      fetchUserHealthProfileQuestionAnswer();
      fetchHealthProfileDone.current = true;
    }
  }, [selectedHealthProfileId, fetchUserHealthProfileQuestionAnswer]);

  useEffect(() => {
    if (fetchUserHealthProfileQuestionAnswerDone) {
      fetchHealthProfileDone.current = false;
      setSelectedHealthProfileId(null);
      initializeUserHealthProfileQuestionAnswerDone();
    }
  }, [
    fetchUserHealthProfileQuestionAnswerDone,
    initializeUserHealthProfileQuestionAnswerDone,
  ]);

  useEffect(() => {
    if (!isPapPermitted && key === 'pap') {
      setKey('healthProfileSelected');
    }
  }, [isPapPermitted, key]);

  const concatAnswer = (answerList) => {
    let answer = '';
    answerList.forEach((answerData, index) => {
      if (index === 0) answer += `${answerData}`;
      else answer += `, ${answerData}`;
    });
    return answer;
  };

  return (
    <Row span={24}>
      <Col span={24}>
        <h5>
          <WalletTwoTone className="userTitleIcon" />
          데이터 보관함
        </h5>
        <Divider plain />
        <Tabs
          defaultActiveKey={key}
          onChange={setKey}
          className="userDetailTab"
        >
          {isPapPermitted && (
            <TabPane tab="PAP" key="pap">
              <UserPapRequest
                userId={userId?.userId}
                setIsPapPermitted={setIsPapPermitted}
              />
            </TabPane>
          )}
          <TabPane tab="설문" key="healthProfileSelected">
            <Row>
              {loading ? (
                <Skeleton active />
              ) : (
                <>
                  <Col className="healthProfileTitle">
                    <h6>설문 항목</h6>
                    <Divider plain />
                    <Menu
                      onClick={(e) => setSelectedHealthProfileId(e?.key)}
                      mode="inline"
                      items={healthProfileInfo}
                    />
                  </Col>
                  <Col className="healthProfileSub">
                    <h6>설문 내용</h6>
                    <Divider plain />
                    <List
                      itemLayout="horizontal"
                      dataSource={
                        fetchUserHealthProfileQuestionAnswerData || []
                      }
                      renderItem={(item) => (
                        <List.Item>
                          <List.Item.Meta
                            title={item?.mainQuestion}
                            description={
                              <div
                                className={
                                  item?.healthProfileAnswers?.[0]?.mainAnswers
                                    ? 'greenText'
                                    : 'redText'
                                }
                              >
                                {item?.healthProfileAnswers?.[0]?.mainAnswers
                                  ? concatAnswer(
                                      item?.healthProfileAnswers?.[0]
                                        ?.mainAnswers,
                                    )
                                  : '답변을 하지 않았습니다'}
                              </div>
                            }
                          />
                        </List.Item>
                      )}
                    />
                  </Col>
                </>
              )}
            </Row>
          </TabPane>
          <TabPane tab="병원 검사" key="hospitalExaminationSelected">
            <UserHospitalExamination userId={userId?.userId} />
          </TabPane>
          <TabPane tab="질환 인증" key="diseaseTypeCertificationSelected">
            <UserdiseaseTypeCertification userId={userId?.userId} />
          </TabPane>
          <TabPane tab="주기적 설문" key="healthProfileGroupSelected">
            <UserHealthProfileGroup healthProfileGroup={healthProfileGroup} />
          </TabPane>
          {/* 유전자 검사 준비 중입니다. */}
          {/* <TabPane tab="유전자 검사" key="dnaExaminationSelected">
        </TabPane> */}
        </Tabs>
      </Col>
    </Row>
  );
};

export default UserHealthProfile;
