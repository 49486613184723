// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { customOpFactory } from '../operations/custom';
import { convertItemToConversationMessage } from './convertItemToConversationMessage';
import { serializeAiContext, serializeContent, serializeToolConfiguration, } from './conversationMessageSerializers';
import { AiAction, getCustomUserAgentDetails, } from './getCustomUserAgentDetails';
export const createSendMessageFunction = (client, modelIntrospection, conversationId, conversationRouteName, getInternals) => async (input) => {
    const { conversations } = modelIntrospection;
    // Safe guard for standalone function. When called as part of client generation, this should never be falsy.
    if (!conversations) {
        return {};
    }
    const processedInput = typeof input === 'string' ? { content: [{ text: input }] } : input;
    const { content, aiContext, toolConfiguration } = processedInput;
    const sendSchema = conversations[conversationRouteName].message.send;
    const sendOperation = customOpFactory(client, modelIntrospection, 'mutation', sendSchema, false, getInternals, getCustomUserAgentDetails(AiAction.SendMessage));
    const { data, errors } = await sendOperation({
        conversationId,
        content: serializeContent(content),
        ...(aiContext && { aiContext: serializeAiContext(aiContext) }),
        ...(toolConfiguration && {
            toolConfiguration: serializeToolConfiguration(toolConfiguration),
        }),
    });
    return {
        data: data ? convertItemToConversationMessage(data) : data,
        errors,
    };
};
