import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  Upload,
  Radio,
  Select,
  Space,
  Spin,
  Checkbox,
  Collapse,
  notification,
} from 'antd';
import LoadingOverlay from 'react-loading-overlay';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import '../../../public/css/window.css';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';
import { notificationCreators } from '../../../store/reducers/notification.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';

import {
  fetchPatientGroup,
  postPatientGroup,
  patchPatientGroup,
  deletePatientGroup,
} from '../../../services/patientGroupService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useAntFileUpload } from '../../util/useAntFileImage';
import AntTinymceInput from '../../components/antTinymceInput';
import * as listMap from '../../../util/listMap';

const PatientGroupWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { notificationContents, diseaseTypeInfo } = useSelector((state) => {
    return {
      notificationContents: state.notificationReducer.contents.data,
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
    };
  }, shallowEqual);

  const initialState = {
    shareLink: '',
    name: '',
    photo: '',
    diseaseTypeIds: [],
    level: 'beforeRegistration',
    healthProfiles: [],
    projects: [],
    bankAccount: '',
    isDonationType: false,
    donationType: 'donationURL',
    donationURL: '',
    mission: '',
    establishedYear: '',
    description: '',
    sites: [],
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [patientGroupId, setPatientGroupId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const fileUploadSetting = useRef(false);
  const didCancel = useRef(false);
  const uploadCancel = useRef(false);
  const nextID = useRef(0);
  const healthProfileNextID = useRef(0);
  const [inputItems, setInputItems] = useState([]);
  const [inputHealthProfiles, setInputHealthProfiles] = useState([]);
  const [form] = Form.useForm();
  const [imageLoading, setImageLoading] = useState(false);
  const { Panel } = Collapse;

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useAntFileUpload(distinctFileInputEvent);

  const postPatchData = {
    name: state.name,
    description: state.description,
    photo: state.photo || null,
    diseaseTypeIds: state.diseaseTypeIds,
    healthProfiles: state.healthProfiles,
    projects: [state.projects],
    level: state.level,
    mission: state.mission,
    establishedYear: state.establishedYear,
    sites: state.sites,
    donationType: state.isDonationType ? state.donationType : null,
    bankAccount: state.bankAccount,
    donationURL: state.donationURL,
    shareLink: state.shareLink,
  };

  const { data: fetchPatientGroupData, call: fetchPatientGroupInfo } = useFetch(
    null,
    fetchPatientGroup,
    patientGroupId,
  );

  const { mutate: putPatientGroup, done: isPosted } = useMutate(
    postPatientGroup,
    postPatchData,
  );

  const { mutate: changePatientGroup, done: isPatched } = useMutate(
    patchPatientGroup,
    patientGroupId,
    postPatchData,
  );

  const { mutate: erasePatientGroup, done: isDeleted } = useMutate(
    deletePatientGroup,
    patientGroupId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(notificationCreators.fetchAllNotificationContents.request());
    if (params.id) {
      setPatientGroupId(params.id);
    } else {
      setPatientGroupId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (patientGroupId) {
      fetchPatientGroupInfo();
    }
  }, [patientGroupId, fetchPatientGroupInfo]);

  useEffect(() => {
    if (
      fetchPatientGroupData &&
      diseaseTypeInfo &&
      notificationContents &&
      !didCancel.current
    ) {
      setState({
        shareLink: fetchPatientGroupData?.shareLink,
        name: fetchPatientGroupData?.name,
        photo: fetchPatientGroupData?.photo,
        diseaseTypeIds: fetchPatientGroupData?.diseaseTypes?.map(
          (item) => item?.id,
        ),
        level: fetchPatientGroupData?.level,
        // healthProfiles: [],
        projects: fetchPatientGroupData?.projects?.[0],
        bankAccount: fetchPatientGroupData?.bankAccount,
        isDonationType: !!(
          fetchPatientGroupData?.donationType === 'donationURL' ||
          fetchPatientGroupData?.donationType === 'bankAccount'
        ),
        donationType: fetchPatientGroupData?.donationType,
        donationURL: fetchPatientGroupData?.donationURL,
        mission: fetchPatientGroupData?.mission,
        establishedYear: fetchPatientGroupData?.establishedYear,
        description: fetchPatientGroupData?.description,
        // sites: [],
      });
      setInputItems(
        fetchPatientGroupData?.sites?.map((item, index) => ({
          id: index,
          name: item?.name,
          url: item?.url,
        })),
      );
      setInputHealthProfiles(
        fetchPatientGroupData?.patientGroupHealthProfiles?.map(
          (item, index) => ({
            id: index,
            completeContent: item?.completeContent,
            goalEnrollment: item?.goalEnrollment,
            status: item?.status,
            healthProfileId: item?.healthProfileId,
          }),
        ),
      );
      nextID.current = fetchPatientGroupData?.sites?.length;
      healthProfileNextID.current =
        fetchPatientGroupData?.patientGroupHealthProfiles?.length;

      didCancel.current = true;
      setIsLoading(false);
    } else if (
      patientGroupId === '' &&
      diseaseTypeInfo &&
      notificationContents &&
      !didCancel.current
    ) {
      setIsLoading(false);
      didCancel.current = true;
    }
  }, [
    fetchPatientGroupData,
    diseaseTypeInfo,
    notificationContents,
    patientGroupId,
  ]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      name: values.name,
      projects: values.projects,
      mission: values.mission,
      establishedYear: values.establishedYear,
      sites: inputItems,
      healthProfiles: inputHealthProfiles,
      bankAccount: values.bankAccount,
      donationURL: values.donationURL,
    });
    if (patientGroupId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      erasePatientGroup();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changePatientGroup();
      setIsPatch(false);
    }
    if (isPost) {
      putPatientGroup();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  const beforeUpload = (file) => {
    const isSupportedImageType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg+xml';
    if (!isSupportedImageType) {
      notification.error({
        message: 'JPG/PNG/SVG만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isSupportedImageType && isLt10M;
  };

  const uploadImg = async (e) => {
    if (e !== undefined) {
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const noop = () => {};

  const deleteThumbImg = () => {
    form.setFieldsValue({ photo: '' });
    setState({
      photo: '',
    });
    notification.success({
      message: '이미지를 삭제하였습니다.',
      description: '환우회 이미지를 다시 업로드해주세요.',
    });
  };

  const uploadProps = {
    customRequest: noop,
    onChange: uploadImg,
    onPreview: noop,
  };

  useEffect(() => {
    if (distinctFileInputEvent && uploadCancel.current) {
      distinctImageUpload();
      uploadCancel.current = false;
      fileUploadSetting.current = true;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '환우회 이미지 등록에 성공하였습니다.',
      });
      setState({ photo: distinctPublicUrl });
      form.setFieldsValue({ photo: distinctPublicUrl });
      distinctUploadInitialize();
      setLoading(false);
    }
  }, [distinctImageUploadDone, distinctPublicUrl, distinctUploadInitialize]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const addHealthProfileInput = () => {
    let isRecruitinng = false;
    inputHealthProfiles?.map((item) => {
      if (item?.status === 'recruiting') {
        isRecruitinng = true;
      }
    });

    if (isRecruitinng) {
      alert('모집중인 건강 설문이 있습니다.');
      return;
    }

    const input = {
      id: healthProfileNextID.current,
      completeContent: '',
      goalEnrollment: '',
      status: 'recruiting',
      healthProfileId: '',
    };
    setInputHealthProfiles([...inputHealthProfiles, input]);
    healthProfileNextID.current += 1;
  };

  const deleteHealthProfileInput = (index) => {
    if (window.confirm('정말 삭제하실건가요?')) {
      setInputHealthProfiles(
        inputHealthProfiles.filter((item) => item.id !== index),
      );
    }
  };

  const handleHealthProfileChange = (e, index, label) => {
    const inputItemsCopy = JSON.parse(JSON.stringify(inputHealthProfiles));
    if (label === 'healthProfileId' || label === 'completeContent') {
      inputItemsCopy[index][label] = e;
    } else {
      inputItemsCopy[index][label] = e.target.value;
    }
    setInputHealthProfiles(inputItemsCopy);
  };

  const addInput = () => {
    const input = {
      id: nextID.current,
      name: '',
      url: '',
    };
    setInputItems([...inputItems, input]);
    nextID.current += 1;
  };

  const deleteInput = (index) => {
    if (window.confirm('정말 삭제하실건가요?')) {
      setInputItems(inputItems.filter((item) => item.id !== index));
    }
  };

  const handleSitesChange = (e, index, label) => {
    const inputItemsCopy = JSON.parse(JSON.stringify(inputItems));
    inputItemsCopy[index][label] = e.target.value;
    setInputItems(inputItemsCopy);
  };

  const onIsDonationTypeChange = (e) => {
    setState({
      isDonationType: e.target.value,
    });
  };

  const onDonationTypeChange = (e) => {
    setState({
      donationType: e.target.value,
    });
  };

  const onLevelChange = (e) => {
    setState({
      level: e.target.value,
    });
  };

  if (isLoading) return <ElementLoading type="환우회" />;
  return (
    <>
      <WindowHeader title="환우회" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              shareLink: state.shareLink,
              name: state.name,
              photo: state.photo,
              diseaseTypeIds: state.diseaseTypeIds,
              healthProfiles: state.healthProfiles,
              projects: state.projects,
              level: state.level,
              bankAccount: state.bankAccount,
              isDonationType: state.isDonationType,
              donationType: state.donationType,
              donationURL: state.donationURL,
              mission: state.mission,
              establishedYear: state.establishedYear,
              description: state.description,
              sites: state.sites,
            }}
          >
            <Form.Item
              wrapperCol={{ span: 4 }}
              style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 16 }}
            >
              환우회
            </Form.Item>
            <Form.Item label="공유 링크" name="shareLink">
              <Input placeholder="미입력 시 자동으로 생성됩니다" disabled />
            </Form.Item>
            <Form.Item
              label="환우회명"
              name="name"
              rules={[
                {
                  required: true,
                  message: '환우회명을 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="환우회명을 입력해주세요." />
            </Form.Item>
            <Form.Item
              label="환우회 이미지"
              name="photo"
              rules={[
                {
                  required: true,
                  message: '환우회 이미지를 확인해주세요.',
                },
              ]}
            >
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.photo ? (
                  <img
                    src={state?.photo}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.photo && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={deleteThumbImg}
                >
                  이미지 삭제
                </Button>
              )}
            </Form.Item>
            <Form.Item
              label="질환"
              name="diseaseTypeIds"
              rules={[
                {
                  required: true,
                  message: '질환을 확인해주세요.',
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="질환을 선택해주세요"
                filterOption={(input, option) =>
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.key.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
                onChange={(e) =>
                  setState({
                    diseaseTypeIds: e,
                  })
                }
              >
                {diseaseTypeInfo?.map((diseaseType) => (
                  <Select.Option
                    key={diseaseType?.krName}
                    id={diseaseType?.krName}
                    value={diseaseType?.id}
                  >
                    {diseaseType.krName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="추진 사업"
              name="projects"
              rules={[
                {
                  required: true,
                  message: '추진 사업을 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="추진 사업이 여러개일 경우 콤마로 구분해 넣어주세요" />
            </Form.Item>
            <Form.Item
              label="성장 단계"
              name="level"
              rules={[
                {
                  required: true,
                  message: '성장 단계를 확인해주세요.',
                },
              ]}
            >
              <Radio.Group
                onChange={onLevelChange}
                value={state.level}
                optionType="button"
                buttonStyle="solid"
                options={listMap.levelOptions}
              />
            </Form.Item>
            <hr />

            <Form.Item
              wrapperCol={{ span: 4 }}
              style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 16 }}
            >
              건강 설문
            </Form.Item>
            <Form.Item
              wrapperCol={{ offset: 4 }}
              style={{ marginTop: -55, paddingLeft: 12, marginBottom: 0 }}
            >
              <Button
                style={{ width: 104, marginBottom: 16 }}
                onClick={addHealthProfileInput}
              >
                추가하기
              </Button>
            </Form.Item>

            <Form.Item name="healthProfiles" wrapperCol={{ offset: 4 }}>
              <Collapse defaultActiveKey={[inputHealthProfiles?.length - 1]}>
                {inputHealthProfiles?.map((item, index) => (
                  <Panel
                    header={`건강 설문 ${index + 1}번`}
                    key={index}
                    extra={
                      <>
                        <Button
                          danger
                          onClick={() => deleteHealthProfileInput(item.id)}
                        >
                          삭제
                        </Button>
                      </>
                    }
                  >
                    <div>
                      <Form.Item label="건강 설문 상태">
                        <Radio.Group
                          onChange={(e) => {
                            if (
                              (e.target.value === 'completed' &&
                                item?.healthProfileId !== '' &&
                                item?.goalEnrollment !== '') ||
                              e.target.value === 'recruiting'
                            ) {
                              handleHealthProfileChange(e, index, 'status');
                            } else {
                              notification.error({
                                message: '건강 설문과 목표인원을 확인해주세요.',
                                description:
                                  '건강 설문과 목표인원을 입력해야 결과 발행을 할 수 있습니다.',
                              });
                            }
                          }}
                          value={item?.status}
                          optionType="button"
                          buttonStyle="solid"
                          options={listMap.healthProfileStatusOptions}
                        />
                      </Form.Item>
                      {item?.status === 'recruiting' && (
                        <>
                          <Form.Item
                            label="건강 설문"
                            name={`inputHealthProfiles${index}`}
                            rules={[
                              {
                                required: true,
                                message: '건강 설문을 확인해주세요.',
                              },
                            ]}
                            initialValue={item?.healthProfileId}
                          >
                            <Select
                              allowClear
                              showSearch
                              style={{ width: '100%' }}
                              placeholder="건강 설문을 선택해주세요"
                              onChange={(e) =>
                                handleHealthProfileChange(
                                  e,
                                  index,
                                  'healthProfileId',
                                )
                              }
                              filterOption={(input, option) =>
                                option.props.value
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0 ||
                                option.props.key
                                  .toLowerCase()
                                  .indexOf(input.toLowerCase()) >= 0
                              }
                            >
                              {notificationContents?.['설문']
                                ?.filter(
                                  (content) =>
                                    (content?.research?.length === 0 &&
                                      content?.patientGroups?.length === 0) ||
                                    content?.patientGroups?.[0]
                                      ?.patientGroupHealthProfile
                                      ?.patientGroupId === patientGroupId,
                                )
                                ?.map((healthProfile) => (
                                  <Select.Option
                                    key={
                                      healthProfile?.title + healthProfile?.id
                                    }
                                    value={healthProfile?.id}
                                  >
                                    {healthProfile?.title}
                                  </Select.Option>
                                ))}
                            </Select>
                          </Form.Item>

                          <Form.Item
                            label="목표 인원"
                            name={`goalEnrollment${index}`}
                            rules={[
                              {
                                required: true,
                                message: '목표 인원을 확인해주세요.',
                              },
                            ]}
                            initialValue={item?.goalEnrollment}
                          >
                            <Input
                              type="number"
                              className={`name-${index}`}
                              onChange={(e) =>
                                handleHealthProfileChange(
                                  e,
                                  index,
                                  'goalEnrollment',
                                )
                              }
                              style={{ width: 200 }}
                              placeholder="목표 인원을 설정해주세요"
                            />
                          </Form.Item>
                        </>
                      )}
                      {item?.status === 'completed' && (
                        <Form.Item
                          label="건강 설문 완료 콘텐츠 링크"
                          name={`completeContent${index}`}
                          rules={[
                            {
                              required: true,
                              message: '건강 설문 완료 콘텐츠 링크.',
                            },
                          ]}
                          initialValue={item?.completeContent}
                        >
                          <Select
                            allowClear
                            showSearch
                            style={{ width: '100%' }}
                            placeholder="건강 설문 완료 콘텐츠 링크를 선택해주세요"
                            onChange={(e) =>
                              handleHealthProfileChange(
                                e,
                                index,
                                'completeContent',
                              )
                            }
                            filterOption={(input, option) =>
                              option.props.value
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0 ||
                              option.props.key
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {notificationContents?.['레어노트 콘텐츠']?.map(
                              (content) => (
                                <Select.Option
                                  key={content?.title + content?.id}
                                  value={content?.id}
                                >
                                  {content?.title}
                                </Select.Option>
                              ),
                            )}
                          </Select>
                        </Form.Item>
                      )}
                    </div>
                  </Panel>
                ))}
              </Collapse>
            </Form.Item>

            <hr />

            <Form.Item
              wrapperCol={{ span: 4 }}
              style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 16 }}
            >
              회비 납부
            </Form.Item>
            <Form.Item
              wrapperCol={{ span: 24 }}
              label="환우회 계좌"
              name="bankAccount"
            >
              <Input placeholder="은행명 계좌번호 예금주 / 예) 우리 1234-12-1234567 김레어" />
            </Form.Item>
            <Form.Item
              label="회비 납부 서비스"
              name="isDonationType"
              rules={[
                {
                  required: true,
                  message: '회비 납부 서비스를 확인해주세요.',
                },
              ]}
            >
              <Radio.Group
                onChange={onIsDonationTypeChange}
                value={state.isDonationType}
                optionType="button"
                buttonStyle="solid"
                options={listMap.donationTypeOptions}
              />
            </Form.Item>
            {state.isDonationType && (
              <>
                <Form.Item
                  wrapperCol={{
                    offset: 4,
                    span: 20,
                  }}
                  name="donationType"
                >
                  <Radio.Group
                    onChange={onDonationTypeChange}
                    value={state.donationType}
                  >
                    <Space direction="vertical">
                      <Radio value="donationURL">
                        <Form.Item
                          label="회비 링크"
                          name="donationURL"
                          rules={
                            state.donationType === 'donationURL'
                              ? [
                                  {
                                    required: true,
                                    message: '회비 링크를 확인해주세요.',
                                  },
                                ]
                              : []
                          }
                        >
                          <Input
                            placeholder="제휴한 회비 서비스에서 발행된 링크를 입력해주세요"
                            style={{
                              width: 350,
                            }}
                          />
                        </Form.Item>
                      </Radio>
                      <Radio value="bankAccount">환우회 계좌</Radio>
                    </Space>
                  </Radio.Group>
                </Form.Item>
              </>
            )}
            <hr />

            <Form.Item
              wrapperCol={{ span: 4 }}
              style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 16 }}
            >
              환우회 소개
            </Form.Item>
            <Form.Item
              label="환우회 미션"
              name="mission"
              rules={[
                {
                  required: true,
                  message: '환우회 미션을 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="50자 내외로 입력해주세요 (줄바꿈 시 /n 입력)" />
            </Form.Item>
            <Form.Item
              label="설립연도"
              name="establishedYear"
              rules={[
                {
                  required: true,
                  message: '설립연도를 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="설립 연도를 선택해주세요" />
            </Form.Item>

            <Form.Item
              label="환우회 스토리"
              name="description"
              className="treatmentContent"
            >
              <LoadingOverlay
                active={imageLoading}
                spinner={<Spin />}
                text={<p>이미지를 업로드 하는 중</p>}
              >
                <AntTinymceInput
                  content={state.description}
                  setContent={(e) => {
                    setState({ description: e });
                  }}
                  setImageLoading={setImageLoading}
                  onBlur={(e) => {}}
                  contentLinkData={{ setState }}
                />
              </LoadingOverlay>
            </Form.Item>

            <Form.Item label="채널링크" name="sites">
              <Button
                style={{ width: 104, marginBottom: 16 }}
                onClick={addInput}
              >
                추가하기
              </Button>
              {inputItems?.map((item, index) => (
                <div>
                  <Form.Item>
                    <label>채널링크 {index + 1}번:&nbsp;</label>
                    <Input
                      type="text"
                      className={`name-${index}`}
                      onChange={(e) => handleSitesChange(e, index, 'name')}
                      value={item.name}
                      style={{ width: 100, marginRight: 12 }}
                      placeholder="채널명"
                    />
                    <Input
                      type="text"
                      className={`url-${index}`}
                      onChange={(e) => handleSitesChange(e, index, 'url')}
                      value={item.url}
                      style={{ width: 300 }}
                      placeholder="URL"
                    />
                    <Button danger onClick={() => deleteInput(item.id)}>
                      삭제
                    </Button>
                  </Form.Item>
                </div>
              ))}
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {patientGroupId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default PatientGroupWindow;
