import { createAction, handleActions } from 'redux-actions';
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';
import * as Sentry from '@sentry/react';

const accessToken = window.localStorage.getItem('accessToken');
const rawExpiresAt = window.localStorage.getItem('expiresAt');
const expiresAt = rawExpiresAt ? new Date(rawExpiresAt) : undefined;
const expired = !accessToken || expiresAt < new Date();
if (expired) window.localStorage.clear();

const emptyState = { auth: getAsyncState.initial({}) };

// initial states
const initialState = expired
  ? emptyState
  : {
      auth: getAsyncState.initial({
        accessToken,
        refreshToken: window.localStorage.getItem('refreshToken'),
        expiresAt,
        // email: undefined
      }),
    };

// action types
export const authActions = {
  AUTO_LOGIN: '@auth/AUTO_LOGIN',
  LOGOUT: '@auth/LOGOUT',
  ACCESS_LOGIN: '@auth/ACCESS_LOGIN',
  RENEW_SESSION: '@auth/RENEW_SESSION',
  RENEW_WINDOW_SESSION: '@auth/RENEW_WINDOW_SESSION',
};

// actions
export const authCreators = {
  autoLogin: createAsyncActions(authActions, 'AUTO_LOGIN'),
  logout: createAction(authActions.LOGOUT),
  accessLogin: createAsyncActions(authActions, 'ACCESS_LOGIN'),
  renewSession: createAsyncActions(authActions, 'RENEW_SESSION'),
  renewWindowSession: createAction(authActions.RENEW_WINDOW_SESSION),
};

const logoutReducer = (prop) => {
  Sentry.setUser(null);
  return emptyState;
};

const renewWindowSessionReducer = (
  { auth: { data: { email } = {} } },
  { payload },
) => ({
  auth: getAsyncState.initial({
    email,
    ...payload,
  }),
});

const authReducer = handleActions(
  {
    [authActions.LOGOUT]: logoutReducer,
    [authActions.RENEW_WINDOW_SESSION]: renewWindowSessionReducer,
    ...handleAsyncActions(authActions.AUTO_LOGIN, 'auth', (auth, data) => ({
      email: data.email,
      ...auth.data,
    })),
    ...handleAsyncActions(authActions.ACCESS_LOGIN, 'auth'),
    ...handleAsyncActions(authActions.RENEW_SESSION, 'auth', (auth, data) => ({
      email: auth.data.email,
      ...data,
    })),
  },
  initialState,
);

export default authReducer;
