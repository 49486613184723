export const ACCOUNT_ADMIN = 'account_admin';
export const ACCOUNT_USER = 'account_user';
export const ACCOUNT_PATIENT_GROUP = 'patient_group';
export const WITHDRAWAL_USER = 'withdrawal_user';
export const DNA_COUNSELING = 'dna_counseling';
export const DNA_REPORT = 'dna_report';
export const CONTENT_TREATMENT_NEWS = 'content_treatment_news';
export const CONTENT_TERMS = 'content_terms';
export const CONTENT_DNA = 'content_dna';
export const CONTENT_DISEASE = 'content_disease';
export const CONTENT_PRODUCT = 'content_product';
export const MANAGE_NOTICE = 'manage_notice';
export const MANAGE_NOTIFICATION = 'manage_notification';
export const MANAGE_INDIVIDUAL_NOTIFICATION = 'manage_individual_notification';
export const MANAGE_RECOMMENDATION = 'manage_recommendation';
export const ADMIN_LOGIN = 'admin_login';
export const CONTENT_DISEASE_GROUP = 'content_disease_group';
export const CONTENT_DNA_ANALYSIS_STATS = 'content_dna_analysis_stats';
export const CONTENT_HEALTH_PROFILE = 'content_health_profile';
export const DASHBOARD = 'dashboard';
export const DASHBOARD_CONTENTS = 'dashboard_contents';
export const DASHBOARD_POINT = 'dashboard_point';
export const CONTENT_HOSPITAL_EXAMINATION = 'content_hospital_examination';
export const HOSPITAL_EXAMINATION_RECORD = 'hospital_examination_record';
export const SUPPORT_VERSION = 'support_version';
export const CLINICALTRIAL_COUNSELING = 'clinicalTrial_counseling';
export const IP_ALLOW = 'ip_allow';
export const GENE_RESEARCH = 'geneResearch';
export const DOCTORPROFILE = 'doctorProfile';
export const GET_USER_INFO_FOR_SEARCH = 'get_user_info_for_search';
export const MANAGE_REMOTE_CONFIG = 'manage_remote_config';
export const CONTENT_RSS_FEED = 'content_rss_feed';
export const TOPIC_TAG = 'topic_tag';
export const TRANSLATE_WORD = 'translate_word';
export const ENTRY_MODAL = 'entry_modal';
export const PAP = 'pap';
export const MEDIA_MONITOR = 'media_monitor';
