import React, { useEffect, useState, useRef, useReducer } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import {
  Spin,
  Form,
  Input,
  Select,
  Button,
  Popover,
  Alert,
  Row,
  Col,
} from 'antd';
import 'antd/dist/antd.css';
import LoadingOverlay from 'react-loading-overlay';
import WindowHeader from '../../components/windowHeader';
import {
  fetchRemoteConfig,
  postRemoteConfig,
  patchRemoteConfig,
  deleteRemoteConfig,
} from '../../../services/remoteConfigService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import ElementLoading from '../../components/elementLoading';
import { ALERT_MESSAGES } from '../../../assets/alert';

import { treatmentNewsCreators } from '../../../store/reducers/treatmentNews.reducer';
import { noticeCreators } from '../../../store/reducers/notice.reducer';
import { dnaCreators } from '../../../store/reducers/dna.reducer';
import AntTinymceInput from '../../components/antTinymceInput';
import MoveAppUrl from '../../components/moveAppUrl';
import { papProjectCreators } from '../../../store/reducers/papProject.reducer';

const popoverContent = (
  <div>
    <h5>
      <span style={{ color: 'blue' }}>역할명</span>_
      <span style={{ color: 'green' }}>value</span>의 형태
    </h5>
    역할명과 밸류의 구분은 언더바로 진행
    <br />
    <br />
    예1) 추천 게시글 타이틀 변경
    <br />
    <span style={{ color: 'blue' }}>recommendedPost</span>_
    <span style={{ color: 'green' }}>title</span>
    <br />
    <br />
    예2) ‘회비는 어디에 사용되나요?’ 콘텐츠 연결
    <br />
    <span style={{ color: 'blue' }}>patientGroupFundUsage</span>_
    <span style={{ color: 'green' }}>contentLink</span>
    <br />
    <br />
    예3) ‘환우회 등록 문의하기’ 구글폼 연결
    <br />
    <span style={{ color: 'blue' }}>patientGroupRegistrationInquiry</span>_
    <span style={{ color: 'green' }}>googleformLink</span>
  </div>
);

const RemoteConfigWindow = () => {
  const dispatch = useDispatch();
  const { treatmentNews, notices, dnas, rssFeed, pap } = useSelector(
    (state) => {
      return {
        treatmentNews: state.treatmentNewsReducer.treatmentNews.data?.map(
          (treatment) => ({
            ...treatment,
            key: treatment?.id,
          }),
        ),
        notices: state.noticeReducer.notices.data,
        dnas: state.dnaReducer.dna?.data,
        rssFeed: state.rssFeedReducer?.rssFeed?.data,
        pap: state.papProjectReducer?.papProject?.data?.data,
      };
    },
    shallowEqual,
  );

  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const didCancel = useRef(false);
  const [remoteConfigId, setRemoteConfigId] = useState(null);
  const [remoteConfigKey, setRemoteConfigKey] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [form] = Form.useForm();
  const [imageLoading, setImageLoading] = useState(false);

  const initialState = {
    key: '',
    description: '',
    dataType: 'string',
    value: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);

  const postPatchData = {
    key: state.key,
    description: state.description,
    dataType: state.dataType,
    value: state.value,
  };

  const { data: fetchRemoteConfigData, call: fetchRemoteConfigInfo } = useFetch(
    null,
    fetchRemoteConfig,
    remoteConfigKey,
  );

  const { mutate: putRemoteConfig, done: isPosted } = useMutate(
    postRemoteConfig,
    postPatchData,
  );
  const { mutate: changeRemoteConfig, done: isPatched } = useMutate(
    patchRemoteConfig,
    remoteConfigId,
    postPatchData,
  );
  const { mutate: eraseRemoteConfig, done: isDeleted } = useMutate(
    deleteRemoteConfig,
    remoteConfigId,
  );

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    dispatch(
      treatmentNewsCreators.fetchAllTreatmentNews.request({
        params: '',
      }),
    );
    dispatch(dnaCreators.fetchAllDnas.request());
    dispatch(noticeCreators.fetchNotices.request());
    dispatch(papProjectCreators.fetchAllPapProjects.request());
    if (params.id && params.subId) {
      setRemoteConfigId(params.id);
      setRemoteConfigKey(params.subId);
    } else {
      setRemoteConfigId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [dispatch, search, windowId]);

  useEffect(() => {
    if (remoteConfigId && remoteConfigKey) {
      fetchRemoteConfigInfo();
    }
  }, [remoteConfigId, remoteConfigKey, fetchRemoteConfigInfo]);

  useEffect(() => {
    if (
      fetchRemoteConfigData &&
      treatmentNews &&
      notices &&
      dnas &&
      !didCancel.current
    ) {
      setState({
        key: fetchRemoteConfigData?.[remoteConfigKey]?.key,
        description: fetchRemoteConfigData?.[remoteConfigKey]?.description,
        dataType: fetchRemoteConfigData?.[remoteConfigKey]?.dataType,
        value: fetchRemoteConfigData?.[remoteConfigKey]?.value,
      });
      didCancel.current = true;
      setIsLoading(false);
    } else if (
      remoteConfigId === '' &&
      treatmentNews &&
      notices &&
      dnas &&
      !didCancel.current
    ) {
      setIsLoading(false);
      didCancel.current = true;
    }
  }, [fetchRemoteConfigData, treatmentNews, notices, dnas, remoteConfigId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      key: values.key,
      description: values.description,
      dataType: values.dataType,
      value: values.dataType === 'html' ? state.value : values.value,
    });
    if (remoteConfigId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseRemoteConfig();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeRemoteConfig();
      setIsPatch(false);
    }
    if (isPost) {
      putRemoteConfig();
      setIsPost(false);
    }
  }, [changeRemoteConfig, isPatch, isPost, putRemoteConfig]);

  if (isLoading) return <ElementLoading type="리모트 컨피그" />;
  return (
    <>
      <WindowHeader title="리모트 컨피그" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            form={form}
            initialValues={{
              remember: true,
              key: state.key,
              description: state.description,
              dataType: state.dataType,
              value: state.value,
            }}
          >
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Popover content={popoverContent} trigger="click">
                <Button type="primary">Key 네이밍 규칙</Button>
              </Popover>
            </Form.Item>
            <Form.Item
              label="키"
              name="key"
              rules={[
                {
                  required: true,
                  message: '키를 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="키값을 입력해주세요" />
            </Form.Item>
            <Form.Item
              name="dataType"
              label="데이터 타입"
              rules={[
                { required: true, message: '데이터 타입을 확인해주세요.' },
              ]}
            >
              <Select
                onChange={(value) => {
                  setState({
                    dataType: value,
                    value: '',
                  });
                  form.setFieldsValue({ value: null });
                }}
                placeholder="데이터 타입을 선택해주세요"
              >
                <Select.Option value="string">string</Select.Option>
                <Select.Option value="boolean">boolean</Select.Option>
                <Select.Option value="html">html</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label="설명"
              name="description"
              rules={[
                {
                  required: true,
                  message: '설명을 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="설명을 입력해주세요" />
            </Form.Item>
            {state.dataType === 'string' && (
              <Form.Item
                label="값"
                name="value"
                rules={[
                  {
                    required: true,
                    message: '값을 확인해주세요.',
                  },
                ]}
              >
                <Input placeholder="값을 입력해주세요" />
              </Form.Item>
            )}
            {state.dataType === 'boolean' && (
              <Form.Item
                label="값"
                name="value"
                rules={[{ required: true, message: '값을 확인해주세요.' }]}
              >
                <Select
                  onChange={(value) =>
                    setState({
                      value,
                    })
                  }
                  placeholder="값을 선택해주세요"
                >
                  <Select.Option value="true">true</Select.Option>
                  <Select.Option value="false">false</Select.Option>
                </Select>
              </Form.Item>
            )}
            {state.dataType === 'html' && (
              <>
                <MoveAppUrl
                  type="contents"
                  treatmentNews={treatmentNews}
                  notices={notices}
                  dnas={dnas}
                  rssFeed={rssFeed}
                  pap={pap}
                />
                <Form.Item label="값" name="value" className="treatmentContent">
                  <LoadingOverlay
                    active={imageLoading}
                    spinner={<Spin />}
                    text={<p>이미지를 업로드 하는 중</p>}
                  >
                    <AntTinymceInput
                      content={state.value}
                      setContent={(e) => {
                        setState({ value: e });
                      }}
                      setImageLoading={setImageLoading}
                      onBlur={(e) => {}}
                      contentLinkData={{ setState }}
                      isAntTinymceInit={false}
                    />
                  </LoadingOverlay>
                </Form.Item>
              </>
            )}

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {remoteConfigId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default RemoteConfigWindow;
