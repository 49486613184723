import React, { useEffect, useState, useReducer, useCallback } from 'react';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Input, Radio, Select, Button, Alert, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import WindowHeader from '../../components/windowHeader';

import { patchTreatmentNews } from '../../../services/treatmentNewsService';

import { useMutate } from '../../../hooks/useRequest';
import ElementLoading from '../../components/elementLoading';
import { ALERT_MESSAGES } from '../../../assets/alert';

const ContentsReservationWindow = () => {
  const dispatch = useDispatch();

  const { search } = useLocation();
  const { Option } = Select;
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [contentsReservationId, setContentsReservation] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isPatch, setIsPatch] = useState(false);

  const initialState = {
    reservationTime: '',
    isReservation: false,
    reservationYear: null,
    reservationMonth: null,
    reservationDay: null,
    reservationHour: null,
    reservationMinute: null,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);

  const postPatchData = {
    reservationTime: state.reservationTime,
  };

  const { mutate: changeContentsReservation, done: isPatched } = useMutate(
    patchTreatmentNews,
    contentsReservationId,
    postPatchData,
  );

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id);
    if (params.id) {
      setContentsReservation(params.id);
      const paramData = JSON.parse(params.subId);
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (contentsReservationId) {
      setIsLoading(false);
    }
  }, [contentsReservationId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const message = ALERT_MESSAGES.UPDATE;
    if (isPatched) {
      alert(message);
      window.close();
    }
  }, [isPatched]);

  const numberOptions = (number) => {
    const result = [];
    for (let i = 1; i <= number; i++) {
      result.push(
        <Option key={i} id={i} value={i}>
          {i}
        </Option>,
      );
    }
    return result;
  };

  const onReservationChange = (e) => {
    setState({ isReservation: e.target.value });
  };

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      reservationTime: values.reservationTime,
      reservationYear: values.reservationYear,
      reservationMonth: values.reservationMonth,
      reservationDay: values.reservationDay,
      reservationHour: values.reservationHour,
      reservationMinute: values.reservationMinute,
    });
    if (contentsReservationId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeContentsReservation();
      setIsPatch(false);
    }
  }, [changeContentsReservation, isPatch]);

  if (isLoading) return <ElementLoading type="콘텐츠 앱 노출" />;
  return (
    <>
      <WindowHeader title="콘텐츠 앱 노출" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              isReservation: state.isReservation,
              reservationTime: state.reservationTime,
              reservationYear: state.reservationYear,
              reservationMonth: state.reservationMonth,
              reservationDay: state.reservationDay,
              reservationHour: state.reservationHour,
              reservationMinute: state.reservationMinute,
            }}
          >
            <Form.Item label="앱 노출 예약">
              <Radio.Group
                onChange={(e) => {
                  setState({ isReservation: e.target.value });
                }}
                value={state.isReservation}
              >
                <Radio value={true}>예약 설정</Radio>
                <Radio value={false}>예약 삭제</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item label="시간 설정">
              <div style={{ display: 'flex' }}>
                <div style={{ width: 120, marginLeft: 20 }}>
                  <Form.Item label="년" name="reservationYear">
                    <Select
                      allowClear
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="year"
                      onChange={(e) => setState({ reservationYear: e })}
                    >
                      <Option key="2023" id="2023" value="2023">
                        2023
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ width: 120, marginLeft: 20 }}>
                  <Form.Item label="월" name="reservationMonth">
                    <Select
                      allowClear
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="month"
                      onChange={(e) => setState({ reservationMonth: e })}
                    >
                      {numberOptions(12)}
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ width: 120, marginLeft: 20 }}>
                  <Form.Item label="일" name="reservationDay">
                    <Select
                      allowClear
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="day"
                      onChange={(e) => setState({ reservationDay: e })}
                    >
                      {numberOptions(31)}
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ width: 120, marginLeft: 20 }}>
                  <Form.Item label="시" name="reservationHour">
                    <Select
                      allowClear
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="day"
                      onChange={(e) => setState({ reservationHour: e })}
                    >
                      {numberOptions(24)}
                    </Select>
                  </Form.Item>
                </div>
                <div style={{ width: 120, marginLeft: 20 }}>
                  <Form.Item label="분" name="reservationMinute">
                    <Select
                      allowClear
                      showSearch
                      style={{ width: '100%' }}
                      placeholder="day"
                      onChange={(e) => setState({ reservationMinute: e })}
                    >
                      <Option key={0} id={0} value={0}>
                        {0}
                      </Option>
                      <Option key={30} id={30} value={30}>
                        {30}
                      </Option>
                    </Select>
                  </Form.Item>
                </div>
              </div>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default ContentsReservationWindow;
