export const HEALTH_PROFILE_GROUP_STATUS = {
  NOT_STARTED: '미참여',
  IN_PROGRESS: '진행중',
  COMPLETED: '완료',
};

export const HEALTH_PROFILE_GROUP_GENDER = {
  male: '남성',
  female: '여성',
};
