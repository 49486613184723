import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Input, List, notification, Row, Spin } from 'antd';
import moment from 'moment';
import LoadingOverlay from 'react-loading-overlay';
import { parse } from 'qs';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import WindowHeader from '../../components/windowHeader';
import AntTinymceInput from '../../components/antTinymceInput';
import { useFetch } from '../../../hooks/useRequest';
import {
  createPapTerm,
  deletePapTermVersion,
  fetchPapTerm,
  updatePapTerm,
} from '../../../services/papService';
import ElementLoading from '../../components/elementLoading';

const PapTermWindow = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [windowId, setWindowId] = useState('');
  const [termId, setTermId] = useState(null);
  const [versionId, setVersionId] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [form] = Form.useForm();
  const initialState = {
    title: '',
    description: '',
  };

  const { data: PapTermData, call: fetchPapTermData } = useFetch(
    null,
    fetchPapTerm,
    termId,
    versionId,
  );

  const [termsToDelete, setTermsToDelete] = useState([]);

  const { search } = useLocation();

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setTermId(params.id);
    }
    if (params.versionId) {
      setVersionId(params.versionId);
    }
    setIsLoading(false);
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (termId && versionId) {
      fetchPapTermData();
    }
  }, [fetchPapTermData, termId, versionId]);

  useEffect(() => {
    if (PapTermData) {
      form.setFieldsValue({
        ...PapTermData,
      });
      setDescription(PapTermData.description);
    }
  }, [PapTermData, form]);

  const handleSubmit = async (values) => {
    const { isNok: isNokStr, new: isNew } = parse(search, {
      ignoreQueryPrefix: true,
    });
    const isNok = isNokStr === 'true';
    const REQ_BODY = {
      ...values,
    };

    if (
      window.confirm(
        `저장하시겠습니까?${
          isNew
            ? ''
            : '\n약관 변경으로 인해 기존 약관에 동의한 유저들은 재동의가 필요합니다.'
        }${
          termsToDelete.length > 0
            ? `\n${termsToDelete.length}개의 이전 버전 약관이 삭제됩니다.`
            : ''
        }`,
      )
    ) {
      if (termId) {
        // patch
        const result = await updatePapTerm(termId, REQ_BODY);
        await Promise.all(
          termsToDelete.map((version) => deletePapTermVersion(termId, version)),
        );
        window?.opener?.postMessage(
          `${isNok ? 'nokAgreement' : 'agreement'} ${
            result.termId
          } ${JSON.stringify(result)}`,
          '/',
        );
      } else {
        // post
        REQ_BODY.type = isNok ? 'nok' : 'patient';
        const result = await createPapTerm(REQ_BODY);
        window?.opener?.postMessage(
          `${isNok ? 'nokAgreement' : 'agreement'} ${
            result.termId
          } ${JSON.stringify(result)}`,
          '/',
        );
      }
      alert('저장되었습니다.');
      window.close();
    }
  };

  const fetchPreviousVersion = async (version) => {
    if (
      window.confirm(
        '이전 버전 약관 내용을 가져올까요?\n이전에 작성중이던 내용은 지워집니다.',
      )
    ) {
      const result = await fetchPapTerm(termId, version);
      form.setFieldsValue({
        title: result.title,
        description: result.description,
      });
      notification.success({
        key: 'fetchSuccess',
        message: '이전 버전 약관 내용을 불러왔습니다.',
      });
      setDescription(result.description);
    }
  };

  const deletePreviousVersion = async (version) => {
    if (window.confirm('이전 버전 약관을 삭제할까요?')) {
      setTermsToDelete([...termsToDelete, version]);
      notification.success({
        key: 'deleteSuccess',
        message: (
          <>
            삭제되었습니다.
            <br />
            저장하면 최종 적용됩니다.
          </>
        ),
      });
    }
  };

  if (isLoading) return <ElementLoading type="운영 > PAP > 약관 서류" />;
  return (
    <>
      <WindowHeader title="운영 > PAP > 약관 서류" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={(error) => {
              notification.error({
                key: 'onFailed',
                message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
              });
            }}
            initialValues={initialState}
          >
            <Form.Item
              label="약관명"
              name="title"
              rules={[
                {
                  required: true,
                  message: '약관명을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="약관 내용"
              name="description"
              rules={[
                {
                  required: true,
                  message: '약관 내용을 확인해주세요.',
                },
              ]}
              className="treatmentContent"
            >
              <LoadingOverlay
                active={imageLoading}
                spinner={<Spin />}
                text={<p>이미지를 업로드 하는 중</p>}
              >
                <AntTinymceInput
                  content={description}
                  setContent={(value) => {
                    form.setFieldsValue({
                      description: value,
                    });
                    setDescription(value);
                  }}
                  setImageLoading={setImageLoading}
                />
              </LoadingOverlay>
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
            </Form.Item>
            <Form.Item
              label="이전 버전 약관 보기"
              tooltip="이전 버전 약관을 볼 수 있습니다.  불러오기 클릭시 작성중이던 내용은 사라지고 저장되지 않습니다."
            >
              <List
                dataSource={PapTermData?.prevTerms}
                size="small"
                rowKey={(item) => item.id}
                renderItem={(item) => (
                  <List.Item
                    actions={[
                      <Button
                        type="link"
                        size="small"
                        onClick={() => fetchPreviousVersion(item.id)}
                      >
                        불러오기
                      </Button>,
                      <Button
                        type="link"
                        size="small"
                        onClick={() => deletePreviousVersion(item.id)}
                        danger
                        disabled={item.hasAgreement}
                      >
                        삭제
                      </Button>,
                    ]}
                  >
                    <List.Item.Meta
                      title={`${item.title} (${moment(item.updatedAt).format(
                        'YYYY.MM.DD HH:mm',
                      )})`}
                    />
                  </List.Item>
                )}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default PapTermWindow;
