import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useReducer,
} from 'react';
import { Spinner } from 'react-bootstrap';
import { parse } from 'qs';
import { useDispatch } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import {
  Form,
  Input,
  Button,
  Radio,
  Alert,
  Row,
  Col,
  Upload,
  Modal,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';
import TinymceAntInput from '../../components/tinymceAntInput';

import 'antd/dist/antd.css';

import {
  fetchUserHospitalExamination,
  postUserHospitalExamination,
  patchUserHospitalExamination,
  deleteUserHospitalExamination,
} from '../../../services/hospitalExaminationService';
import { useFetch, useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import {
  useDistinctFileUpload,
  useDistinctFileDelete,
} from '../../util/usePrivateDistinctImage';
import * as listMap from '../../../util/listMap';

const UserHospitalExaminationWindow = (props) => {
  const dispatch = useDispatch();
  const { search } = props.location;
  const [windowId, setWindowId] = useState('');
  const [userId, setUserId] = useState('');
  const [hospitalExaminationId, setHospitalExaminationId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [windowClose, setWindowClose] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const [distinctImageToDelete, setDistinctImageToDelete] = useState(null);
  const [imageLoading, setImageLoading] = useState(false);
  const [isCanceled, setIsCanceled] = useState(false);
  const [imageList, setImageList] = useState([]);
  const [imageNumber, setImageNumber] = useState(false);
  const [count, setCount] = useState(0);
  const [btnLoadings, setBtnLoadings] = useState(false);

  const contentChange = useRef(false);
  const didCancel = useRef(false);
  const fileUploadReady = useRef(false);
  const removeDistinctImageByButton = useRef(false);
  const deleteDone = useRef(false);
  const isDeleteImageExist = useRef(null);
  const distinctImageType = useRef(null);

  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const dateFormat = 'YYYY/MM/DD';

  const initialState = {
    dateAt: null,
    institution: null,
    description: null,
    fileNames: [],
    previewVisible: false,
    previewImage: '',
    previewTitle: '',
    documentType: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);

  const splittedPhotoUrl = (splittedUrl) => {
    return (
      splittedUrl &&
      `${splittedUrl[splittedUrl.length - 2]}/${
        splittedUrl[splittedUrl.length - 1].split('?')[0]
      }`
    );
  };

  const postPatchData = {
    institution: state.institution,
    reportedAt: state.dateAt === '' ? null : state.dateAt,
    description: state.description,
    fileNames: imageList.map((image) =>
      splittedPhotoUrl(image?.url?.split('/')),
    ),
    documentType: state.documentType,
  };

  const {
    data: fetchUserHospitalExaminationData,
    call: callHospitalExamination,
  } = useFetch(
    null,
    fetchUserHospitalExamination,
    userId,
    hospitalExaminationId,
  );

  const { mutate: putHospitalExamination, done: isPosted } = useMutate(
    postUserHospitalExamination,
    userId,
    postPatchData,
  );

  const { mutate: changeHospitalExamination, done: isPatched } = useMutate(
    patchUserHospitalExamination,
    userId,
    hospitalExaminationId,
    postPatchData,
  );

  const { mutate: eraseHospitalExamination, done: isDeleted } = useMutate(
    deleteUserHospitalExamination,
    userId,
    hospitalExaminationId,
  );

  const {
    privateUrl: distinctPrivateUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useDistinctFileUpload(distinctFileInputEvent);

  const {
    done: distinctImageDeleteDone,
    imageDelete: distinctImageDelete,
    initialize: distinctDeleteInitialize,
  } = useDistinctFileDelete(distinctImageToDelete);

  const message = isPosted
    ? ALERT_MESSAGES.CREATE
    : isPatched
    ? ALERT_MESSAGES.UPDATE
    : isDeleted
    ? ALERT_MESSAGES.DELETE
    : '';

  const isMutated = isPosted || isPatched || isDeleted;
  const windowCloseCondition = windowClose && distinctImageDeleteDone;

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };
  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    const ids = params?.id?.split('/');
    if (ids[1]) {
      setUserId(ids[0]);
      setHospitalExaminationId(ids[1]);
    } else {
      setUserId(ids[0]);
      setHospitalExaminationId('');
      if (params?.subId) {
        const addUrls = JSON.parse(params.subId).map((photo) => {
          return {
            ...photo,
            url: photo.signedUrl,
          };
        });
        setImageList(addUrls);
        setImageNumber(addUrls.length);
      }
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    if (hospitalExaminationId) callHospitalExamination();
  }, [hospitalExaminationId, callHospitalExamination]);

  useEffect(() => {
    if (fetchUserHospitalExaminationData && !didCancel.current) {
      const addUrls = fetchUserHospitalExaminationData.hospitalExaminationRecord?.photos.map(
        (photo) => {
          return {
            ...photo,
            url: photo.signedUrl,
          };
        },
      );
      setImageList(addUrls);
      setImageNumber(addUrls.length);
      setState({
        dateAt:
          fetchUserHospitalExaminationData.hospitalExaminationRecord
            ?.reportedAt,
        institution:
          fetchUserHospitalExaminationData.hospitalExaminationRecord
            ?.institution,
        description:
          fetchUserHospitalExaminationData.hospitalExaminationRecord
            ?.description,
        documentType:
          fetchUserHospitalExaminationData.hospitalExaminationRecord
            ?.documentType === null
            ? ''
            : fetchUserHospitalExaminationData.hospitalExaminationRecord
                ?.documentType,
      });
      didCancel.current = true;
      setIsLoading(false);
    } else if (hospitalExaminationId === '' && !didCancel.current) {
      didCancel.current = true;
      setIsLoading(false);
    }
  }, [fetchUserHospitalExaminationData, hospitalExaminationId]);

  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const handleCancel = () => setState({ previewVisible: false });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    setState({
      previewImage: file.originFileObj ? file.thumbUrl : file.url,
      previewVisible: true,
      previewTitle:
        file.name ||
        file.url.substring(
          file.url.lastIndexOf('com/') + 4,
          file.url.lastIndexOf('com/') + 14,
        ),
    });
  };

  const handleChange = ({ fileList }) => {
    setCount(count + 1);
    const differenceLength = fileList?.length - imageList?.length;

    if (differenceLength <= count && count === 1) {
      setImageLoading(true);
      setDistinctFileInputEvent(fileList?.slice(imageNumber));
      fileUploadReady.current = true;
    }
    if (differenceLength < 0) {
      setCount(0);
      setImageNumber(fileList?.length);
    }
    setImageList(fileList);
  };

  const uploadImage = async (options) => {
    const { onSuccess } = options;
    onSuccess('Ok');
  };

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const uploadProps = {
    customRequest: uploadImage,
    onChange: handleChange,
    onPreview: handlePreview,
    multiple: true,
  };

  useEffect(() => {
    if (distinctFileInputEvent?.length > 0 && fileUploadReady.current) {
      fileUploadReady.current = false;
      distinctImageUpload();
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (
      distinctImageToDelete?.length > 0 &&
      !distinctImageDeleteDone &&
      !deleteDone.current
    ) {
      distinctImageDelete();
      setState({ [distinctImageType.current]: null });
      setWindowClose(true);
      deleteDone.current = true;
      distinctImageType.current = null;
    }
  }, [distinctImageDelete, distinctImageDeleteDone, distinctImageToDelete]);

  const closeWindowAfterAlert = useCallback(() => {
    if (isMutated || isCanceled) {
      setWindowClose(false);
      setDistinctImageToDelete(null);
      if (isMutated) alert(message);
      setBtnLoadings(false);
      window.close();
    }
  }, [isMutated, isCanceled, message]);

  useEffect(() => {
    if (!isDeleteImageExist.current || windowCloseCondition) {
      closeWindowAfterAlert();
    }
  }, [windowCloseCondition, closeWindowAfterAlert]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPrivateUrl) {
      setImageLoading(false);
      const photoSelected = imageList.map((image, i) => {
        if (i >= imageNumber) {
          return {
            ...image,
            url: distinctPrivateUrl[i - imageNumber].publicUrl,
          };
        }
        return {
          ...image,
        };
      });
      setCount(0);
      setImageNumber(photoSelected.length);
      setImageList(photoSelected);
      distinctUploadInitialize();
      distinctImageType.current = null;
    }
  }, [distinctImageUploadDone, distinctPrivateUrl]);

  useEffect(() => {
    if (distinctImageDeleteDone && removeDistinctImageByButton.current) {
      distinctDeleteInitialize();
      removeDistinctImageByButton.current = false;
      setDistinctImageToDelete(null);
      deleteDone.current = false;
    }
  }, [distinctDeleteInitialize, distinctImageDeleteDone]);

  const changeState = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
    contentChange.current = true;
  }, []);

  const changeOption = useCallback((e) => {
    setState({ [e.target.name]: e.target.value });
  }, []);

  const onFinish = (values) => {
    if (
      (state.description === null || state.description === '') &&
      imageList.length === 0
    ) {
      setBtnLoadings(false);
      return setShowAlert(true);
    }

    setShowAlert(false);
    setState({
      institution: values.institution,
      documentType: values.documentType,
      dateAt: values.reportedAt
        ? moment(values.reportedAt).format(dateFormat)
        : null,
    });
    if (hospitalExaminationId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
        setBtnLoadings(true);
      } else {
        setBtnLoadings(false);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
      setBtnLoadings(true);
    } else {
      setBtnLoadings(false);
    }
  };

  const onFinishFailed = () => {
    setBtnLoadings(false);
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseHospitalExamination();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeHospitalExamination();
      setIsPatch(false);
    }
    if (isPost) {
      putHospitalExamination();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  if (isLoading) return <ElementLoading type="병원 검사" />;
  return (
    <LoadingOverlay
      active={imageLoading}
      spinner={<Spinner animation="border" variant="info" />}
      text={<p>이미지를 업로드 하는 중</p>}
    >
      <WindowHeader title="병원 검사" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              institution: state.institution,
              documentType: state.documentType,
              reportedAt: state.dateAt
                ? moment(state.dateAt).format('YYYYMMDD')
                : '',
            }}
          >
            <Form.Item
              label="일시"
              name="reportedAt"
              rules={[
                {
                  pattern: new RegExp(/^[0-9]{8}$/),
                  message: '8자리의 숫자만 입력해주세요.(ex: 20220701)',
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="병원명"
              name="institution"
              rules={[
                {
                  required: true,
                  message: '병원명을 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <TinymceAntInput
              content={state.description}
              setContent={changeState}
              setImageLoading={setImageLoading}
              windowId={hospitalExaminationId}
              isEssential={false}
              name="description"
            />
            <Form.Item label="이미지">
              <Upload
                {...uploadProps}
                listType="picture-card"
                fileList={imageList}
              >
                {imageList?.length >= 30 ? null : uploadButton}
              </Upload>
              <Modal
                visible={state.previewVisible}
                title={state.previewTitle}
                footer={null}
                onCancel={handleCancel}
              >
                <img
                  alt="example"
                  style={{ width: '100%' }}
                  src={state.previewImage}
                />
              </Modal>
            </Form.Item>
            <Form.Item label="서류 종류" name="documentType">
              <Row>
                <Col span={24}>
                  <div style={{ marginTop: 6 }}>진단서</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeOption}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.diagnosisOptions}
                  />
                  <div style={{ marginTop: 6 }}>소견서</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeOption}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.opinionOptions}
                  />
                  <div style={{ marginTop: 6 }}>입퇴원 관련 서류</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeOption}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.hospitalizationOptions}
                  />
                  <div style={{ marginTop: 6 }}>진료 관련 서류</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeOption}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.treatmentOptions}
                  />
                  <div style={{ marginTop: 6 }}>처방전</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeOption}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.prescriptionOptions}
                  />
                  <div style={{ marginTop: 6 }}>기타 서류</div>
                  <Radio.Group
                    name="documentType"
                    onChange={changeOption}
                    value={state.documentType}
                    optionType="button"
                    buttonStyle="solid"
                    style={{ marginBottom: 8 }}
                    options={listMap.etcOptions}
                  />
                </Col>
              </Row>
            </Form.Item>

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 100 }}
                loading={btnLoadings}
                onClick={() => setBtnLoadings(true)}
              >
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {hospitalExaminationId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요. 내용 또는 이미지를 입력해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </LoadingOverlay>
  );
};

export default UserHospitalExaminationWindow;
