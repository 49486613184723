import { Switch, Table } from 'antd';
import React from 'react';

import 'antd/dist/antd.css';

const AntList = ({
  deployChangeInfo,
  setDeployChangeInfo,
  pageUpdate,
  setPageUpdate,
  columns,
  dataSource,
  onChange,
  loading = false,
  pagination,
  defaultPageSize = 20,
}) => {
  const contentList = columns?.map((column) =>
    column?.key === 'isApp' ||
    column?.key === 'isNew' ||
    column?.key === 'isInfo'
      ? {
          ...column,
          render: (text, record) => (
            <DeployButton item={record} deployOption={column?.key} />
          ),
        }
      : { ...column },
  );

  const visualDistribute = ({ targetItem, deployOption }) => {
    const target = deployChangeInfo.filter(
      (changedInfo) => changedInfo.id === targetItem.id,
    );
    const selected =
      target.length > 0 && deployOption in target[0]
        ? Boolean(target[0]?.[deployOption])
        : Boolean(targetItem?.[deployOption]);
    return selected;
  };

  const update = ({ id, which, toWhat }) => {
    let isIdExist = false;
    const newDeployChangeInfo = deployChangeInfo
      .map((changedInfo) => {
        if (changedInfo.id === id) {
          isIdExist = true;
          changedInfo[which] = toWhat;
          const listItem = dataSource.find((data) => data.id === id);
          if (listItem[which] === toWhat) {
            delete changedInfo[which];
          }
          if (Object.keys(changedInfo).length === 1) {
            return null;
          }
        }
        return changedInfo;
      })
      .filter(Boolean);

    if (!isIdExist) {
      newDeployChangeInfo.push({ id, [which]: toWhat });
    }
    setDeployChangeInfo(newDeployChangeInfo);
  };

  const DeployButton = ({ item, deployOption }) => {
    return (
      <Switch
        defaultChecked={visualDistribute({
          targetItem: item,
          deployOption,
        })}
        size="small"
        onClick={() =>
          update({
            id: item.id,
            which: deployOption,
            toWhat:
              deployChangeInfo.find(
                (changedInfo) => changedInfo.id === item.id,
              )?.[deployOption] !== undefined
                ? !deployChangeInfo.find(
                    (changedInfo) => changedInfo.id === item.id,
                  )[deployOption]
                : !item[deployOption],
          })
        }
      />
    );
  };

  return (
    <Table
      rowKey={(record) => record.id}
      columns={contentList}
      dataSource={dataSource}
      pagination={{
        onChange: (page) => {
          setPageUpdate(page);
        },
        current: pageUpdate,
        defaultPageSize,
        pageSizeOptions: [10, 20, 50, 100, 1000, 10000],
        ...pagination,
      }}
      size="small"
      loading={loading}
      onChange={onChange}
      bordered
    />
  );
};

export default AntList;
