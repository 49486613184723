import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Space } from 'antd';
import React from 'react';

const MultiRowInput = ({
  fieldName,
  placeholder,
  addButtonLabel,
  form,
  inputWidth,
}) => (
  <Form.List
    name={fieldName}
    grid={{
      gutter: 16,
    }}
  >
    {(fields, { add, remove }) => (
      <>
        {fields.map(({ key: listKey, name, fieldKey, ...restField }) => (
          <Space key={listKey} align="baseline" style={{ marginRight: 10 }}>
            <Form.Item
              {...restField}
              name={name}
              fieldKey={fieldKey}
              rules={[
                {
                  required: true,
                  message: placeholder,
                },
              ]}
              className="multiRowInput"
            >
              <Input placeholder={placeholder} style={{ width: inputWidth }} />
            </Form.Item>
            <MinusCircleOutlined onClick={() => remove(name)} />
          </Space>
        ))}
        <Form.Item className="multiRowInput">
          <Button
            type="dashed"
            onClick={() => add()}
            block
            icon={<PlusOutlined />}
          >
            {addButtonLabel}
          </Button>
        </Form.Item>
      </>
    )}
  </Form.List>
);

export default MultiRowInput;
