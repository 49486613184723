import { all, takeLatest } from 'redux-saga/effects';
import {
  healthProfileActions,
  healthProfileCreators,
} from '../reducers/healthProfile.reducer';
import { createAsyncSaga } from '../reducer.util';
import {
  fetchAllHealthProfileV4,
  fetchHealthProfileUser,
  fetchAllHealthProfileQuestion,
  fetchHealthProfileBasicQuestionAnswer,
} from '../../services/healthProfileService';

const handleFetchAllHealthProfile = createAsyncSaga(
  healthProfileCreators.fetchAllHealthProfiles,
  fetchAllHealthProfileV4,
);

const handleFetchHealthProfileUser = createAsyncSaga(
  healthProfileCreators.fetchHealthProfileUsers,
  fetchHealthProfileUser,
);

const handleFetchAllHealthProfileQuestion = createAsyncSaga(
  healthProfileCreators.fetchAllHealthProfileQuestions,
  fetchAllHealthProfileQuestion,
);
const handleFetchHealthProfileBasicQuestionAnswer = createAsyncSaga(
  healthProfileCreators.fetchHealthProfileBasicQuestionAnswer,
  fetchHealthProfileBasicQuestionAnswer,
);
export function* healthProfileSaga() {
  yield all([
    takeLatest(
      healthProfileActions.FETCH_ALL_HEALTH_PROFILES,
      handleFetchAllHealthProfile,
    ),
    takeLatest(
      healthProfileActions.FETCH_HEALTH_PROFILE_USERS,
      handleFetchHealthProfileUser,
    ),
    takeLatest(
      healthProfileActions.FETCH_ALL_HEALTH_PROFILE_QUESTIONS,
      handleFetchAllHealthProfileQuestion,
    ),
    takeLatest(
      healthProfileActions.FETCH_HEALTH_PROFILE_BASIC_QUESTION_ANSWER,
      handleFetchHealthProfileBasicQuestionAnswer,
    ),
  ]);
}
