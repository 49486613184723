import React, { useEffect, useState, useReducer } from 'react';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { Form, Input, Button, Alert, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import WindowHeader from '../../components/windowHeader';
import { useMutate } from '../../../hooks/useRequest';
import ElementLoading from '../../components/elementLoading';
import { ALERT_MESSAGES } from '../../../assets/alert';
import {
  patchTranslateWord,
  postTranslateWord,
} from '../../../services/translateWordService';

const TranslateWordWindow = () => {
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [translateWordId, setTranslateWordId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const initialState = {
    krWord: '',
    enWord: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);

  const postPatchData = {
    krWord: state.krWord,
    enWord: state.enWord,
  };
  const { mutate: putTranslateWord, done: isPosted } = useMutate(
    postTranslateWord,
    postPatchData,
  );
  const { mutate: changeTranslateWord, done: isPatched } = useMutate(
    patchTranslateWord,
    translateWordId,
    postPatchData,
  );

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setTranslateWordId(params.id);
      const paramData = JSON.parse(params.subId);
      setState({
        krWord: paramData?.krWord,
        enWord: paramData?.enWord,
      });
      setIsLoading(false);
    } else {
      setTranslateWordId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (translateWordId) {
      setIsLoading(false);
    }
  }, [translateWordId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isPosted || isPatched) {
      alert(message);
      window.close();
    }
  }, [isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      krWord: values.krWord,
      enWord: values.enWord,
    });
    if (translateWordId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  useEffect(() => {
    if (isPatch) {
      changeTranslateWord();
      setIsPatch(false);
    }
    if (isPost) {
      putTranslateWord();
      setIsPost(false);
    }
  }, [changeTranslateWord, isPatch, isPost, putTranslateWord]);

  if (isLoading)
    return (
      <ElementLoading
        type={`번역 대치어 ${translateWordId ? '수정' : '추가'}`}
      />
    );
  return (
    <>
      <WindowHeader
        title={`번역 대치어 ${translateWordId ? '수정' : '추가'}`}
      />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              krWord: state.krWord,
              enWord: state.enWord,
            }}
          >
            <Form.Item
              label="한글"
              name="krWord"
              rules={[
                {
                  required: true,
                  message: '한글 번역 대치어를 입력해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="영문"
              name="enWord"
              rules={[
                {
                  required: true,
                  message: '한글 번역 대치어를 입력해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ float: 'right' }}
              >
                번역 대치어 {translateWordId ? '수정' : '추가'}
              </Button>
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default TranslateWordWindow;
