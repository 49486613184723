// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { listFactory } from '../operations/list';
import { convertItemToConversationMessage } from './convertItemToConversationMessage';
import { AiAction, getCustomUserAgentDetails, } from './getCustomUserAgentDetails';
export const createListMessagesFunction = (client, modelIntrospection, conversationId, conversationMessageModel, getInternals) => async (input) => {
    const list = listFactory(client, modelIntrospection, conversationMessageModel, getInternals, false, getCustomUserAgentDetails(AiAction.ListMessages));
    const { data, nextToken, errors } = await list({
        ...input,
        filter: { conversationId: { eq: conversationId } },
    });
    return {
        data: data.map((item) => convertItemToConversationMessage(item)),
        nextToken,
        errors,
    };
};
