import React, { useState, useEffect, useRef } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  notification,
  Select,
  Divider,
  Skeleton,
} from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import {
  fetchDnaCounselingContent as fetchDnaCounselingContentService,
  patchDnaCounselingContent as patchDnaCounselingContentService,
  deleteDnaCounselingContent as deleteDnaCounselingContentService,
} from '../../../services/dnaCounselingRequestService';
import { useMutate, useFetch } from '../../../hooks/useRequest';
import * as listMap from '../../../util/listMap';
import DisabledInput from '../../components/disabledInput';

const DnaCounselingHistory = (userId) => {
  const [form] = Form.useForm();
  const { TextArea } = Input;
  const [counselingStatus, setCounselingStatus] = useState({});
  const [counselingMemo, setCounselingMemo] = useState({});
  const [
    patchDnaCounselingRequestId,
    setPatchDnaCounselingRequestId,
  ] = useState(null);
  const [
    deleteDnaCounselingRequestId,
    setDeleteDnaCounselingRequestId,
  ] = useState(null);
  const counselingMemoDone = useRef(false);
  const userDataFetchDone = useRef(false);

  const {
    done: fetchCounselingContentDone,
    data: fetchCounselingContentData,
    call: fetchDnaCounselingContent,
  } = useFetch(null, fetchDnaCounselingContentService, userId?.userId);

  useEffect(() => {
    if (!fetchCounselingContentDone && userId && !userDataFetchDone.current) {
      fetchDnaCounselingContent();
      userDataFetchDone.current = true;
    }
  }, [fetchCounselingContentDone, userId, userDataFetchDone]);

  useEffect(() => {
    if (fetchCounselingContentData && !counselingMemoDone.current) {
      const counselingMemoList = {};
      const cousnselingStatusList = {};
      fetchCounselingContentData.userCounselingHistory.forEach(
        (counselingHistory) => {
          counselingMemoList[counselingHistory.id] = counselingHistory.memo;
          cousnselingStatusList[counselingHistory.id] =
            counselingHistory.counselingStatus;
        },
      );
      setCounselingStatus(cousnselingStatusList);
      setCounselingMemo(counselingMemoList);
      counselingMemoDone.current = true;
    }
  }, [fetchCounselingContentData]);

  const {
    mutate: patchDnaCounselingContent,
    done: isCounselingPatched,
    initialize: initializeCounselingPatch,
  } = useMutate(patchDnaCounselingContentService, patchDnaCounselingRequestId, {
    userId: userId?.userId,
    memo: counselingMemo[patchDnaCounselingRequestId],
    counselingStatus: counselingStatus[patchDnaCounselingRequestId],
  });

  const {
    mutate: deleteDnaCounselingContent,
    done: isCounselingDeleted,
    initialize: initializeCounselingDelete,
  } = useMutate(
    deleteDnaCounselingContentService,
    deleteDnaCounselingRequestId,
  );

  useEffect(() => {
    if (isCounselingPatched) {
      fetchDnaCounselingContent();
      initializeCounselingPatch();
      notification.success({
        message: '수정이 완료되었습니다.',
        description: '수정이 완료되었습니다.',
      });
    }
    if (isCounselingDeleted) {
      fetchDnaCounselingContent();
      initializeCounselingDelete();
      notification.success({
        message: '삭제가 완료되었습니다.',
        description: '삭제가 완료되었습니다.',
      });
    }
  }, [
    isCounselingPatched,
    isCounselingDeleted,
    fetchDnaCounselingContent,
    initializeCounselingPatch,
    initializeCounselingDelete,
  ]);

  useEffect(() => {
    if (deleteDnaCounselingRequestId) {
      deleteDnaCounselingContent();
      setDeleteDnaCounselingRequestId(null);
    }
  }, [deleteDnaCounselingRequestId, deleteDnaCounselingContent]);

  useEffect(() => {
    if (patchDnaCounselingRequestId) {
      patchDnaCounselingContent();
      setPatchDnaCounselingRequestId(null);
    }
  }, [patchDnaCounselingRequestId, patchDnaCounselingContent]);

  if (!userDataFetchDone.current) return <Skeleton active />;
  return (
    <>
      {fetchCounselingContentData ? (
        <>
          {fetchCounselingContentData?.userCounselingHistory?.map(
            (counselingContent) => (
              <React.Fragment key={counselingContent.id}>
                <Form name="basic" form={form} {...listMap.layout}>
                  <Row span={24}>
                    <Col span={12}>
                      <DisabledInput
                        label="신청일"
                        type="createdAt"
                        value={
                          counselingContent.createdAt &&
                          moment(counselingContent.createdAt).format(
                            'YYYY.MM.DD',
                          )
                        }
                      />
                      <DisabledInput
                        label="희망일시"
                        type="applicationDate"
                        value={
                          counselingContent.applicationDate &&
                          moment(counselingContent.applicationDate).format(
                            'YYYY.MM.DD',
                          )
                        }
                      />
                    </Col>
                    <Col span={12}>
                      <DisabledInput
                        label="상담질환"
                        type="diseaseType"
                        value={counselingContent.diseaseType}
                      />
                      <DisabledInput
                        label="희망시간"
                        type="applicationTime"
                        value={
                          counselingContent.applicationTime &&
                          `${counselingContent.applicationTime}시 ~ ${
                            counselingContent.applicationTime + 1
                          }시`
                        }
                      />
                    </Col>
                  </Row>
                  {counselingContent.topic && (
                    <Row span={24}>
                      <Col span={24}>
                        <DisabledInput
                          label="상담목적"
                          type="topic"
                          value={counselingContent.topic}
                          size={true}
                        />
                      </Col>
                    </Row>
                  )}
                  <Row span={24}>
                    <Col span={24}>
                      <Form.Item
                        label="상담 내역"
                        labelCol={listMap.largeLayout.labelCol}
                        wrapperCol={listMap.largeLayout.wrapperCol}
                      >
                        <Select
                          showSearch
                          allowClear
                          status={
                            counselingStatus[counselingContent.id] ===
                              'apply' && 'warning'
                          }
                          style={{ width: '100%' }}
                          placeholder="상태를 선택해주세요"
                          disabled={
                            counselingStatus[counselingContent.id] === 'cancel'
                          }
                          value={counselingStatus[counselingContent.id]}
                          onChange={(_, data) => {
                            setCounselingStatus({
                              ...counselingStatus,
                              [counselingContent.id]: data.value,
                            });
                          }}
                        >
                          {listMap.counselingStatusOptions?.map((status) => (
                            <Select.Option
                              key={status?.value}
                              value={status?.value}
                            >
                              {status?.text}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label="상담 내역"
                        labelCol={listMap.largeLayout.labelCol}
                        wrapperCol={listMap.largeLayout.wrapperCol}
                      >
                        <TextArea
                          rows={4}
                          value={counselingMemo[counselingContent.id]}
                          onChange={(e) =>
                            setCounselingMemo({
                              ...counselingMemo,
                              [counselingContent.id]: e.target.value,
                            })
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        wrapperCol={{
                          offset: 4,
                          span: 20,
                        }}
                      >
                        <Button
                          type="primary"
                          htmlType="submit"
                          style={{ width: 100 }}
                          onClick={() =>
                            setPatchDnaCounselingRequestId(counselingContent.id)
                          }
                        >
                          저장
                        </Button>
                        <Button
                          danger
                          htmlType="button"
                          style={{ width: 100, marginLeft: 8 }}
                          onClick={() => {
                            if (
                              window.confirm('신청 내역을 삭제하시겠습니까?')
                            ) {
                              setDeleteDnaCounselingRequestId(
                                counselingContent.id,
                              );
                            }
                          }}
                        >
                          삭제
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
                <Divider plain />
              </React.Fragment>
            ),
          )}
        </>
      ) : (
        <div style={{ marginLeft: 10, color: 'red' }}>
          유전자상담 이력이 없습니다.
        </div>
      )}
    </>
  );
};

export default DnaCounselingHistory;
