import React, { useEffect, useState, useReducer } from 'react';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import { Form, Input, InputNumber, Button, Alert, Row, Col } from 'antd';
import 'antd/dist/antd.css';
import moment from 'moment';
import WindowHeader from '../../components/windowHeader';
import {
  postIpAllow,
  patchIpAllow,
  deleteIpAllow,
} from '../../../services/ipAllowService';
import { useMutate } from '../../../hooks/useRequest';
import ElementLoading from '../../components/elementLoading';
import { ALERT_MESSAGES } from '../../../assets/alert';

const IpAllowWindow = () => {
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [ipAllowId, setIpAllowId] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const today = new Date();
  const initialState = {
    ip: '',
    memo: '',
    expiresAt: '',
    addHour: 1,
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });
  const [state, setState] = useReducer(stateReducer, initialState);

  const postPatchData = {
    ip: state.ip,
    memo: state.memo,
    expiresAt: moment(today).add(state.addHour, 'hours'),
  };
  const { mutate: putIpAllow, done: isPosted } = useMutate(
    postIpAllow,
    postPatchData,
  );
  const { mutate: changeIpAllow, done: isPatched } = useMutate(
    patchIpAllow,
    ipAllowId,
    postPatchData,
  );
  const { mutate: eraseIpAllow, done: isDeleted } = useMutate(
    deleteIpAllow,
    ipAllowId,
  );

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setIpAllowId(params.id);
      const paramData = JSON.parse(params.subId);
      setState({
        ip: paramData.ip,
        memo: paramData.memo,
        expiresAt: paramData.expiresAt,
      });
      setIsLoading(false);
    } else {
      setIpAllowId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (ipAllowId) {
      setIsLoading(false);
    }
  }, [ipAllowId]);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      ip: values.ip,
      memo: values.memo,
      addHour: values.addHour,
    });
    if (ipAllowId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseIpAllow();
    }
  };

  useEffect(() => {
    if (isPatch) {
      changeIpAllow();
      setIsPatch(false);
    }
    if (isPost) {
      putIpAllow();
      setIsPost(false);
    }
  }, [isPatch, isPost]);

  if (isLoading) return <ElementLoading type="개인정보 IP" />;
  return (
    <>
      <WindowHeader title="개인정보 IP" />

      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              ip: state.ip,
              memo: state.memo,
              expiresAt: state.expiresAt,
              addHour: state.addHour,
            }}
          >
            <Form.Item
              label="IP 주소"
              name="ip"
              rules={[
                {
                  required: true,
                  message: 'IP 주수를 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="장소/담당자"
              name="memo"
              rules={[
                {
                  required: true,
                  message: '장소/담당자를 확인해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="만료시간"
              name="addHour"
              rules={[
                {
                  required: true,
                  message: '마감 시간을 확인해주세요.',
                },
              ]}
            >
              <InputNumber min={1} max={10000} />
            </Form.Item>

            {state?.expiresAt && (
              <Form.Item label="기존 만료시간" name="expiresAt">
                <Input disabled />
              </Form.Item>
            )}

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {ipAllowId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default IpAllowWindow;
