import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Form,
  Button,
  Row,
  Col,
  Input,
  DatePicker,
  Switch,
  InputNumber,
  notification,
  Select,
} from 'antd';
import { parse } from 'qs';
import '../../../public/css/window.css';
import WindowHeader from '../../components/windowHeader';
import { fetchHealthProfiles } from '../../../services/healthProfileService';
import { useLocation } from 'react-router';
import moment from 'moment';
import RoundItem from './RoundItem';
import {
  deleteHealthProfileGroup,
  fetchHealthProfileGroup,
  patchHealthProfileGroup,
  postHealthProfileGroup,
} from '../../../services/healthProfileGroupService';
import { useDispatch, useSelector } from 'react-redux';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';

const HealthProfileGroupWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();
  const { diseaseTypeInfo } = useSelector((state) => {
    return {
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data ?? [],
    };
  });
  const [form] = Form.useForm();
  const [healthProfiles, setHealthProfiles] = useState([]);
  const [windowId, setWindowId] = useState(null);
  const [groupId, setGroupId] = useState(null);

  const [selectedDisease, setSelectedDisease] = useState([]);
  const filteredHealthProfiles = useMemo(() => {
    return healthProfiles
      .filter((profile) => {
        const profileDisease = profile.diseaseTypes?.map(
          (disease) => disease.id,
        );
        return selectedDisease.some((disease) =>
          profileDisease.includes(disease),
        );
      })
      .map((profile) => ({
        label: `${profile.subCategory ? profile.subCategory.name + ' | ' : ''}${
          profile.title
        }`,
        value: profile.id,
      }));
  }, [healthProfiles, selectedDisease]);

  const setupBeforeUnloadListener = (data) => {
    const callback = (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    };
    window.addEventListener('beforeunload', callback);
    return callback;
  };

  const getData = useCallback(async () => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    const res = await fetchHealthProfiles();
    setHealthProfiles(res);
    if (groupId) {
      const groupData = await fetchHealthProfileGroup(groupId);
      form.setFieldsValue({
        ...groupData,
        rounds: groupData.rounds.map((round) => ({
          healthProfileIds: round.healthProfiles?.map((profile) => profile.id),
          id: round.id,
        })),
      });
    }
  }, [groupId]);

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    setGroupId(params.id);
    getData();

    const unloadHandler = setupBeforeUnloadListener(`close ${windowId}`);
    return () => {
      window.removeEventListener('beforeunload', unloadHandler);
    };
  }, [search, windowId]);

  const onDelete = async () => {
    if (window.confirm('삭제하시겠습니까?')) {
      await deleteHealthProfileGroup(groupId);
      window.alert('삭제되었습니다.');
      window.close();
    }
  };

  const onSubmit = async (values) => {
    if (!window.confirm('저장하시겠습니까?')) return;
    const data = { ...values, isRunning: !!values.isRunning };

    const selectedHealthProfiles = data.rounds
      .map((round) =>
        healthProfiles.filter((profile) =>
          round.healthProfileIds.includes(profile.id),
        ),
      )
      .flat();

    const notAllowed = selectedHealthProfiles.filter((profile) => {
      const profileDisease = profile.diseaseTypes?.map((disease) => disease.id);
      return !selectedDisease.some((disease) =>
        profileDisease.includes(disease),
      );
    });
    if (notAllowed.length > 0) {
      notification.error({
        message: '선택된 질환과 맞지 않는 설문이 있습니다.',
        description: '유효하지 않은 설문을 확인해 주세요.',
        key: 'notAllowedHealthProfile',
      });
      return;
    }

    if (groupId) {
      // PATCH
      try {
        await patchHealthProfileGroup(groupId, data);
        alert('수정되었습니다.');
        window.close();
      } catch (error) {
        notification.error({
          message: '수정에 실패했습니다.',
          key: 'patchHealthProfileGroupFailed',
        });
      }
    } else {
      // POST
      try {
        await postHealthProfileGroup(data);
        alert('저장되었습니다.');
        window.close();
      } catch (error) {
        notification.error({
          message: '저장에 실패했습니다.',
          key: 'postHealthProfileGroupFailed',
        });
      }
    }
  };

  return (
    <>
      <WindowHeader title="운영 > 주기적 설문 등록" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={onSubmit}
          >
            <Form.Item
              name="title"
              label="주기적 설문 그룹명"
              rules={[
                {
                  required: true,
                  message: '주기적 설문 그룹명을 입력하세요.',
                },
              ]}
            >
              <Input placeholder="예) 소아 가부키 연구" />
            </Form.Item>
            <Form.Item
              name="diseaseTypeIds"
              label="질환 선택"
              initialValue={[]}
              rules={[
                {
                  required: true,
                  message: '질환을 선택하세요.',
                },
              ]}
            >
              <Select
                onChange={setSelectedDisease}
                options={diseaseTypeInfo.map((item) => ({
                  label: item.krName,
                  value: item.id,
                }))}
                mode="multiple"
                placeholder="질환을 선택하세요."
                filterOption={(input, option) =>
                  option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              />
            </Form.Item>
            <Form.Item
              label="적용일"
              name="appliedAt"
              getValueProps={(v) => ({
                value: v ? moment(v) : null,
              })}
              tooltip="설문 주기를 적용할 시점을 선택하세요."
              rules={[
                {
                  required: true,
                  message: '적용일을 입력하세요.',
                },
              ]}
            >
              <DatePicker placeholder="" />
            </Form.Item>
            <Form.Item
              name="isRunning"
              label="주기 상태"
              valuePropName="checked"
              tooltip="적용일 이전에는 대기 중, 적용일 이후에는 진행 중으로 자동 변경됩니다. 주기를 더 이상 적용하지 않을 경우 토글을 끄세요."
              required
            >
              <Switch />
            </Form.Item>
            <Form.Item label="차수별 설문 설정" required>
              <Form.List
                name="rounds"
                rules={[
                  {
                    required: true,
                    message: '차수별 설문 설정을 입력하세요.',
                  },
                ]}
                noStyle
              >
                {(fields, { add, remove }, { errors }) => {
                  const records = form.getFieldValue('rounds');
                  return (
                    <>
                      <Row style={{ marginBottom: 16 }}>
                        <Button
                          onClick={() => {
                            const newRecord = {
                              healthProfileIds: [],
                            };
                            add(newRecord);
                          }}
                          type="primary"
                        >
                          추가
                        </Button>
                      </Row>
                      {fields.map((field, index) => (
                        <RoundItem
                          key={field.name}
                          round={field}
                          form={form}
                          removeRound={index > 0 ? () => remove(index) : null}
                          healthProfiles={filteredHealthProfiles}
                        />
                      ))}
                      <Form.ErrorList errors={errors} />
                    </>
                  );
                }}
              </Form.List>
            </Form.Item>
            <Form.Item
              label="푸시 발송 주기"
              tooltip={
                <>
                  차수별로 푸시를 발송할 주기를 설정하세요.
                  <br />
                  앱에서는 푸시 발송 주기와 무관하게 해당 차수 설문 완료 7일 후
                  다음 설문 화면으로 노출됩니다.
                </>
              }
              required
            >
              <Form.Item
                name="pushPeriod"
                rules={[
                  {
                    required: true,
                    message: '푸시 발송 주기를 입력하세요.',
                  },
                ]}
                noStyle
              >
                <InputNumber min={0} placeholder="예) 90" />
              </Form.Item>
              &nbsp;일
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button
                type="primary"
                htmlType="submit"
                style={{ width: 100, marginRight: 8 }}
              >
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onDelete}
                danger
              >
                삭제
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default HealthProfileGroupWindow;
