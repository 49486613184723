import { Amplify } from 'aws-amplify';
import { generateClient } from '@aws-amplify/api';
import { gql } from "graphql-tag";
import { useState } from "react";
import config from '../aws-exports';

Amplify.configure(config);

const ON_PROGRESS_UPDATE = gql`
  subscription OnProgressUpdate($requestId: ID!) {
    onProgressUpdate(requestId: $requestId) {
      requestId
      status
      message
    }
  }
`;

export function useAppSync() {
  const [subscription, setSubscription] = useState(null);

  const subscribe = (requestId, onMessage, onError) => {
    const client = generateClient();
    const subscribeOperation = client.graphql({
      query: ON_PROGRESS_UPDATE,
      variables: { requestId },
    });

    const sub = subscribeOperation.subscribe({
      next: (eventData) => {
        onMessage(eventData.data.onProgressUpdate);
      },
      error: (error) => {
        onError?.(error);
      },
    });
    console.log(requestId)
    setSubscription(sub);
  };

  const unsubscribe = () => {
    subscription?.unsubscribe();
    setSubscription(null);
  };

  return { subscribe, unsubscribe };
}
