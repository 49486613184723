import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  notification,
  Row,
  Select,
  TimePicker,
} from 'antd';
import dayjs from 'dayjs';
import { parse } from 'qs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useFetch } from '../../../hooks/useRequest';
import useWindow from '../../../hooks/useWindow';
import {
  createMediaMonitorConfig,
  deleteMediaMonitorConfig,
  fetchMediaMonitorConfigById,
  updateMediaMonitorConfig,
} from '../../../services/mediaMonitorService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import MultiRowInput from '../../../views/components/multiRowInput';
import WindowHeader from '../../components/windowHeader';

const MediaMonitorConfigWindow = () => {
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [configId, setConfigId] = useState(null);
  const { destroyWindowById } = useWindow();

  const dispatch = useDispatch();
  const { diseaseTypeData } = useSelector((state) => {
    const diseaseTypes = state.diseaseTypeReducer.diseaseTypes?.data;
    return {
      diseaseTypeData: diseaseTypes,
    };
  });
  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const { data: config, call: getConfig } = useFetch(
    null,
    fetchMediaMonitorConfigById,
    configId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setConfigId(params.id);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId]);

  useEffect(() => {
    if (configId) {
      getConfig();
    }
  }, [configId, getConfig]);

  const [form] = Form.useForm();

  useEffect(() => {
    if (config) {
      const sendDate =
        config.sendTime && config.sendTime.split(':').length === 2
          ? dayjs()
              .set('hour', config.sendTime.split(':')[0])
              .set('minute', config.sendTime.split(':')[1])
          : null;

      form.setFieldsValue({
        ...config,
        createdAt: dayjs(config.createdAt),
        sendTime: sendDate,
      });
    }
  }, [config, form]);

  const handleSubmit = useCallback(
    async (values) => {
      const requestBody = {
        ...values,
        sendTime: values.sendTime.format('HH:mm'),
        targetEmails: values.targetEmails ?? [],
      };
      if (configId) {
        try {
          await updateMediaMonitorConfig(configId, requestBody);
          notification.success({
            key: 'onSuccess',
            message: '저장되었습니다.',
          });
          destroyWindowById(windowId);
          window.close();
        } catch (error) {
          notification.error({
            key: 'onFailed',
            message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
          });
        }
      } else {
        try {
          await createMediaMonitorConfig(requestBody);
          notification.success({
            key: 'onSuccess',
            message: '저장되었습니다.',
          });
          destroyWindowById(windowId);
          window.close();
        } catch (error) {
          notification.error({
            key: 'onFailed',
            message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
          });
        }
      }
    },
    [configId, destroyWindowById, windowId],
  );

  const handleDelete = useCallback(async () => {
    if (window.confirm(`해당 설정을 삭제하시겠습니까?`)) {
      try {
        await deleteMediaMonitorConfig(configId);
        notification.success({
          key: 'onSuccess',
          message: '삭제되었습니다.',
        });
        destroyWindowById(windowId);
        window.close();
      } catch (error) {
        notification.error({
          key: 'onFailed',
          message: '삭제에 실패했습니다.',
        });
      }
    }
  }, [configId, destroyWindowById, windowId]);

  const { Option } = Select;

  return (
    <>
      <WindowHeader title="미디어 모니터링" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={handleSubmit}
            onFinishFailed={(error) => {
              notification.error({
                key: 'onFailed',
                message: '저장에 실패했습니다. 필수 항목을 확인해주세요.',
              });
            }}
            initialValues={{
              createdAt: dayjs(),
            }}
          >
            <Form.Item label="질환 선택" name="diseaseTypeIds" hasFeedback>
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="질환을 체크하지 않으면 질환이 전체 선택됩니다."
                filterOption={(input, option) => option.key.includes(input)}
              >
                {diseaseTypeData?.map((diseaseType) => (
                  <Option
                    key={diseaseType?.krName}
                    id={diseaseType?.krName}
                    value={diseaseType?.id}
                  >
                    {diseaseType.krName}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="고객사 명"
              name="clientName"
              rules={[{ required: true, message: '고객사 명을 입력해주세요.' }]}
            >
              <Input placeholder="고객사 명을 입력해주세요." />
            </Form.Item>
            <Form.Item label="등록일" name="createdAt" disabled>
              <DatePicker disabled />
            </Form.Item>
            <Form.Item label="수신자 이메일 주소">
              <MultiRowInput
                fieldName="targetEmails"
                placeholder="이메일 주소를 입력하세요"
                addButtonLabel="이메일 추가"
                form={form}
              />
            </Form.Item>
            <Form.Item
              label="뉴스레터 발송 시각"
              name="sendTime"
              rules={[{ required: true }]}
            >
              <TimePicker format="HH:mm" />
            </Form.Item>
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              {configId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={handleDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};

export default MediaMonitorConfigWindow;
