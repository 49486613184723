import { useState, useEffect, useCallback } from 'react';
import { parse } from 'qs';
import {
  getSignedUrl,
  getMultiSignedUrl,
  deleteImageRequest,
  deleteImagesRequest,
} from '../../services/fileUploadService';
import { useFetch } from '../../hooks/useRequest';

export function useEditorImageDelete(arrayToDelete) {
  const [done, setDone] = useState(false);
  const [fileName, setFileName] = useState(null);
  const [fileNameList, setFileNameList] = useState(null);
  const [deleteDone, setDeleteDone] = useState(false);

  const isSingleImageDelete = arrayToDelete.length === 1;
  const isMultiImgaeDelete = arrayToDelete.length > 1;

  const {
    data: signedUrlData,
    call: fetchSignedUrl,
    initialize: initializeSignedUrlDone,
  } = useFetch(null, getSignedUrl, {
    params: {
      functionType: 'DELETE',
      fileName,
      contentType: '',
    },
  });

  const {
    data: multiSignedUrlData,
    call: fetchMultiSignedUrl,
    initialize: initializeMultiSignedUrlDone,
  } = useFetch(null, getMultiSignedUrl, {
    functionType: 'DELETE',
    fileNameList,
    contentType: '',
  });

  useEffect(() => {
    if (fileName) {
      fetchSignedUrl();
      setFileName(null);
    }
  }, [fileName, fetchSignedUrl]);

  const deleteImage = useCallback(async () => {
    await deleteImageRequest(signedUrlData);
    setDone(true);
    initializeSignedUrlDone();
  }, [signedUrlData, initializeSignedUrlDone]);

  useEffect(() => {
    if (signedUrlData && !deleteDone) {
      setDeleteDone(true);
      deleteImage();
    }
  }, [signedUrlData, deleteImage, deleteDone]);

  useEffect(() => {
    if (fileNameList) {
      fetchMultiSignedUrl();
      setFileNameList(null);
    }
  }, [fileNameList, fetchMultiSignedUrl]);

  const deleteImages = useCallback(async () => {
    await deleteImagesRequest(multiSignedUrlData);
    setDone(true);
    initializeMultiSignedUrlDone();
  }, [multiSignedUrlData, initializeMultiSignedUrlDone]);

  useEffect(() => {
    if (multiSignedUrlData && !deleteDone) {
      setDeleteDone(true);
      deleteImages();
    }
  }, [multiSignedUrlData, deleteImages, deleteDone]);

  const imageDelete = async () => {
    if (isSingleImageDelete) {
      setFileName(arrayToDelete[0]);
    } else if (isMultiImgaeDelete) {
      setFileNameList(arrayToDelete);
    }
  };

  const initialize = () => {
    setDone(false);
  };
  return { done, imageDelete, initialize };
}
