import { Button, Layout, Switch } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import PAGE_URL from '../../../assets/pageUrl';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { useFetch } from '../../../hooks/useRequest';
import useWindow from '../../../hooks/useWindow';
import {
  fetchMediaMonitorConfigs,
  updateMediaMonitorConfig,
} from '../../../services/mediaMonitorService';
import { NEW } from '../../../window/util/utils';
import AntList from '../../components/antList';

const MediaMonitorConfig = () => {
  const history = useHistory();

  const { data: configs, loading, call: getData } = useFetch(
    [],
    fetchMediaMonitorConfigs,
  );

  useEffect(() => {
    getData();
  }, [getData]);

  const { Content } = Layout;

  const { findWindowById, createWindow, destroyWindowById } = useWindow();

  // receive messages from NoticeWindow
  const receiveMessage = useCallback(
    (event) => {
      if (
        event.origin !== window.location.origin ||
        typeof event.data !== 'string'
      )
        return;
      const [command, id] = event.data.split(' ');
      if (command === 'close') {
        destroyWindowById(id);
      }
      getData();
    },
    [getData, destroyWindowById],
  );

  // add event listner(to receive message) & fetch data at mount
  useEffect(() => {
    window.addEventListener('message', receiveMessage, false);
    return () => {
      window.removeEventListener('message', receiveMessage, false);
    };
  }, [receiveMessage]);

  const handleCreateNewWindow = () => {
    createWindow({ id: `${NEW}${Date.now()}`, dataType: 'MediaMonitorConfig' });
  };

  // handling the case of click link to edit notice
  const handleCreateEditWindow = (config) => {
    if (findWindowById(config.id)) {
      alert('이미 편집중인 설정입니다.');
      return;
    }
    createWindow({ id: config.id, dataType: 'mediaMonitorConfig' });
  };

  const handleStatusChange = useCallback(
    async (id, status) => {
      await updateMediaMonitorConfig(id, { status });
      await getData();
    },
    [getData],
  );

  const columns = [
    {
      title: '생성일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: (a, b) => a.createdAt?.localeCompare(b.createdAt),
      render: (text) => dayjs(text).format('YY.MM.DD'),
    },
    {
      title: '고객사 명',
      dataIndex: 'clientName',
      key: 'clientName',
      sorter: (a, b) => a.clientName.localeCompare(b.clientName),
      render: (text, record) =>
        text ? (
          <a onClick={() => handleCreateEditWindow(record)}>{text}</a>
        ) : (
          '-'
        ),
    },
    {
      title: '대상 질환 개수',
      dataIndex: 'diseaseCount',
      key: 'diseaseCount',
      sorter: (a, b) => a.diseaseCount - b.diseaseCount,
    },
    {
      title: '발송 기사 건수',
      dataIndex: 'sendNewsCount',
      key: 'sendNewsCount',
      sorter: (a, b) => a.sendNewsCount - b.sendNewsCount,
    },
    {
      title: '뉴스레터 발송 횟수',
      dataIndex: 'sendLetterCount',
      key: 'sendLetterCount',
      sorter: (a, b) => a.sendLetterCount - b.sendLetterCount,
    },
    {
      title: '최근 발송 일자',
      dataIndex: 'recentSendAt',
      key: 'recentSendAt',
      sorter: (a, b) => a.recentSendAt?.localeCompare(b.recentSendAt),
      render: (text) => (text ? dayjs(text).format('YY.MM.DD') : '-'),
    },
    {
      title: '서비스 시작 일자',
      dataIndex: 'serviceStartAt',
      key: 'serviceStartAt',
      sorter: (a, b) =>
        a.serviceStartAt?.localeCompare(b.serviceStartAt),
      render: (text) => (text ? dayjs(text).format('YY.MM.DD') : '-'),
    },
    {
      title: '서비스 종료 일자',
      dataIndex: 'serviceEndAt',
      key: 'serviceEndAt',
      sorter: (a, b) => a.serviceEndAt?.localeCompare(b.serviceEndAt),
      render: (text) => (text ? dayjs(text).format('YY.MM.DD') : '-'),
    },
    {
      title: '서비스 상태',
      dataIndex: 'status',
      key: 'status',
      width: '100px',
      filters: [
        { text: '사용 중', value: 'RUNNING' },
        { text: '중지', value: 'READY' },
        { text: '적재 중', value: 'LOADING' },
      ],
      onFilter: (value, record) => record.status === value,
      render: (value, record) => (
        <Switch
          disabled={value === 'LOADING'}
          checked={value !== 'READY'}
          onChange={(checked) =>
            handleStatusChange(record.id, checked ? 'RUNNING' : 'READY')
          }
        />
      ),
    },
    {
      title: '상세 설정 페이지 이동',
      render: (_, record) => (
        <Button
          href={`/${PAGE_URL.MEDIA_MONITOR_CONFIG}?id=${record.id}`}
          onClick={(e) => {
            e.preventDefault();
            history.push(
              `/${PAGE_URL.MEDIA_MONITOR_CONFIG_SETTING}?id=${record.id}`,
            );
          }}
        >
          상세 설정 &gt;
        </Button>
      ),
    },
    {
      title: '검수 페이지 이동',
      render: (_, record) => (
        <Button
          href={`/${PAGE_URL.MEDIA_MONITOR_NEWS}?id=${record.id}`}
          onClick={(e) => {
            e.preventDefault();
            history.push(`/${PAGE_URL.MEDIA_MONITOR_NEWS}?id=${record.id}`);
          }}
        >
          검수 페이지 &gt;
        </Button>
      ),
    },
  ];

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="mediaMonitorConfig" />
        <Layout className="right-layout">
          <TitleBreadcrumb
            title="콘텐츠"
            subTitle="미디어 모니터링"
            className="white-bg"
          />
          <Content className="site-layout-background contentStyle">
            <Button
              type="primary"
              style={{ width: 100, marginBottom: '16px' }}
              onClick={handleCreateNewWindow}
            >
              추가
            </Button>
            <AntList loading={loading} columns={columns} dataSource={configs} />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MediaMonitorConfig;
