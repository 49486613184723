import React, { useEffect, useState, useCallback, useRef } from 'react';
import moment from 'moment';
import dayjs from 'dayjs';
import {
  Layout,
  Button,
  Input,
  Table,
  notification,
  DatePicker,
  Space,
  Select,
} from 'antd';
import 'antd/dist/antd.css';
import { SearchOutlined } from '@ant-design/icons';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { useFetch } from '../../../hooks/useRequest';
import {
  fetchAllUser,
  fetchUnmaskedInfo as fetchUnmaskedInfoService,
} from '../../../services/userService';
import { usePagination } from '../../../hooks/usePagination';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { paginationCreators } from '../../../store/reducers/pagination.reducer';
import 'react-datepicker/dist/react-datepicker.css';

import * as listMap from '../../../util/listMap';
import system from '../../../img/system.png';
import humanscapeIcn from '../../../img/icn/humanscape.png';
import naverIcn from '../../../img/icn/naver.png';
import googleIcn from '../../../img/icn/google.png';
import kakaoIcn from '../../../img/icn/kakao.png';
import appleIcn from '../../../img/icn/apple.png';

export const makeLink = (handleClick) => (text, record) => (
  <span
    style={{ color: '#40a9ff', cursor: 'pointer' }}
    key={text}
    onClick={() => handleClick(record)}
  >
    {text}
  </span>
);

const renderBoolean = (value) => (value ? 'O' : 'X');
const booleanFilters = [
  { text: 'O', value: true },
  { text: 'X', value: false },
];

const User = () => {
  const { Content } = Layout;
  const [unmaskedType, setUnmaskedType] = useState('');
  const [unmaskedId, setUnmaskedId] = useState('');
  const [getMasked, setGetMasked] = useState('');
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { RangePicker } = DatePicker;
  const dateFormat = 'YYYY-MM-DD';

  const { diseaseTypeData, userPagination } = useSelector((pagingState) => {
    return {
      userPagination: pagingState.paginationReducer.userPagination,
      diseaseTypeData: pagingState.diseaseTypeReducer.diseaseTypes?.data
        ? pagingState.diseaseTypeReducer.diseaseTypes.data.map(
            ({ id, krName }) => ({
              label: krName,
              value: id,
            }),
          )
        : null,
    };
  }, shallowEqual);

  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  useEffect(() => {
    getData();
  }, [getData]);

  const searchInput = useRef(null);

  const handleSearch = (_selectedKeys, confirm, _dataIndex) => {
    confirm();
  };
  const handleReset = (clearFilters) => {
    clearFilters();
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: 'block',
          }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });
  const getColumnSearchDateProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <RangePicker
          defaultValue={[
            selectedKeys?.[0] ? moment(selectedKeys?.[0], dateFormat) : null,
            selectedKeys?.[1] ? moment(selectedKeys?.[1], dateFormat) : null,
          ]}
          onChange={(e) => {
            if (e !== null) {
              if (e?.[0].format(dateFormat) === e?.[1].format(dateFormat)) {
                setSelectedKeys([
                  e?.[0].format(dateFormat),
                  moment(e?.[1]).add(1, 'days').format(dateFormat),
                ]);
              } else {
                setSelectedKeys([
                  e?.[0].format(dateFormat),
                  e?.[1].format(dateFormat),
                ]);
              }
            } else {
              setSelectedKeys([]);
            }
          }}
          format={dateFormat}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });
  const getColumnSearchSelectProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Select
          style={{
            marginRight: 8,
            width: 250,
          }}
          placement="bottomLeft"
          defaultValue={userPagination.filter?.diseaseType ?? null}
          value={selectedKeys}
          showSearch
          mode="multiple"
          placeholder="질환을 선택하세요"
          options={diseaseTypeData}
          filterOption={(input, { label }) =>
            label.toLowerCase().includes(input.toLowerCase())
          }
          onChange={(e) => setSelectedKeys(e)}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{
              width: 90,
            }}
          >
            Search
          </Button>
          <Button
            onClick={() =>
              clearFilters && (handleReset(clearFilters), setSelectedKeys([]))
            }
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? '#1890ff' : undefined,
        }}
      />
    ),
  });

  const socialRender = (values) => {
    const socialArr = values.map((item) => item.social);

    return socialArr.map((item, idx) => {
      let source;
      let altText;

      switch (item) {
        case 'HUMANSCAPE':
          source = humanscapeIcn;
          altText = 'humanscape';
          break;
        case 'KAKAO':
          source = kakaoIcn;
          altText = 'kakao';
          break;
        case 'NAVER':
          source = naverIcn;
          altText = 'naver';
          break;
        case 'APPLE':
          source = appleIcn;
          altText = 'apple';
          break;
        case 'GOOGLE':
          source = googleIcn;
          altText = 'google';
          break;
        default:
          source = '';
          altText = '';
      }

      return (
        <img
          key={`${values[idx].id}-${item}`}
          src={source}
          style={source ? { height: 20, marginRight: 4 } : {}}
          alt={altText}
        />
      );
    });
  };

  const {
    done: fetchUnmaskedInfoDone,
    data: fetchUnmaskedInfoData,
    call: fetchUnmaskedInfo,
    initialize: initializeDone,
  } = useFetch(null, fetchUnmaskedInfoService, {
    userId: unmaskedId,
    type: unmaskedType,
  });

  const onChangeMasked = (record, type) => {
    if (record === '-') return;
    setUnmaskedType(type);
    setUnmaskedId(record.id);
    setGetMasked(true);
  };

  useEffect(() => {
    if (getMasked) {
      fetchUnmaskedInfo();
      setGetMasked(false);
    }
  }, [getMasked]);

  useEffect(() => {
    if (fetchUnmaskedInfoDone) {
      initializeDone();
      notification.success({
        message: '마스킹을 해제하였습니다.',
        description: '마스킹을 해제하였습니다.',
      });
    }
  }, [fetchUnmaskedInfoDone]);

  const MaskedData = useCallback(
    ({ record, type }) => {
      return (
        <>
          <a onClick={() => onChangeMasked(record, type)}>
            {record?.id === unmaskedId && type === unmaskedType
              ? fetchUnmaskedInfoData?.[type] ?? '-'
              : type === 'birthDate'
              ? '****-**-**'
              : record?.[type] ?? '-'}
          </a>
        </>
      );
    },
    [fetchUnmaskedInfoDone],
  );
  const {
    apiParams: [paramFilters],
    setApiParams,
    initialize,
    data,
    loading,
    pageState,
    pagination,
  } = usePagination({
    call: fetchAllUser,
    initialType: false,
    initialPageState: userPagination.pageState,
    initialApiParams: [userPagination?.filter],
  });

  const handleMoveDetailPage = useCallback(
    (record) => {
      history.replace('/user', {
        ...state,
        pageState,
        paramFilters,
      });
      history.push('/userDetail', {
        userId: record.id,
        diseaseTypeId: record.diseaseGroupId,
      });
    },
    [history, state, pageState, paramFilters],
  );

  useEffect(initialize, []);

  const columns = [
    {
      title: '계정 유형',
      dataIndex: 'socialAccounts',
      key: 'socialAccounts',
      width: 50,
      filters: [
        {
          text: (
            <>
              <span>
                <img
                  src={humanscapeIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="humanscape"
                />
                이메일 가입
              </span>
            </>
          ),
          value: 'NULL',
        },
        {
          text: (
            <>
              <span>
                <img
                  src={kakaoIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="kakao"
                />
                카카오
              </span>
            </>
          ),
          value: 'KAKAO',
        },
        {
          text: (
            <>
              <span>
                <img
                  src={naverIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="naver"
                />
                네이버
              </span>
            </>
          ),
          value: 'NAVER',
        },
        {
          text: (
            <>
              <span>
                <img
                  src={appleIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="apple"
                />
                애플
              </span>
            </>
          ),
          value: 'APPLE',
        },
        {
          text: (
            <>
              <span>
                <img
                  src={googleIcn}
                  style={{ height: 20, marginRight: 4 }}
                  alt="google"
                />
                구글
              </span>
            </>
          ),
          value: 'GOOGLE',
        },
      ],
      filteredValue: userPagination.filter?.socialAccounts || null,
      render: (value) =>
        value?.length > 0 ? (
          socialRender(value)
        ) : (
          <img
            src={humanscapeIcn}
            style={{ height: 20, marginRight: 4 }}
            alt="humanscape"
          />
        ),
    },
    {
      title: '계정',
      dataIndex: 'email',
      key: 'email',
      width: 250,
      ...getColumnSearchProps('email'),
      filteredValue: userPagination.filter?.email || null,
      sorter: {
        compare: (a, b) => a?.email?.localeCompare(b?.email),
      },
      render: (text, record) => (
        <>
          <MaskedData record={record} type="email" />
          <Button
            type="primary"
            size="small"
            style={{ marginLeft: 8 }}
            onClick={() => handleMoveDetailPage(record)}
          >
            이동
          </Button>
        </>
      ),
    },
    {
      title: '이름',
      dataIndex: 'name',
      key: 'name',
      width: 60,
      ...getColumnSearchProps('name'),
      filteredValue: userPagination.filter?.name || null,
      sorter: {
        compare: (a, b) => a?.name?.localeCompare(b?.name),
      },
      render: (text, record) => <MaskedData record={record} type="name" />,
    },
    {
      title: '닉네임',
      dataIndex: 'nickname',
      key: 'nickname',
      width: 60,
      ...getColumnSearchProps('nickname'),
      filteredValue: userPagination.filter?.nickname || null,
    },
    {
      title: '게시글',
      dataIndex: 'feedCount',
      key: 'feedCount',
      width: 30,
    },
    {
      title: '댓글',
      dataIndex: 'commentCount',
      key: 'commentCount',
      width: 30,
    },
    {
      title: '레어투게더',
      dataIndex: 'isInfluencer',
      key: 'isInfluencer',
      width: 50,
      filters: [
        {
          text: <img src={system} style={{ height: 20 }} alt="system" />,
          value: true,
        },
        { text: 'X', value: false },
      ],
      filterMultiple: false,
      filteredValue: userPagination.filter?.isInfluencer || null,
      render: (value) =>
        value ? (
          <img src={system} style={{ height: 20 }} alt="system" />
        ) : (
          <div>X</div>
        ),
    },
    {
      title: '신고',
      dataIndex: 'isAbuseReported',
      key: 'isAbuseReported',
      width: 30,
    },
    {
      title: '차단',
      dataIndex: 'hasBlockUsers',
      key: 'hasBlockUsers',
      width: 30,
    },
    {
      title: '연락처',
      dataIndex: 'phone',
      key: 'phone',
      width: 100,
      ...getColumnSearchProps('phone'),
      filteredValue: userPagination.filter?.phone || null,
      sorter: {
        compare: (a, b) => a?.phone?.localeCompare(b?.phone),
      },
      render: (text, record) => <MaskedData record={record} type="phone" />,
    },
    {
      title: '생년월일',
      dataIndex: 'birthDate',
      key: 'birthDate',
      width: 60,
      render: (text, record) => <MaskedData record={record} type="birthDate" />,
    },
    {
      title: '성별',
      dataIndex: 'gender',
      key: 'gender',
      width: 65,
      filters: [
        {
          text: '남',
          value: 'male',
        },
        {
          text: '여',
          value: 'female',
        },
      ],
      filteredValue: userPagination.filter?.gender || null,
      filterMultiple: false,
      sorter: {
        compare: (a, b) => a?.gender?.localeCompare(b?.gender),
      },
      render: (gender) => <>{listMap.genderMap[gender]}</>,
    },
    {
      title: '질환',
      dataIndex: 'diseaseType',
      key: 'diseaseType',
      width: 50,
      ...getColumnSearchSelectProps('diseaseType'),
      filteredValue: userPagination.filter?.diseaseType || null,
    },
    {
      title: '계정 유형',
      dataIndex: 'relationship',
      key: 'relationship',
      width: 65,
      filters: listMap.relationshipFilter,
      filteredValue: userPagination.filter?.relationship || null,
      sorter: {
        compare: (a, b) => a?.relationship?.localeCompare(b?.relationship),
      },
    },
    {
      title: '14세 미만',
      dataIndex: 'isUnder14',
      key: 'isUnder14',
      width: 35,
      render: renderBoolean,
    },
    {
      title: '본인인증',
      dataIndex: 'isIdentified',
      key: 'isIdentified',
      width: 50,
      filters: booleanFilters,
      filterMultiple: false,
      filteredValue: userPagination.filter?.isIdentified || null,
      render: renderBoolean,
    },
    {
      title: '질환인증',
      dataIndex: 'isCertified',
      key: 'isCertified',
      width: 50,
      filters: booleanFilters,
      filterMultiple: false,
      filteredValue: userPagination.filter?.isCertified || null,
      render: renderBoolean,
    },
    {
      title: '마케팅 동의',
      dataIndex: 'agreementMarketing',
      key: 'agreementMarketing',
      width: 50,
      filters: booleanFilters,
      filterMultiple: false,
      filteredValue: userPagination.filter?.agreementMarketing || null,
      render: renderBoolean,
    },
    {
      title: '테스트 ID',
      dataIndex: 'isTest',
      key: 'isTest',
      width: 50,
      filters: booleanFilters,
      filterMultiple: false,
      filteredValue: userPagination.filter?.isTest || null,
      render: renderBoolean,
    },
    {
      title: '회원가입일',
      dataIndex: 'createdAt',
      key: 'createdAt',
      width: 65,
      ...getColumnSearchDateProps('createdAt'),
      filteredValue: userPagination.filter?.createdAt || null,
      render: (createdAt) => dayjs(createdAt).format('YYYY-MM-DD'),
    },
  ];

  const onTableChange = (paginations, filters, sorter) => {
    dispatch(
      paginationCreators.setValue(
        (userPagination.filter = filters),
        (userPagination.sorter = sorter),
        (userPagination.pageState = paginations),
      ),
    );
    setApiParams([filters, sorter], paginations);
  };

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabAccount" link="user" />
        <Layout className="right-layout-floud">
          <TitleBreadcrumb title="계정" subTitle="유저" className="white-bg" />
          <Content className="site-layout-background contentStyle">
            <div className="searchResult">검색결과 {pagination?.total}개</div>
            <Table
              columns={columns}
              rowKey={(record) => record.id}
              dataSource={data}
              pagination={pagination}
              loading={loading}
              size="small"
              bordered
              onChange={onTableChange}
            />
          </Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default User;
