import axios from 'axios';
import { HTTP_UNAUTHORIZED, UNAUTHORIZED } from '../services/utils';
import BaseURL from '../api/BaseURL';

export const DOWNLOAD_URL = /^\/v1\/admin\/pap\/requests\/[0-9a-fA-F-]{36}\/documents/;

const onUnauthorized = () => {
  alert('세션이 만료되었습니다.');
  if (window.location.pathname.endsWith('Detail')) {
    window.close();
  } else {
    localStorage.clear();
    window.location.replace('/login');
  }
};

const getTempToken = async () => {
  const response = await axios.get(`/integration/token`, {
    headers: { 'If-None-Match': '' },
  });
  return response.data;
};

export function setInterceptors() {
  axios.interceptors.request.use((config) => {
    // setBaseURL 대체
    if (config.url.startsWith('/v1/')) {
      config.baseURL = BaseURL.replace(/\/api$/, '');
    } else {
      config.baseURL = BaseURL;
    }
    /**
     * NOTE(reo): 대용량의 응답을 받아야 하는 경우 API Gateway를 통하지 않도록 예외 처리
     * @see RARENOTE-4010
     */
    if (config.url.match(DOWNLOAD_URL)) {
      config.baseURL = process.env.REACT_APP_RARENOTE_DIRECT_URL;
    }
    return config;
  });

  axios.interceptors.request.use(async (config) => {
    const jwtToken = localStorage.getItem('jwtToken');
    const accessToken = localStorage.getItem('accessToken');
    if (
      !jwtToken &&
      config.url !== '/v3/humanscape/admin/login' &&
      config.url !== '/integration/token'
    ) {
      onUnauthorized();
      throw new Error('access token expired');
    }

    if (config.url !== '/integration/token' && config.url.startsWith('/')) {
      config.headers.Authorization = `Bearer ${
        jwtToken ?? (await getTempToken())
      }`;
    }

    // NOTE: 외부 서버(healthcaretogether, news-api)도 jwt로 변경되면 아래 로직을 지워야 함
    if (!config.url.startsWith('/') || config.url.match(DOWNLOAD_URL)) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  });

  axios.interceptors.response.use(
    (response) => {
      if (response.data.status === UNAUTHORIZED) onUnauthorized();
      return response;
    },
    (error) => {
      if (error.response?.status === HTTP_UNAUTHORIZED) {
        onUnauthorized();
        return;
      }
      throw error;
    },
  );
}

/**
 * NOTE(reo): nest와 koa 서버의 baseURL이 다르기 때문에 현재 사용되지 않음
 */
export function setBaseURL(url) {
  axios.defaults.baseURL = url;
}
