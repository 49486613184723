// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { generateConversationsProperty, generateCustomQueriesProperty, generateCustomMutationsProperty, generateCustomSubscriptionsProperty, generateEnumsProperty, generateGenerationsProperty, generateModelsProperty, upgradeClientCancellation, } from './internals';
export function addSchemaToClient(client, apiGraphqlConfig, getInternals) {
    upgradeClientCancellation(client);
    client.models = generateModelsProperty(client, apiGraphqlConfig, getInternals);
    client.enums = generateEnumsProperty(apiGraphqlConfig);
    client.queries = generateCustomQueriesProperty(client, apiGraphqlConfig, getInternals);
    client.mutations = generateCustomMutationsProperty(client, apiGraphqlConfig, getInternals);
    client.subscriptions = generateCustomSubscriptionsProperty(client, apiGraphqlConfig, getInternals);
    client.conversations = generateConversationsProperty(client, apiGraphqlConfig, getInternals);
    client.generations = generateGenerationsProperty(client, apiGraphqlConfig, getInternals);
    return client;
}
