import { Button, Col, Form, Layout, Row } from "antd";
import moment from "moment";
import { parse } from "qs";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Header, SideBar, TitleBreadcrumb } from '../../../component';
import { useFetch } from '../../../hooks/useRequest';
import {
  createMediaMonitorConfigPreview,
  createMediaMonitorConfigPreviewById,
  fetchMediaMonitorConfigById,
  getMediaMonitorPreviews,
  updateMediaMonitorConfig,
} from '../../../services/mediaMonitorService';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import { MediaMonitorFilter } from './mediaMonitorFilter';
import { MediaMonitorPreview } from './mediaMonitorPreview';
import { MediaMonitorPreviews } from './mediaMonitorPreviews';
import { MediaMonitorProcess } from './mediaMonitorProcess';
import { useAppSync } from '../../../hooks/useAppSync';

const MediaMonitorConfigSetting = () => {
  const history = useHistory();
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [configId, setConfigId] = useState(null);
  const [substitutions, setSubstitutions] = useState([]);
  const [selectedPreview, setSelectedPreview] = useState(null);
  const [processedPreview, setProcessedPreview] = useState(null);
  const [previewsLoading, setPreviewsLoading] = useState(false);
  const [previewsLoadingText, setPreviewsLoadingText] = useState('');
  const [previewLoading, setPreviewLoading] = useState(false);
  const [previewLoadingText, setPreviewLoadingText] = useState('');
  const [filterForm] = Form.useForm();
  const [processForm] = Form.useForm();
  const [startDate, setStartDate] = useState(
    moment().subtract(1, 'days').startOf('day'),
  );
  const [endDate, setEndDate] = useState(
    moment().subtract(1, 'days').endOf('day'),
  );

  const {
    subscribe: previewsSubscribe,
    unsubscribe: previewsUnsubscribe,
  } = useAppSync();
  const {
    subscribe: previewSubscribe,
    unsubscribe: previewUnsubscribe,
  } = useAppSync();

  const dispatch = useDispatch();
  const { diseaseTypeData } = useSelector((state) => {
    const diseaseTypes = state.diseaseTypeReducer.diseaseTypes?.data;
    return {
      diseaseTypeData: diseaseTypes,
    };
  });
  const getData = useCallback(() => {
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
  }, [dispatch]);

  const { data: config, call: getConfig } = useFetch(
    null,
    fetchMediaMonitorConfigById,
    configId,
  );

  const { data: previews, call: getPreviews } = useFetch(
    null,
    getMediaMonitorPreviews,
    configId,
  );

  useEffect(() => {
    getData();
    if (configId) {
      getConfig();
      getPreviews();
    }
  }, [getConfig, getData, configId, getPreviews]);

  useEffect(() => {
    if (selectedPreview) {
      const preview = previews?.find((item) => item.id === selectedPreview);
      setProcessedPreview(preview);
    }
  }, [selectedPreview, previews]);

  useEffect(() => {
    if (config) {
      filterForm.setFieldsValue(config);
      processForm.setFieldsValue(config);
      setSubstitutions(config.substitutions);
    }
  }, [config, filterForm, processForm]);

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });

    setWindowId(params.id ? params.id : params.new);
    if (params.id) {
      setConfigId(params.id);
    }
  }, [search, windowId]);

  const onSave = useCallback(() => {
    Promise.all([filterForm.validateFields(), processForm.validateFields()])
      .then((values) => {
        const body = {
          ...values[0],
          ...values[1],
          substitutions,
        };

        updateMediaMonitorConfig(configId, body)
          .then(() => {
            alert('저장되었습니다.');
          })
          .catch((error) => {
            alert('입력값을 확인해주세요.');
          });
      })
      .catch((error) => {
        alert('입력값을 확인해주세요.');
      });
  }, [filterForm, processForm, configId, substitutions]);

  const createPreviews = useCallback(async () => {
    try {
      const body = await filterForm.validateFields();

      setPreviewsLoading(true);
      const requestId = await createMediaMonitorConfigPreview(configId, {
        ...body,
        startDate,
        endDate,
      });

      previewsSubscribe(
        requestId,
        ({ status, message }) => {
          if (status === 'LOADING') {
            setPreviewsLoadingText(`적재 중... ${message}%`);
          }
          if (status === 'FILTERING') {
            setPreviewsLoadingText(`필터링 중... ${message}%`);
          }
          if (status === 'FILTERING' && message === '100.00') {
            setPreviewsLoading(false);
            getPreviews();
            previewsUnsubscribe();
          }
        },
        (e) => {
          alert('미리보기 생성에 실패했습니다.');
          setPreviewsLoading(false);
          previewsUnsubscribe();
          window.location.reload();
        },
      );
    } catch (e) {
      alert('미리보기 생성에 실패했습니다.');
      setPreviewsLoading(false);
      window.location.reload();
    }
  }, [filterForm, configId, startDate, endDate]);

  const createPreview = useCallback(async () => {
    if (!selectedPreview) {
      alert('미리보기를 선택해주세요.');
      return;
    }

    try {
      const body = await processForm.validateFields();

      setPreviewLoading(true);
      const requestId = await createMediaMonitorConfigPreviewById(
        configId,
        selectedPreview,
        {
          ...body,
          substitutions,
        },
      );

      previewSubscribe(
        requestId,
        (message) => {
          setPreviewLoadingText(
            `${message.status === 'PROCESSING' ? '후처리 중' : '완료'}... (${
              message.message
            }건)`,
          );
          if (message.status === 'COMPLETE') {
            setPreviewLoading(false);
            getPreviews();
            previewUnsubscribe();
          }
        },
        (e) => {
          alert('미리보기 생성에 실패했습니다.');
          setPreviewLoading(false);
          previewUnsubscribe();
        },
      );
    } catch (e) {
      alert('미리보기 생성에 실패했습니다.');
      setPreviewLoading(false);
    }
  }, [configId, processForm, selectedPreview, substitutions]);

  return (
    <Layout>
      <Header className="site-layout-background" />
      <Layout className="site-layout contentLayout">
        <SideBar tab="tabContent" link="mediaMonitorConfig" />
        <Layout className="right-layout-floud">
          <TitleBreadcrumb
            title="콘텐츠 / 미디어 모니터링"
            subTitle="상세 설정"
            className="white-bg"
          />
          <Layout.Content className="site-layout-background userContentStyle">
            <Row span={24}>
              <Layout.Content>
                <Button type="primary" onClick={() => history.goBack()}>
                  목록 돌아가기
                </Button>
                <Button
                  type="primary"
                  onClick={onSave}
                  style={{ marginLeft: 10 }}
                >
                  저장
                </Button>
              </Layout.Content>
            </Row>
            <Row span={24} style={{ marginTop: 16 }}>
              <Col span={12} style={{ paddingRight: 8 }}>
                <Layout.Content>
                  <MediaMonitorFilter
                    filterForm={filterForm}
                    config={config}
                    diseaseTypeData={diseaseTypeData}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                  />
                  <Button
                    type="primary"
                    onClick={createPreviews}
                    style={{ marginTop: 40 }}
                  >
                    미리보기
                  </Button>
                </Layout.Content>
              </Col>
              <Col span={12} style={{ paddingLeft: 8 }}>
                <Layout.Content style={{ height: '100%' }}>
                  <MediaMonitorPreviews
                    previews={previews}
                    config={config}
                    diseaseTypeData={diseaseTypeData}
                    previewLoading={previewsLoading}
                    previewLoadingText={previewsLoadingText}
                    selectedPreview={selectedPreview}
                    setSelectedPreview={setSelectedPreview}
                  />
                </Layout.Content>
              </Col>
            </Row>
            <Row span={24} style={{ marginTop: 16 }}>
              <Col span={12} style={{ paddingRight: 8 }}>
                <Layout.Content>
                  <MediaMonitorProcess
                    processForm={processForm}
                    substitutions={substitutions}
                    setSubstitutions={setSubstitutions}
                  />
                  <Button
                    type="primary"
                    onClick={createPreview}
                    style={{ marginTop: 40 }}
                  >
                    미리보기
                  </Button>
                </Layout.Content>
              </Col>
              <Col span={12} style={{ paddingLeft: 8 }}>
                <Layout.Content style={{ height: '100%' }}>
                  <MediaMonitorPreview
                    preview={processedPreview}
                    diseaseTypeData={diseaseTypeData}
                    previewLoading={previewLoading}
                    previewLoadingText={previewLoadingText}
                  />
                </Layout.Content>
              </Col>
            </Row>
          </Layout.Content>
        </Layout>
      </Layout>
    </Layout>
  );
};

export default MediaMonitorConfigSetting;
