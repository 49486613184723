import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Col,
  Empty,
  Form,
  Input,
  Menu,
  notification,
  Row,
  Typography,
} from 'antd';
import { QRCodeCanvas } from 'qrcode.react';
import { DownloadOutlined } from '@ant-design/icons';

const UserHealthProfileGroup = ({ healthProfileGroup }) => {
  const [groupIndex, setGroupIndex] = useState(0);
  const [form] = Form.useForm();
  const groupInfo = useMemo(() => {
    return healthProfileGroup?.map((group, index) => ({
      key: `${index}`,
      label: group?.title,
    }));
  }, [healthProfileGroup]);

  useEffect(() => {
    form.setFieldsValue(healthProfileGroup?.[groupIndex]);
  }, [groupIndex, healthProfileGroup]);

  return (
    <Row wrap={false}>
      <Col style={{ maxWidth: 160 }}>
        <Menu
          onClick={(e) => setGroupIndex(e?.key)}
          mode="inline"
          items={groupInfo}
          selectedKeys={`${groupIndex}`}
          style={{ height: '100%' }}
        />
      </Col>
      <Col flex={1} style={{ padding: '8px 16px' }}>
        <Form labelCol={{ span: 8 }} form={form}>
          {healthProfileGroup.length > 0 ? (
            <>
              <Typography.Title level={5}>설문 진행 정보</Typography.Title>
              <Form.Item label="설문 차수" name="round">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="최종 설문" name="title">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="설문 상태" name="status">
                <Input readOnly />
              </Form.Item>
              <Typography.Title level={5}>연구 대상자 정보</Typography.Title>
              <Form.Item label="병원명" name="institutionName">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="연구 번호" name="seq">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="앱 딥링크" name="qrLink">
                <Input readOnly />
              </Form.Item>
              <Form.Item label="QR코드">
                <Button
                  icon={<DownloadOutlined />}
                  onClick={(e) => {
                    const canvas = e.currentTarget.nextSibling;
                    if (!canvas) {
                      notification.error({
                        message: 'QR 코드가 생성되지 않았습니다.',
                        key: 'qrCodeNotGenerated',
                      });
                      return;
                    }
                    const dataURI = canvas.toDataURL('image/png');
                    const a = document.createElement('a');
                    a.href = dataURI;
                    a.download = `${healthProfileGroup?.[groupIndex].seq}.png`;
                    a.click();
                    URL.revokeObjectURL(dataURI);
                  }}
                />
                <QRCodeCanvas
                  value={healthProfileGroup?.[groupIndex].qrLink}
                  hidden
                />
              </Form.Item>
            </>
          ) : (
            <Empty />
          )}
        </Form>
      </Col>
    </Row>
  );
};

export default UserHealthProfileGroup;
