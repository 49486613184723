import axios from 'axios';
import { HTTP_SUCCESS, SUCCESS } from './utils';
import moment from 'moment';

/**
 * 주기적 설문 목록을 가져옵니다.
 */
export async function fetchHealthProfileGroups() {
  const res = await axios.get(`/v1/admin/healthProfileGroups`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주기적 설문을 생성합니다.
 * @param {Object} postData
 */
export async function postHealthProfileGroup(postData) {
  const res = await axios.post(`/v1/admin/healthProfileGroups`, postData);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주기적 설문을 수정합니다.
 * @param {uuid} healthProfileGroupId
 * @param {Object} patchData
 */
export async function patchHealthProfileGroup(healthProfileGroupId, patchData) {
  const res = await axios.patch(
    `/v1/admin/healthProfileGroups/${healthProfileGroupId}`,
    patchData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 주기적 설문의 정보를 가져옵니다.
 * @param {uuid} healthProfileGroupId
 */
export async function fetchHealthProfileGroup(healthProfileGroupId) {
  const res = await axios.get(
    `/v1/admin/healthProfileGroups/${healthProfileGroupId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주기적 설문을 삭제합니다.
 * @param {uuid} healthProfileGroupId
 */
export async function deleteHealthProfileGroup(healthProfileGroupId) {
  const res = await axios.delete(
    `/v1/admin/healthProfileGroups/${healthProfileGroupId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주기적 설문 유저 리스트를 조회합니다.
 * @param {uuid} healthProfileGroupId
 * @param {Object} filter
 */
export async function fetchHealthProfileGroupUsers(
  healthProfileGroupId,
  filter,
) {
  const res = await axios.get(
    `/v1/admin/healthProfileGroups/${healthProfileGroupId}/subjects`,
    {
      params: filter,
    },
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주기적 설문의 병원별 연구대상자를 생성합니다.
 * @param {uuid} healthProfileGroupId
 * @param {Object} postData { title: string, subjectCount: number }
 */
export async function postHealthProfileGroupInstitutions(
  healthProfileGroupId,
  postData,
) {
  const res = await axios.post(
    `/v1/admin/healthProfileGroups/${healthProfileGroupId}/institutions`,
    postData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 특정 유저의 주기적 설문 응답내역을 조회합니다.
 * @param {uuid} userId
 */
export async function fetchHealthProfileGroupUser(userId) {
  const res = await axios.get(`/v1/admin/healthProfileGroups/users/${userId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주기적 설문 미참여 유저에게 독려 푸시를 발송합니다.
 * @param {uuid} healthProfileGroupId
 * @param {number} pushRound
 */
export async function postHealthProfileGroupPush(
  healthProfileGroupId,
  pushRound,
) {
  const res = await axios.post(
    `/v1/admin/healthProfileGroups/${healthProfileGroupId}/notifications`,
    {
      round: pushRound,
    },
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) throw res;
  return res.data.data;
}

/**
 * 주기적 설문 응답 데이터를 csv 파일로 다운로드합니다.
 * @param {uuid} healthProfileGroupId
 */
export async function fetchHealthProfileGroupExcel(
  healthProfileGroupId,
  startDate,
  endDate,
  fileName = '주기적 설문',
) {
  const res = await axios.get(
    `/v1/admin/healthProfileGroups/${healthProfileGroupId}/csv`,
    {
      responseType: 'arraybuffer',
      params: {
        startDate: moment(startDate).format('YYYY-MM-DD'),
        endDate: moment(endDate).format('YYYY-MM-DD'),
      },
    },
  );

  let name = `${fileName}.xlsx`;

  const disposition = res.headers['content-disposition'];
  if (disposition && disposition.includes('filename=')) {
    const matches = disposition.match(/filename="(.+)"/);
    if (matches != null && matches[1]) {
      name = decodeURIComponent(matches[1]);
    }
  }

  const blob = new Blob([res.data], {
    type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  });

  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = name;

  link.click();

  URL.revokeObjectURL(link.href);
}
