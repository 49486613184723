import React from 'react';
import { Form, Input } from 'antd';
import 'antd/dist/antd.css';
import * as listMap from '../../util/listMap';

/**
 * @deprecated 사용 금지; Form이 제어하는 값과 동기화되지 않아 혼동을 유발합니다. Form.Item 하위 Input에 disabled 속성을 추가해 직접 구현해주세요.
 */
const DisabledInput = ({ label, value, size = false }) => {
  return (
    <Form.Item
      label={label}
      labelCol={size ? listMap.largeLayout.labelCol : listMap.layout.labelCol}
      wrapperCol={
        size ? listMap.largeLayout.wrapperCol : listMap.layout.wrapperCol
      }
    >
      <Input defaultValue={value} disabled />
    </Form.Item>
  );
};

export default DisabledInput;
