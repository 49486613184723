export const HEALTH_PROFILE_CATEGORY = {
  NORMAL: '레어노트 설문',
  RESEARCH: '연구 설문',
  PATIENT_GROUP: '환우회 설문',
};

export const HEALTH_PROFILE_ACCOUNT_TYPES = {
  PATIENT: '환자',
  NOK: '보호자',
};

export const HEALTH_PROFILE_GENDER = {
  MALE: '남성',
  FEMALE: '여성',
};

export const HEALTH_PROFILE_QUESTION_TYPE = {
  RADIO: '단일 선택 (+ 텍스트 입력)',
  CHECKBOX: '복수 선택 (+ 텍스트 입력)',
  DROPDOWN: '목록 선택',
  DATE_PICKER: '연도/월/일 선택',
  DATE_INPUT: '연도/월/일 입력',
  YEAR_MONTH_INPUT: '연도/월 입력',
  YEAR_DROPDOWN: '연도 선택',
  TEXT_INPUT: '텍스트 입력',
  NUMBER_INPUT: '단위 입력',
};
