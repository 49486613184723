import React, { useEffect, useState, useRef, useReducer } from 'react';
import {
  Form,
  Input,
  Button,
  Alert,
  Row,
  Col,
  DatePicker,
  Upload,
  Radio,
  Select,
  Spin,
  notification,
} from 'antd';
import LoadingOverlay from 'react-loading-overlay';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import moment from 'moment';
import '../../../public/css/window.css';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';
import { notificationCreators } from '../../../store/reducers/notification.reducer';
import { diseaseTypeCreators } from '../../../store/reducers/diseaseType.reducer';
import {
  postResearch,
  patchResearch,
  deleteResearch,
} from '../../../services/geneResearchService';
import { useMutate } from '../../../hooks/useRequest';
import { ALERT_MESSAGES } from '../../../assets/alert';
import { useAntFileUpload } from '../../util/useAntFileImage';
import AntTinymceInput from '../../components/antTinymceInput';
import * as listMap from '../../../util/listMap';

const ResearchWindow = () => {
  const { search } = useLocation();
  const dispatch = useDispatch();

  const { notificationContents, diseaseTypeInfo } = useSelector((state) => {
    return {
      notificationContents: state.notificationReducer.contents.data,
      diseaseTypeInfo: state.diseaseTypeReducer.diseaseTypes.data
        ? state.diseaseTypeReducer.diseaseTypes.data.map(({ id, krName }) => ({
            id,
            krName,
          }))
        : null,
    };
  }, shallowEqual);

  const initialState = {
    shareLink: '',
    title: '',
    diseaseTypeIds: [],
    hostHospitalName: '',
    hostHospitalLogo: '',
    connectHospitals: [],
    goalEnrollment: '',
    completedAt: moment(),
    status: 'ready',
    type: 'promotion_and_healthprofile',
    optionalHostHospitals: [],
    customDetailPageId: null,
    isSharedWithOtherDiseases: true,
    healthProfileIds: [],
    description: '',
  };

  const stateReducer = (prevState, updatedProperty) => ({
    ...prevState,
    ...updatedProperty,
  });

  const [state, setState] = useReducer(stateReducer, initialState);
  const [windowId, setWindowId] = useState('');
  const [isPost, setIsPost] = useState(false);
  const [isPatch, setIsPatch] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [researchId, setResearchId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [distinctFileInputEvent, setDistinctFileInputEvent] = useState(null);
  const fileUploadSetting = useRef(false);
  const uploadCancel = useRef(false);
  const connectNextID = useRef(0);
  const optionalHostHospitalNextID = useRef(0);
  const [connectItems, setConnectItems] = useState([]);
  const [optionalHostHospitalItems, setOptionalHostHospitalItems] = useState(
    [],
  );
  const [form] = Form.useForm();
  const [imageLoading, setImageLoading] = useState(false);

  const {
    publicUrl: distinctPublicUrl,
    done: distinctImageUploadDone,
    imageUpload: distinctImageUpload,
    initialize: distinctUploadInitialize,
  } = useAntFileUpload(distinctFileInputEvent);

  const postPatchData = {
    shareLink: state.shareLink,
    title: state.title,
    diseaseTypeIds: state.diseaseTypeIds,
    hostHospitalName: state.hostHospitalName,
    hostHospitalLogo: state.hostHospitalLogo,
    connectHospitals: state.connectHospitals,
    goalEnrollment: state.goalEnrollment,
    completedAt: state.completedAt ? state.completedAt : null,
    status: state.status,
    type: state.type,
    optionalHostHospitals: state.optionalHostHospitals,
    customDetailPageId: state.customDetailPageId,
    isSharedWithOtherDiseases: state.isSharedWithOtherDiseases,
    healthProfileIds: state.healthProfileIds,
    description: state.description,
  };

  const { mutate: putResearch, done: isPosted } = useMutate(
    postResearch,
    postPatchData,
  );

  const { mutate: changeResearch, done: isPatched } = useMutate(
    patchResearch,
    researchId,
    postPatchData,
  );

  const { mutate: eraseResearch, done: isDeleted } = useMutate(
    deleteResearch,
    researchId,
  );

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    dispatch(diseaseTypeCreators.fetchAllDiseaseTypes.request());
    dispatch(notificationCreators.fetchAllNotificationContents.request());

    if (params.id) {
      setResearchId(params.id);
      const paramData = JSON.parse(params.subId);
      setState({
        shareLink: paramData?.shareLink,
        title: paramData?.title,
        diseaseTypeIds: paramData?.diseaseTypes?.map((item) => item?.id),
        hostHospitalName: paramData?.hostHospitalName,
        hostHospitalLogo: paramData?.hostHospitalLogo,
        connectHospitals: paramData?.connectHospitals
          ? paramData?.connectHospitals?.map((item, index) => ({
              id: index,
              title: item,
            }))
          : [],
        goalEnrollment: paramData?.goalEnrollment,
        status: paramData?.status,
        type: paramData?.type,
        optionalHostHospitals: paramData?.optionalHostHospitals
          ? paramData?.optionalHostHospitals?.map((item, index) => ({
              id: index,
              name: item?.name,
              phone: item?.phone,
            }))
          : [],
        customDetailPageId: paramData?.customDetailPageId,
        isSharedWithOtherDiseases: paramData?.isSharedWithOtherDiseases,
        healthProfileIds: paramData?.healthProfiles?.map((item) => item?.id),
        description: paramData?.description,
        completedAt: paramData?.completedAt
          ? moment(paramData?.completedAt)
          : '',
      });
      setConnectItems(
        paramData?.connectHospitals
          ? paramData?.connectHospitals?.map((item, index) => ({
              id: index,
              title: item,
            }))
          : [],
      );
      setOptionalHostHospitalItems(
        paramData?.optionalHostHospitals
          ? paramData?.optionalHostHospitals?.map((item, index) => ({
              id: index,
              name: item?.name,
              phone: item?.phone,
            }))
          : [],
      );
      connectNextID.current = paramData?.connectHospitals?.length;
      optionalHostHospitalNextID.current =
        paramData?.optionalHostHospitals?.length;
      setIsLoading(false);
    } else {
      setResearchId('');
      setIsLoading(false);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
  }, [search, windowId, dispatch]);

  useEffect(() => {
    let message = '';
    if (isPosted) {
      message = ALERT_MESSAGES.CREATE;
    }
    if (isPatched) {
      message = ALERT_MESSAGES.UPDATE;
    }
    if (isDeleted) {
      message = ALERT_MESSAGES.DELETE;
    }
    if (isPosted || isPatched || isDeleted) {
      alert(message);
      window.close();
    }
  }, [isDeleted, isPosted, isPatched]);

  const onFinish = (values) => {
    setShowAlert(false);
    setState({
      title: values.title,
      hostHospitalName: values.hostHospitalName,
      connectHospitals: connectItems?.map((item) => item.title),
      goalEnrollment: values.goalEnrollment ? values.goalEnrollment : null,
      optionalHostHospitals:
        state.type === 'promotion_and_healthprofile'
          ? optionalHostHospitalItems?.map((item) => ({
              name: item.name,
              phone: item.phone,
            }))
          : [],
      customDetailPageId:
        state.type === 'custom_detail_page' ? values.customDetailPageId : null,
    });
    if (researchId) {
      if (window.confirm('수정하시겠습니까?')) {
        setIsPatch(true);
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      setIsPost(true);
    }
  };

  const onFinishFailed = () => {
    setShowAlert(true);
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const onDelete = () => {
    if (window.confirm('삭제하시겠습니까?')) {
      eraseResearch();
    }
  };

  const onDateChange = (date, dateString) => {
    setState({
      completedAt: dateString,
    });
  };

  useEffect(() => {
    if (isPatch) {
      changeResearch();
      setIsPatch(false);
    }
    if (isPost) {
      putResearch();
      setIsPost(false);
    }
  }, [changeResearch, isPatch, isPost, putResearch]);

  const beforeUpload = (file) => {
    const isSupportedImageType =
      file.type === 'image/jpeg' ||
      file.type === 'image/png' ||
      file.type === 'image/svg+xml';
    if (!isSupportedImageType) {
      notification.error({
        message: 'JPG/PNG/SVG만 업로드 가능합니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    const isLt10M = file.size / 1024 / 1024 < 10;
    if (!isLt10M) {
      notification.error({
        message: '이미지가 10MB보다 큽니다.',
        description: '이미지 업로드를 다시 시도해주세요.',
      });
    }
    return isSupportedImageType && isLt10M;
  };

  const uploadImg = async (e) => {
    if (e !== undefined) {
      setDistinctFileInputEvent(e);
      uploadCancel.current = true;
      setLoading(true);
    }
  };

  const noop = () => {};

  const deleteThumbImg = () => {
    form.setFieldsValue({ hostHospitalLogo: '' });
    setState({
      hostHospitalLogo: '',
    });
    notification.success({
      message: '이미지를 삭제하였습니다.',
      description: '환우회 이미지를 다시 업로드해주세요.',
    });
  };

  const uploadProps = {
    customRequest: noop,
    onChange: uploadImg,
    onPreview: noop,
  };

  useEffect(() => {
    if (distinctFileInputEvent && uploadCancel.current) {
      distinctImageUpload();
      uploadCancel.current = false;
      fileUploadSetting.current = true;
    }
  }, [distinctFileInputEvent, distinctImageUpload]);

  useEffect(() => {
    if (distinctImageUploadDone && distinctPublicUrl) {
      notification.success({
        message: '이미지 업로드에 성공하였습니다.',
        description: '환우회 이미지 등록에 성공하였습니다.',
      });
      setState({ hostHospitalLogo: distinctPublicUrl });
      form.setFieldsValue({ hostHospitalLogo: distinctPublicUrl });
      distinctUploadInitialize();
      setLoading(false);
    }
  }, [
    distinctImageUploadDone,
    distinctPublicUrl,
    distinctUploadInitialize,
    form,
  ]);

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const addConnectItems = () => {
    const input = {
      id: connectNextID.current,
      title: '',
    };

    setConnectItems([...connectItems, input]);
    connectNextID.current += 1;
  };

  const deleteConnectItems = (index) => {
    if (window.confirm('정말 삭제하실건가요?')) {
      setConnectItems(connectItems.filter((item) => item.id !== index));
    }
  };

  const handleConnectItemsChange = (e, index, label) => {
    const inputItemsCopy = JSON.parse(JSON.stringify(connectItems));
    inputItemsCopy[index][label] = e.target.value;
    setConnectItems(inputItemsCopy);
  };

  const addOptionalHostHospitalItems = () => {
    const input = {
      id: optionalHostHospitalNextID.current,
      name: '',
      phone: '',
    };

    setOptionalHostHospitalItems([...optionalHostHospitalItems, input]);
    optionalHostHospitalNextID.current += 1;
  };

  const deleteOptionalHostHospitalItems = (index) => {
    if (window.confirm('정말 삭제하실건가요?')) {
      setOptionalHostHospitalItems(
        optionalHostHospitalItems.filter((item) => item.id !== index),
      );
    }
  };

  const handleOptionalHostHospitalItemsChange = (e, index, label) => {
    const inputItemsCopy = JSON.parse(
      JSON.stringify(optionalHostHospitalItems),
    );
    inputItemsCopy[index][label] = e.target.value;
    setOptionalHostHospitalItems(inputItemsCopy);
  };

  if (isLoading) return <ElementLoading type="연구 신청" />;
  return (
    <>
      <WindowHeader title="연구 신청" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            initialValues={{
              remember: true,
              shareLink: state.shareLink,
              title: state.title,
              diseaseTypeIds: state.diseaseTypeIds,
              hostHospitalName: state.hostHospitalName,
              hostHospitalLogo: state.hostHospitalLogo,
              connectHospitals: state.connectHospitals,
              goalEnrollment: state.goalEnrollment,
              status: state.status,
              type: state.type,
              completedAt: state.completedAt,
              optionalHostHospitals: state.optionalHostHospitals,
              customDetailPageId: state.customDetailPageId,
              isSharedWithOtherDiseases: state.isSharedWithOtherDiseases,
              healthProfileIds: state.healthProfileIds,
              description: state.description,
            }}
          >
            <Form.Item
              wrapperCol={{ span: 4 }}
              style={{ textAlign: 'right', fontWeight: 'bold', fontSize: 16 }}
            >
              연구 기본 정보
            </Form.Item>
            <Form.Item label="공유 링크" name="shareLink">
              <Input placeholder="미입력 시 자동으로 생성됩니다" disabled />
            </Form.Item>
            <Form.Item
              label="연구명"
              name="title"
              rules={[
                {
                  required: true,
                  message: '연구명을 확인해주세요',
                },
              ]}
            >
              <Input placeholder="연구명을 입력해주세요" />
            </Form.Item>
            <Form.Item
              label="질환"
              name="diseaseTypeIds"
              rules={[
                {
                  required: true,
                  message: '질환을 확인해주세요',
                },
              ]}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="질환 태그를 선택해주세요 (다중 선택 가능)"
                filterOption={(input, option) =>
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.key.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
                onChange={(e) =>
                  setState({
                    diseaseTypeIds: e,
                  })
                }
              >
                {diseaseTypeInfo?.map((diseaseType) => (
                  <Select.Option
                    key={diseaseType?.krName}
                    id={diseaseType?.krName}
                    value={diseaseType?.id}
                  >
                    {diseaseType.krName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="주관 병원"
              name="hostHospitalName"
              rules={[
                {
                  required: true,
                  message: '주관 병원을 확인해주세요.',
                },
              ]}
            >
              <Input placeholder="주관 병원명을 입력해주세요" />
            </Form.Item>
            <Form.Item
              label="주관 병원 로고"
              name="hostHospitalLogo"
              rules={[
                {
                  required: true,
                  message: '주관 병원 로고를 확인해주세요',
                },
              ]}
            >
              <Upload
                {...uploadProps}
                listType="picture-card"
                showUploadList={false}
                beforeUpload={beforeUpload}
              >
                {state?.hostHospitalLogo ? (
                  <img
                    src={state?.hostHospitalLogo}
                    alt="avatar"
                    style={{
                      width: '100%',
                    }}
                  />
                ) : (
                  uploadButton
                )}
              </Upload>
              {state?.hostHospitalLogo && (
                <Button
                  type="primary"
                  style={{ width: 104 }}
                  danger
                  onClick={deleteThumbImg}
                >
                  이미지 삭제
                </Button>
              )}
            </Form.Item>
            <Form.Item label="참여 병원" name="connectHospitals">
              <Button
                style={{ width: 104, marginBottom: 16 }}
                onClick={addConnectItems}
              >
                추가하기
              </Button>
              {connectItems?.map((item, index) => (
                <>
                  <Form.Item key={`connectItem-${item.id}`}>
                    <div style={{ display: 'inline-block' }}>
                      참여 병원 {index + 1}번:&nbsp;
                    </div>
                    <Input
                      type="text"
                      className={`title-${index}`}
                      onChange={(e) =>
                        handleConnectItemsChange(e, index, 'title')
                      }
                      value={item.title}
                      style={{ width: 250, marginRight: 12 }}
                      placeholder="참여 병원명을 입력해주세요"
                    />
                    <Button danger onClick={() => deleteConnectItems(item.id)}>
                      삭제
                    </Button>
                  </Form.Item>
                </>
              ))}
            </Form.Item>
            <Form.Item label="모집 인원" name="goalEnrollment">
              <Input placeholder="모집 인원 수를 입력해 주세요" />
            </Form.Item>
            <Form.Item label="연구 마감일" name="completedAt">
              <DatePicker onChange={onDateChange} />
            </Form.Item>
            <Form.Item
              label="연구 상태"
              name="status"
              rules={[
                {
                  required: true,
                  message: '연구 상태를 확인해주세요.',
                },
              ]}
            >
              <Radio.Group
                onChange={(e) =>
                  setState({
                    status: e.target.value,
                  })
                }
                value={state.status}
                optionType="button"
                buttonStyle="solid"
                options={listMap.researchStatusOptions}
              />
            </Form.Item>
            <Form.Item
              label="연구 종류"
              name="type"
              rules={[
                {
                  required: true,
                  message: '연구 종류를 확인해주세요.',
                },
              ]}
            >
              <Radio.Group
                onChange={(e) =>
                  setState({
                    type: e.target.value,
                  })
                }
                value={state.type}
                optionType="button"
                buttonStyle="solid"
                options={listMap.researchTypeOptions}
              />
            </Form.Item>
            {state.type === 'promotion_and_healthprofile' && (
              <Form.Item
                label="주관 병원 정보"
                name="optionalHostHospitals"
                rules={[
                  {
                    required: optionalHostHospitalItems?.length === 0,
                    message: '주관 병원 정보를 확인해주세요.',
                  },
                ]}
              >
                <Button
                  style={{ width: 104, marginBottom: 16 }}
                  onClick={addOptionalHostHospitalItems}
                >
                  추가하기
                </Button>
                {optionalHostHospitalItems?.map((item, index) => (
                  <div>
                    <Form.Item>
                      <div style={{ display: 'inline-block' }}>
                        주관 병원 {index + 1}번:&nbsp;
                      </div>
                      <Input
                        type="text"
                        className={`name-${index}`}
                        onChange={(e) =>
                          handleOptionalHostHospitalItemsChange(
                            e,
                            index,
                            'name',
                          )
                        }
                        value={item.name}
                        style={{ width: 250, marginRight: 12 }}
                        placeholder="주관 병원 입력"
                      />
                      <Input
                        type="text"
                        className={`phone-${index}`}
                        onChange={(e) =>
                          handleOptionalHostHospitalItemsChange(
                            e,
                            index,
                            'phone',
                          )
                        }
                        value={item.phone}
                        style={{ width: 250, marginRight: 12 }}
                        placeholder="주관 병원 전화번호를 입력해주세요"
                      />
                      <Button
                        danger
                        onClick={() => deleteOptionalHostHospitalItems(item.id)}
                      >
                        삭제
                      </Button>
                    </Form.Item>
                  </div>
                ))}
              </Form.Item>
            )}
            {state.type === 'custom_detail_page' && (
              <Form.Item
                label="기존 연구 ID"
                name="customDetailPageId"
                rules={[
                  {
                    required: true,
                    message: '기존 연구 ID를 확인해주세요.',
                  },
                ]}
              >
                <Input placeholder="기존 연구 상세 페이지 ID값을 입력해주세요 (FE 개발자 문의)" />
              </Form.Item>
            )}
            <Form.Item
              label="타질환 연구 공유"
              name="isSharedWithOtherDiseases"
              rules={[
                {
                  required: true,
                  message: '타질환 연구 공유를 확인해주세요.',
                },
              ]}
            >
              <Radio.Group
                onChange={(e) =>
                  setState({
                    isSharedWithOtherDiseases: e.target.value,
                  })
                }
                value={state.isSharedWithOtherDiseases}
                optionType="button"
                buttonStyle="solid"
                options={listMap.researchSharedOptions}
              />
            </Form.Item>
            <Form.Item label="건강 설문" name="healthProfileIds">
              <Select
                mode="multiple"
                allowClear
                style={{ width: '100%' }}
                placeholder="건강 설문을 선택해주세요 (다중 선택 가능)"
                filterOption={(input, option) =>
                  option.props.value
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0 ||
                  option.props.key.toLowerCase().indexOf(input.toLowerCase()) >=
                    0
                }
                onChange={(e) =>
                  setState({
                    healthProfileIds: e,
                  })
                }
              >
                {notificationContents?.['설문']
                  ?.filter(
                    (content) =>
                      (content?.research?.length === 0 &&
                        content?.patientGroups?.length === 0) ||
                      content?.research?.[0]?.researchHealthProfile
                        ?.researchId === researchId,
                  )
                  ?.map((healthProfile) => (
                    <Select.Option
                      key={healthProfile?.title + healthProfile?.id}
                      id={healthProfile?.id}
                      value={healthProfile?.id}
                    >
                      [
                      {healthProfile?.diseaseTypes
                        ?.map((disease) => disease?.kraName)
                        .join(', ')}
                      ]{healthProfile?.title}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
            {state.type !== 'custom_detail_page' && (
              <Form.Item
                label="연구 소개"
                name="description"
                className="treatmentContent"
              >
                <LoadingOverlay
                  active={imageLoading}
                  spinner={<Spin />}
                  text={<p>이미지를 업로드 하는 중</p>}
                >
                  <AntTinymceInput
                    content={state.description}
                    setContent={(e) => {
                      setState({ description: e });
                    }}
                    setImageLoading={setImageLoading}
                    onBlur={(e) => {}}
                    contentLinkData={{ setState }}
                  />
                </LoadingOverlay>
              </Form.Item>
            )}

            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100 }}
                onClick={onReset}
              >
                취소
              </Button>
              {researchId && (
                <Button
                  danger
                  htmlType="button"
                  style={{ width: 100, marginLeft: 8 }}
                  onClick={onDelete}
                >
                  삭제
                </Button>
              )}
            </Form.Item>
            {showAlert && (
              <Col span={20} offset={4}>
                <Alert
                  message="에러"
                  description="필수값을 확인해주세요."
                  type="error"
                  showIcon
                />
              </Col>
            )}
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default ResearchWindow;
