// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0
import { retryMiddlewareFactory } from '../middleware/retry';
import { signingMiddlewareFactory, } from '../middleware/signing';
import { userAgentMiddlewareFactory, } from '../middleware/userAgent';
import { composeTransferHandler } from '../internal/composeTransferHandler';
import { fetchTransferHandler } from './fetch';
export const authenticatedHandler = composeTransferHandler(fetchTransferHandler, [
    userAgentMiddlewareFactory,
    retryMiddlewareFactory,
    signingMiddlewareFactory,
]);
