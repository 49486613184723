import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Select,
  Checkbox,
  Collapse,
  notification,
} from 'antd';

import '../../../public/css/window.css';
import { parse } from 'qs';
import { useLocation } from 'react-router';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import {
  closestCenter,
  DndContext,
  PointerSensor,
  pointerWithin,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import WindowHeader from '../../components/windowHeader';
import ElementLoading from '../../components/elementLoading';
import { HEALTH_PROFILE_QUESTION_TYPE } from '../../../util/healthProfileConstants';
import {
  fetchAllHealthProfileQuestion,
  fetchHealthProfileQuestion,
  patchHealthProfileQuestion,
  postHealthProfileQuestion,
} from '../../../services/healthProfileService';

const NEW_OPTION = {
  title: '',
  isTextRequired: null,
  nextQuestionId: null,
  isNokAgreementRequired: null,
  isDecimal: null,
  unit: null,
};

/**
 * NOTE(reo): Collapse의 높이가 불규칙하기 때문에 CollisionDetection 알고리즘을 재정의
 * @see https://docs.dndkit.com/api-documentation/context-provider/collision-detection-algorithms#composition-of-existing-algorithms
 */
function customCollisionDetectionAlgorithm(args) {
  const pointerCollisions = pointerWithin(args);

  if (pointerCollisions.length > 0) {
    return pointerCollisions;
  }

  return closestCenter(args);
}

const QuestionOption = ({
  record,
  index,
  remove,
  nextQuestions,
  hasAnsweredUser,
  type,
  onSelectNextQuestion,
}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id: record.id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const [title, setTitle] = useState(record.title);
  const isNew = record.id?.startsWith('NEW');
  const titleRef = useRef(null);

  useEffect(() => {
    if (isNew) {
      titleRef.current?.focus();
    }
  }, []);

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <Collapse
        expandIconPosition="end"
        defaultActiveKey={isNew ? record.id : undefined}
      >
        <Collapse.Panel
          header={`${index + 1}번 답변: ${title}`}
          key={record.id}
        >
          <Form.Item label="답변 제목" name={[index, 'title']}>
            <Input
              onChange={(e) => {
                setTitle(e.target.value);
              }}
              ref={titleRef}
            />
          </Form.Item>
          {type === 'RADIO' && (
            <Form.Item
              label="보호자 동의 필요"
              name={[index, 'isNokAgreementRequired']}
              valuePropName="checked"
              disabled={hasAnsweredUser}
            >
              <Checkbox>
                선택되면 본 답변은 만 14세 미만의 경우 보호자의 동의가
                필요합니다
              </Checkbox>
            </Form.Item>
          )}
          {type !== 'DROPDOWN' && (
            <Form.Item
              label="텍스트 입력 필수"
              name={[index, 'isTextRequired']}
              valuePropName="checked"
              disabled={hasAnsweredUser}
            >
              <Checkbox>
                선택되면 본 답변은 텍스트를 입력해야만 다음 문항으로 진행할 수
                있습니다.
              </Checkbox>
            </Form.Item>
          )}
          <Form.Item
            label="다음 문항 지정"
            name={[index, 'nextQuestionId']}
            help="지정된 질문 ID가 없으면 다음 질문으로 이동합니다. 순서상 이전 문항을 지정하지 않도록 주의해주세요."
          >
            <Select
              showSearch
              optionFilterProp="label"
              allowClear
              options={nextQuestions.map((i) => ({
                label: i.title,
                value: i.id,
              }))}
              disabled={hasAnsweredUser}
              onChange={onSelectNextQuestion}
            />
          </Form.Item>
          <Row justify="end">
            <Button
              onClick={() => {
                if (window.confirm('삭제하시겠습니까?')) {
                  remove(index);
                }
              }}
              danger
              disabled={hasAnsweredUser}
            >
              삭제
            </Button>
          </Row>
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

const HealthProfileQuestionWindow = () => {
  const { search } = useLocation();
  const [windowId, setWindowId] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [healthProfileTitle, setHealthProfileTitle] = useState('');
  const [healthProfileId, setHealthProfileId] = useState(null);
  const [healthProfileQuestionId, setHealthProfileQuestionId] = useState(null);
  const [form] = Form.useForm();
  const [questionType, setQuestionType] = useState(null);
  const [nextQuestions, setNextQuestions] = useState([]);
  const [hasAnsweredUser, setHasAnsweredUser] = useState(false);
  const [hasNextQuestion, setHasNextQuestion] = useState(false);

  const setupBeforeUnloadListener = (data) => {
    window.addEventListener('beforeunload', (event) => {
      event.preventDefault();
      return window?.opener?.postMessage(data, '/');
    });
  };

  const getData = useCallback(async () => {
    if (healthProfileQuestionId) {
      const response = await fetchHealthProfileQuestion(
        healthProfileId,
        healthProfileQuestionId,
      );
      form.setFieldsValue({
        ...response,
        isAnswerEditable: !response.isAnswerEditable,
      });
      if (response.type === 'NUMBER_INPUT') {
        form.setFieldsValue({
          questionOptions: [
            {
              ...response.questionOptions[0],
              isDecimal: !response.questionOptions[0].isDecimal,
            },
          ],
        });
      }
      setQuestionType(response.type);
      if (response.questionOptions?.some((i) => i.nextQuestionId)) {
        setHasNextQuestion(true);
        form.setFieldsValue({
          isAnswerEditable: true,
        });
      }
    }
    if (healthProfileId) {
      const questions = await fetchAllHealthProfileQuestion(healthProfileId);
      setNextQuestions(questions);
    }
  }, [healthProfileId, healthProfileQuestionId]);

  useEffect(() => {
    const params = parse(search, {
      ignoreQueryPrefix: true,
    });
    setWindowId(params.id ? params.id : params.new);
    if (!params.healthProfileId) {
      alert('잘못된 접근입니다.');
      window.close();
    }
    setHealthProfileTitle(params.title);
    setHealthProfileId(params.healthProfileId);
    setHasAnsweredUser(params.hasAnsweredUser === 'true');
    if (params.id) {
      setHealthProfileQuestionId(params.id);
    } else {
      setHealthProfileQuestionId(null);
    }
    setupBeforeUnloadListener(`close ${windowId}`);
    setIsLoading(false);
  }, [search, windowId]);

  useEffect(() => {
    getData();
  }, [healthProfileId, healthProfileQuestionId]);

  const onSelectNextQuestion = (value) => {
    if (value) {
      setHasNextQuestion(true);
      if (form.getFieldValue('isAnswerEditable') === false) {
        form.setFieldsValue({
          isAnswerEditable: true,
        });
      }
    } else {
      setHasNextQuestion(false);
    }
  };

  const onFinish = async (values) => {
    const data = {
      ...values,
      isAnswerEditable: !values.isAnswerEditable,
    };

    if (data.type === 'RADIO' && data.isAnswerRequired === false) {
      notification.error({
        message: '단일 선택 문항은 필수 문항이어야 합니다.',
        key: 'radioAnswerRequiredError',
      });
      return;
    }

    if (data.questionOptions.length > 0) {
      data.questionOptions = data.questionOptions.map((opt) => ({
        title: null,
        isTextRequired: null,
        nextQuestionId: null,
        isDecimal: null,
        unit: null,
        isNokAgreementRequired: null,
        ...opt,
        id: opt.id?.startsWith('NEW?') ? undefined : opt.id,
      }));
    }

    if (
      data.questionOptions?.length === 0 &&
      !['RADIO', 'CHECKBOX', 'DROPDOWN'].includes(questionType)
    ) {
      data.questionOptions = [{}];
    }

    if (questionType === 'CHECKBOX') {
      const nextQuestions = new Map();
      data.questionOptions.forEach((opt) => {
        if (opt.nextQuestionId) {
          nextQuestions.set(opt.nextQuestionId, true);
        }
      });
      if (nextQuestions.size > 1) {
        notification.error({
          message: '복수 선택 문항은 하나의 다음 문항만 지정 가능합니다.',
          key: 'checkboxNextQuestionError',
        });
        return;
      }
    }

    if (data.type === 'NUMBER_INPUT') {
      data.questionOptions[0].isDecimal = !data.questionOptions[0].isDecimal;
    }

    if (healthProfileQuestionId) {
      if (window.confirm('수정하시겠습니까?')) {
        try {
          await patchHealthProfileQuestion(
            healthProfileId,
            healthProfileQuestionId,
            data,
          );
          alert('성공적으로 수정되었습니다.');
          window.close();
        } catch (e) {
          notification.error({
            message: '문항 수정 실패',
          });
          throw e;
        }
      }
    } else if (window.confirm('생성하시겠습니까?')) {
      try {
        await postHealthProfileQuestion(healthProfileId, data);
        alert('성공적으로 등록되었습니다.');
        window.close();
      } catch (e) {
        notification.error({
          message: '문항 등록 실패',
        });
        throw e;
      }
    }
  };

  const onReset = () => {
    if (window.confirm('취소하시겠습니까?')) {
      window.close();
    }
  };

  const handleDragEnd = ({ active, over }) => {
    if (active?.id !== over?.id) {
      const array = form.getFieldValue('questionOptions');
      const activeIndex = array.findIndex((record) => record.id === active?.id);
      const overIndex = array.findIndex((record) => record.id === over?.id);
      const newArray = arrayMove(
        form.getFieldValue('questionOptions'),
        activeIndex,
        overIndex,
      );
      form.setFieldsValue({
        questionOptions: newArray,
      });
      return newArray;
    }
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 1,
      },
    }),
  );

  if (isLoading) return <ElementLoading type="설문 질문" />;
  return (
    <>
      <WindowHeader title="설문 질문" />
      <Row span={24} style={{ padding: 16 }}>
        <Col span={24}>
          <Form
            name="basic"
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            onFinish={onFinish}
            form={form}
            initialValues={{
              title: '',
              type: null,
              description: null,
              isAnswerRequired: true,
              isAnswerEditable: false,
              questionOptions: [],
            }}
          >
            <Form.Item label="종속 설문">
              <Input disabled value={healthProfileTitle} />
            </Form.Item>
            <Form.Item
              label="문항 제목"
              name="title"
              rules={[
                {
                  required: true,
                  message: '문항 제목을 입력해주세요.',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="답변 타입"
              name="type"
              rules={[
                {
                  required: true,
                  message: '답변 타입을 선택해주세요.',
                },
              ]}
            >
              <Select
                onChange={(v) => {
                  setQuestionType(v);
                  form.setFieldsValue({
                    questionOptions: [],
                  });
                }}
                disabled={hasAnsweredUser}
              >
                {Object.entries(HEALTH_PROFILE_QUESTION_TYPE).map(
                  ([key, value]) => (
                    <Select.Option key={key} value={key}>
                      {value}
                    </Select.Option>
                  ),
                )}
              </Select>
            </Form.Item>
            <Form.Item label="문항 부가 설명" name="description">
              <Input />
            </Form.Item>
            {questionType === 'NUMBER_INPUT' && (
              <Form.Item
                label="단위 문구"
                name={['questionOptions', 0, 'unit']}
                rules={[
                  {
                    required: true,
                    message: '단위 문구를 입력해주세요.',
                  },
                ]}
              >
                <Input />
              </Form.Item>
            )}
            <Form.Item
              label="필수 답변"
              name="isAnswerRequired"
              valuePropName="checked"
            >
              <Checkbox>체크 시 응답 필수 / 체크 해제 시 응답 선택</Checkbox>
            </Form.Item>
            <Form.Item
              label="답변 수정 불가"
              name="isAnswerEditable"
              valuePropName="checked"
            >
              <Checkbox disabled={hasNextQuestion}>
                체크 시 문항 답변 수정 불가 / 체크 해제 시 수정 가능
              </Checkbox>
            </Form.Item>
            {questionType === 'RADIO' ||
            questionType === 'CHECKBOX' ||
            questionType === 'DROPDOWN' ? (
              <Form.Item label="문항 답변 생성">
                <Form.List
                  name="questionOptions"
                  rules={[
                    {
                      validator: async (_, value) => {
                        if (!value || value.length === 0) {
                          return Promise.reject(
                            new Error('1개 이상의 답변을 입력해주세요.'),
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  {(fields, { add, remove }, { errors }) => {
                    const records = form.getFieldValue('questionOptions');
                    return (
                      <>
                        <Row style={{ marginBottom: 16 }}>
                          <Button
                            onClick={() => {
                              const newRecord = {
                                ...NEW_OPTION,
                                id: `NEW?${Date.now()}`,
                              };
                              add(newRecord);
                            }}
                            type="primary"
                            disabled={hasAnsweredUser}
                          >
                            추가
                          </Button>
                        </Row>
                        <DndContext
                          autoScroll={false}
                          modifiers={[
                            restrictToVerticalAxis,
                            restrictToWindowEdges,
                          ]}
                          collisionDetection={customCollisionDetectionAlgorithm}
                          onDragEnd={handleDragEnd}
                          sensors={sensors}
                        >
                          <SortableContext
                            items={records?.map((i) => i.id) ?? []}
                            strategy={verticalListSortingStrategy}
                          >
                            {fields.map((field, index) => (
                              <QuestionOption
                                key={records[index]?.id}
                                index={index}
                                record={form.getFieldValue([
                                  'questionOptions',
                                  field.name,
                                ])}
                                remove={remove}
                                nextQuestions={nextQuestions}
                                hasAnsweredUser={hasAnsweredUser}
                                type={questionType}
                                onSelectNextQuestion={onSelectNextQuestion}
                              />
                            ))}
                          </SortableContext>
                        </DndContext>
                        <Form.ErrorList errors={errors} />
                      </>
                    );
                  }}
                </Form.List>
              </Form.Item>
            ) : (
              <></>
            )}
            {questionType === 'NUMBER_INPUT' && (
              <Form.Item
                label="소수점 비허용"
                name={['questionOptions', 0, 'isDecimal']}
                valuePropName="checked"
              >
                <Checkbox>
                  선택되면 본 문항의 답변은 소수점을 허용하지 않습니다.
                </Checkbox>
              </Form.Item>
            )}
            {!['RADIO', 'CHECKBOX', 'DROPDOWN'].includes(questionType) && (
              <Form.Item
                label="다음 문항 지정"
                name={['questionOptions', 0, 'nextQuestionId']}
                help="지정된 질문 ID가 없으면 다음 질문으로 이동합니다. 순서상 이전 문항을 지정하지 않도록 주의해주세요."
                style={{
                  marginBottom: 24,
                }}
              >
                <Select
                  showSearch
                  optionFilterProp="label"
                  allowClear
                  options={nextQuestions.map((i) => ({
                    label: i.title,
                    value: i.id,
                  }))}
                  disabled={hasAnsweredUser}
                  onChange={onSelectNextQuestion}
                />
              </Form.Item>
            )}
            {!['RADIO', 'CHECKBOX', 'DROPDOWN'].includes(questionType) ||
            questionType === 'NUMBER_INPUT' ? (
              <Form.Item name={['questionOptions', 0, 'id']} hidden>
                <Input />
              </Form.Item>
            ) : (
              <></>
            )}
            <Form.Item
              wrapperCol={{
                offset: 4,
                span: 20,
              }}
            >
              <Button type="primary" htmlType="submit" style={{ width: 100 }}>
                저장
              </Button>
              <Button
                htmlType="button"
                style={{ width: 100, marginLeft: 8 }}
                onClick={onReset}
              >
                취소
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </>
  );
};
export default HealthProfileQuestionWindow;
