import { handleActions } from 'redux-actions'; // import redux-action for simply define action create function and reducer function
import {
  getAsyncState,
  createAsyncActions,
  handleAsyncActions,
} from '../reducer.util';

// initial states
const initialState = {
  healthProfile: getAsyncState.initial(null),
  healthProfileUser: getAsyncState.initial(null),
  healthProfileQuestion: getAsyncState.initial(null),
  healthProfileOption: getAsyncState.initial(null),
  healthProfileBasicQuestionAnswer: getAsyncState.initial(null),
};

// action types
export const healthProfileActions = {
  FETCH_ALL_HEALTH_PROFILES: '@healthProfile/FETCH_ALL_HEALTH_PROFILES',
  FETCH_HEALTH_PROFILE_USERS: '@healthProfile/FETCH_HEALTH_PROFILE_USERS',
  FETCH_ALL_HEALTH_PROFILE_QUESTIONS:
    '@healthProfile/FETCH_ALL_HEALTH_PROFILE_QUESTIONS',
  FETCH_HEALTH_PROFILE_BASIC_QUESTION_ANSWER:
    '@healthProfile/FETCH_HEALTH_PROFILE_BASIC_QUESTION_ANSWER',
};

// actions
export const healthProfileCreators = {
  fetchAllHealthProfiles: createAsyncActions(
    healthProfileActions,
    'FETCH_ALL_HEALTH_PROFILES',
  ),
  fetchHealthProfileUsers: createAsyncActions(
    healthProfileActions,
    'FETCH_HEALTH_PROFILE_USERS',
  ),
  fetchAllHealthProfileQuestions: createAsyncActions(
    healthProfileActions,
    'FETCH_ALL_HEALTH_PROFILE_QUESTIONS',
  ),
  fetchHealthProfileBasicQuestionAnswer: createAsyncActions(
    healthProfileActions,
    'FETCH_HEALTH_PROFILE_BASIC_QUESTION_ANSWER',
  ),
};

const healthProfileReducer = handleActions(
  {
    ...handleAsyncActions(
      healthProfileActions.FETCH_ALL_HEALTH_PROFILES,
      'healthProfile',
    ),
    ...handleAsyncActions(
      healthProfileActions.FETCH_HEALTH_PROFILE_USERS,
      'healthProfileUser',
    ),
    ...handleAsyncActions(
      healthProfileActions.FETCH_ALL_HEALTH_PROFILE_QUESTIONS,
      'healthProfileQuestion',
    ),
    ...handleAsyncActions(
      healthProfileActions.FETCH_HEALTH_PROFILE_BASIC_QUESTION_ANSWER,
      'healthProfileBasicQuestionAnswer',
    ),
  },
  initialState,
);

export default healthProfileReducer;
