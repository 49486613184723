import axios from 'axios';
import { HTTP_SUCCESS, SUCCESS } from './utils';

// GET /v1/admin/mediaMonitor/configs
export async function fetchMediaMonitorConfigs() {
  const res = await axios.get(`/v1/admin/mediaMonitor/configs`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// POST /v1/admin/mediaMonitor/configs
export async function createMediaMonitorConfig(configData) {
  const res = await axios.post(`/v1/admin/mediaMonitor/configs`, configData);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// GET /v1/admin/mediaMonitor/configs/{configId}
export async function fetchMediaMonitorConfigById(configId) {
  const res = await axios.get(`/v1/admin/mediaMonitor/configs/${configId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// PATCH /v1/admin/mediaMonitor/configs/{configId}
export async function updateMediaMonitorConfig(configId, updateData) {
  const res = await axios.patch(
    `/v1/admin/mediaMonitor/configs/${configId}`,
    updateData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// DELETE /v1/admin/mediaMonitor/configs/{configId}
export async function deleteMediaMonitorConfig(configId) {
  const res = await axios.delete(`/v1/admin/mediaMonitor/configs/${configId}`);
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// GET /v1/admin/mediaMonitor/configs/{configId}/news
export async function fetchMediaMonitorConfigNews(
  configId,
  startDate,
  endDate,
) {
  const res = await axios.get(
    `/v1/admin/mediaMonitor/configs/${configId}/news?startDate=${encodeURIComponent(
      startDate,
    )}&endDate=${encodeURIComponent(endDate)}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// GET /v1/admin/mediaMonitor/configs/{configId}/news/{newsId}
export async function fetchMediaMonitorConfigNewsById(configId, newsId) {
  const res = await axios.get(
    `/v1/admin/mediaMonitor/configs/${configId}/news/${newsId}`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// PATCH /v1/admin/mediaMonitor/configs/{configId}/news/{newsId}
export async function updateMediaMonitorConfigNews(
  configId,
  newsId,
  updateData,
) {
  const res = await axios.patch(
    `/v1/admin/mediaMonitor/configs/${configId}/news/${newsId}`,
    updateData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// GET /v1/admin/mediaMonitor/configs/{configId}/letter
export async function fetchMediaMonitorConfigLetter(configId) {
  const res = await axios.get(
    `/v1/admin/mediaMonitor/configs/${configId}/letter`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// POST /v1/admin/mediaMonitor/configs/{configId}/reload
export async function reloadMediaMonitorConfig(configId) {
  const res = await axios.post(
    `/v1/admin/mediaMonitor/configs/${configId}/reload`,
    null,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// POST /v1/admin/mediaMonitor/configs/{configId}/previews
export async function createMediaMonitorConfigPreview(configId, previewData) {
  const res = await axios.post(
    `/v1/admin/mediaMonitor/configs/${configId}/previews`,
    previewData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

// POST /v1/admin/mediaMonitor/configs/{configId}/previews/{previewId}
export async function createMediaMonitorConfigPreviewById(
  configId,
  previewId,
  previewData,
) {
  const res = await axios.post(
    `/v1/admin/mediaMonitor/configs/${configId}/previews/${previewId}`,
    previewData,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}

export async function getMediaMonitorPreviews(configId) {
  const res = await axios.get(
    `/v1/admin/mediaMonitor/configs/${configId}/previews`,
  );
  if (res.status !== HTTP_SUCCESS || res.data.status !== SUCCESS) {
    throw res;
  }
  return res.data.data;
}
