import { FileTwoTone, FilterTwoTone } from '@ant-design/icons';
import { Col, DatePicker, Divider, Form, Row, Tabs } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import MultiRowInput from '../../components/multiRowInput';

const TABS = [
  { key: 'ko', title: '국내', lang: 'kr' },
  { key: 'en', title: '해외', lang: 'en' },
];

const MEDIA_MONITOR_KEYWORD_CATEGORY = [
  { id: 'DEFAULT', title: '기본' },
  { id: 'R&D', title: 'R&D' },
  { id: 'POLICY', title: '규제 및 정책' },
  { id: 'MARKET', title: '시장 및 경제' },
  { id: 'TREATMENT', title: '치료 및 환자 관리' },
  { id: 'ECONOMY', title: '경제 및 정책 변화' },
  { id: 'TECHNOLOGY', title: '기술 및 혁신' },
];

export const MediaMonitorFilter = ({
  filterForm,
  config,
  diseaseTypeData,
  startDate,
  endDate,
  setStartDate,
  setEndDate,
}) => {
  const [loadKey, setLoadKey] = useState('ko');
  const [eligibleKey, setEligibleKey] = useState('ko');
  const [ineligibleKey, setInEligibleKey] = useState('ko');
  const [urlKey, setUrlKey] = useState('ko');

  return (
    <Form name="filter" form={filterForm}>
      <Row span={24}>
        <Col span={24}>
          <h5>
            <FileTwoTone className="userTitleIcon" />
            1. RSS 수집
          </h5>
          <h6 style={{ marginTop: 16 }}>포함된 단어</h6>
          <Divider plain />
          <Tabs
            activeKey={loadKey}
            onChange={setLoadKey}
            style={{ marginLeft: 10 }}
            destroyInactiveTabPane={false}
          >
            {TABS.map(({ key, title, lang }) => (
              <Tabs.TabPane tab={`${title} 뉴스`} key={key} forceRender={true}>
                {config?.diseaseTypeIds?.map((id) => (
                  <React.Fragment key={id}>
                    <Form.Item
                      label={
                        diseaseTypeData?.find((data) => data.id === id)?.[
                          `${lang}Name`
                        ] ?? id
                      }
                      className="mediaMonitorListHeader"
                      style={{ marginBottom: 0 }}
                    >
                      <MultiRowInput
                        fieldName={['includeKeywords', id, key]}
                        placeholder="키워드를 입력해주세요."
                        addButtonLabel="키워드 추가"
                        form={filterForm}
                      />
                    </Form.Item>
                    <Divider style={{ margin: '8px 0' }} />
                  </React.Fragment>
                ))}
              </Tabs.TabPane>
            ))}
          </Tabs>
          <h6 style={{ marginTop: 16 }}>발행일</h6>
          <Divider plain />
          <DatePicker.RangePicker
            defaultValue={[startDate, endDate]}
            onChange={(dates) => {
              setStartDate(dayjs(dates[0]).startOf('day'));
              setEndDate(dayjs(dates[1]).endOf('day'));
            }}
            format="YYYY-MM-DD"
            style={{ marginLeft: 10 }}
          />
          <h5 style={{ marginTop: 100 }}>
            <FilterTwoTone className="userTitleIcon" />
            2.적합성 필터링
          </h5>
          <h6 style={{ marginTop: 16 }}>적합 키워드</h6>
          <Divider plain />
          <Tabs
            activeKey={eligibleKey}
            onChange={setEligibleKey}
            style={{ marginLeft: 10 }}
            destroyInactiveTabPane={false}
          >
            {TABS.map(({ key, title }) => (
              <Tabs.TabPane tab={`${title} 뉴스`} key={key} forceRender={true}>
                {MEDIA_MONITOR_KEYWORD_CATEGORY.map(
                  ({ id, title: categoryTitle }) => (
                    <React.Fragment key={id}>
                      <Form.Item
                        label={categoryTitle}
                        className="mediaMonitorListHeader"
                        style={{ marginBottom: 0 }}
                      >
                        <MultiRowInput
                          fieldName={['eligibleKeywords', id, key]}
                          placeholder="키워드를 입력해주세요."
                          addButtonLabel="키워드 추가"
                          form={filterForm}
                        />
                      </Form.Item>
                      <Divider style={{ margin: '8px 0' }} />
                    </React.Fragment>
                  ),
                )}
              </Tabs.TabPane>
            ))}
          </Tabs>
          <h6 style={{ marginTop: 16 }}>부적합 키워드</h6>
          <Divider plain />
          <Tabs
            activeKey={ineligibleKey}
            onChange={setInEligibleKey}
            style={{ marginLeft: 10 }}
            destroyInactiveTabPane={false}
          >
            {TABS.map(({ key, title }) => (
              <Tabs.TabPane tab={`${title} 뉴스`} key={key} forceRender={true}>
                <MultiRowInput
                  fieldName={['excludeKeywords', key]}
                  placeholder="제외할 키워드를 입력해주세요."
                  addButtonLabel="제외할 키워드 추가"
                  form={filterForm}
                  inputWidth={120}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
          <h6 style={{ marginTop: 16 }}>부적합 URL</h6>
          <Divider plain />
          <Tabs
            activeKey={urlKey}
            onChange={setUrlKey}
            style={{ marginLeft: 10 }}
            destroyInactiveTabPane={false}
          >
            {TABS.map(({ key, title }) => (
              <Tabs.TabPane tab={`${title} 뉴스`} key={key} forceRender={true}>
                <MultiRowInput
                  fieldName={['excludeUrls', key]}
                  placeholder="제외할 URL을 입력해주세요."
                  addButtonLabel="제외할 URL 추가"
                  form={filterForm}
                  inputWidth={500}
                />
              </Tabs.TabPane>
            ))}
          </Tabs>
        </Col>
      </Row>
    </Form>
  );
};
